import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheck, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { CommandResponseService } from 'src/app/services/component/command-response.service';
@Component({
  selector: 'app-default-file-import',
  templateUrl: './default-file-import.component.html',
  styleUrls: ['./default-file-import.component.scss']
})
export class DefaultFileImportComponent implements OnInit {

  defaultImportFile: UntypedFormGroup;
  @Input() title = ''
  @Input() data = ''
  @Input() opened: boolean = false;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() uploadfileEvent: EventEmitter<any> = new EventEmitter();
  faFileImport = faFileImport;
  faCheck = faCheck;
  constructor(private formBuilder: UntypedFormBuilder, private commandService: CommandResponseService) { }

  ngOnInit() {
    this.defaultImportFile = this.formBuilder.group({
      formFile: ['', Validators.required]
    });
  }

  public close(status: boolean) {
    this.opened = false;
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

  importDefaultFile() {

  }

  onSelectedFile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.defaultImportFile.get('formFile').setValue(file);
      const formData = new FormData();
      formData.append('formFile', this.defaultImportFile.get('formFile').value);
      this.uploadfileEvent.next(formData)
    }
  }

}
