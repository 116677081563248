import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-model-popup',
  templateUrl: './model-popup.component.html',
  styleUrls: ['./model-popup.component.scss']
})
export class ModelPopupComponent implements OnInit {

 @Input() my_modal_title;
 @Input() my_modal_content;
 @Input() form_template;
 @Input() form_validation;
 @Input() formSubmit;
 @Output() submitFormData = new EventEmitter();
 @Output() submitEditFormData =  new EventEmitter();
 myForm: UntypedFormGroup;
 submitted = false;
 faTimes = faTimes;
 isDataRequested = false

 constructor(
  public activeModal: NgbActiveModal,
  private formBuilder: UntypedFormBuilder
  ) { }

 ngOnInit() {
    this.myForm = this.formBuilder.group(this.form_validation);
  }
  public submitForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.myForm.invalid) {
        return;
    }
    const formData = this.myForm.value;
    formData.createdBy = 'test@gmail.com';
    this.myForm.reset();
    this.activeModal.close(formData);
    if (this.my_modal_title === 'Edit Component') {
      this.submitEditFormData.emit(this.myForm.value);
    } else {
      this.submitFormData.emit(formData);
    }
  }

  clearModelForm() {
    this.myForm.reset();
    this.submitted = false;
  }

}
