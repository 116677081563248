import { DropTargetEvent } from '@progress/kendo-angular-utils';

export const calculateDestinationIndex = (e: DropTargetEvent, fromGrid: number, fromIndex, toGrid: number): number => {
    let toIndex = +e.dropTarget.getAttribute('data-kendo-grid-item-index');
    const isInLowerHalf = isDroppedInLowerHalf(e);

    if (fromGrid !== toGrid) {
        if (isInLowerHalf) {
            toIndex += 1;
        }
    } else {
        if (isInLowerHalf && fromIndex > toIndex) {
            toIndex += 1;
        } else if (!isInLowerHalf && fromIndex < toIndex) {
            toIndex -= 1;
        }
    }
    return toIndex;
};

const isDroppedInLowerHalf = (ev: DropTargetEvent) => {
    const itemCoords = ev.dropTarget.getBoundingClientRect();
    return ev.dragEvent.clientY > itemCoords.top + itemCoords.height / 2;
};
