import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { faTrash, faEdit, faPlus, faFileImport, faSitemap, faClone, faCopy, faLink, faRetweet, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { FeatureService } from 'src/app/services/system/feature.service';
import { CommonMenusFeaturesService } from 'src/app/services/common-menus-features/common-menus-features.service';
import { DropDownEvent } from 'src/app/common/copy-feature-modal/copy-feature-modal.component';
import { Subject, Subscription } from 'rxjs';
import { CommonFeature } from 'src/app/models/system/feature/feature.module';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateFeatureListingComponent } from 'src/app/system/create-feature-listing/create-feature-listing.component';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DialogboxService } from 'src/app/services/confirmation-dialog/dialogbox.service';

@Component({
  selector: 'app-feature-details',
  templateUrl: './feature-details.component.html',
  styleUrls: ['./feature-details.component.scss']
})
export class FeatureDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
 
  @Input() feature: CommonFeature;
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faLink = faLink;
  faSitemap = faSitemap;
  faFileImport = faFileImport;
  faClone = faClone;
  faCopy = faCopy;
  faRetweet = faRetweet;
  faTimes = faTimes;
  featureListingControlTypesArr: any = [];
  featureListingViewArr: any = [];
  selectedcontentType: any = '';
  selectedView: any;
  featureWidgetListDataArr: any = [];
  selectedWidgetTyp = '';
  selectedWidgetTypeName = '';
  selectedWidgetSize: number;
  selectedWidgetRemainSize: number;
  isFeatureEditing = false
  isAddFeature = false;
  @Input() canShowActions : boolean;
  openFeatureMenu = false;
  copybtnState = true;
  featuresData = []
  copyFeatureErrorMessage = ''
  titles = ['Destination Title*', 'Source Title*', 'Source Feature*']
 destinationList = []
 sourceList = []
 destTitleId: number;
 sourceTitleId: number;
 selectedFeatureId: number;
 getFeatureIdEvent: Subject<void> = new Subject<void>();
 getFeatureDBIdEvent: Subject<void> = new Subject<void>();
 public container: ViewContainerRef;
 showConfirmation : {reqBy: string, show: boolean, id?: number} = {reqBy: '', show: false, id: 0};
  public gridView: GridDataResult;
  public pageSize = 50;
  public skip = 0;
  pageIndex = 1;
  totalRows = 0
  fetchCommonFeaturesSub: Subscription;
  startRearrange = false
  draggingGridData : any = [{}];
  @Input() componentAccess : boolean
  featureForm = this.formBuilder.group({
    name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(22)]],
    description: ['', Validators.required]
  });

  addFeatureForm = this.formBuilder.group({
    name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(22)]],
    description: ['', Validators.required],
  })

  constructor(private formBuilder: FormBuilder, 
    private spinner: NgxSpinnerService,
    private featureService: FeatureService,
    private commonMenusFeaturesService: CommonMenusFeaturesService,
    private modalService: NgbModal,
    private confirmationDialogService: DialogboxService,
  ) {
    
  }
  ngAfterViewInit(): void {
    this.GetFeatureListingMasterData();
    this.GetFeatureDataWidgetList();
  }


  ngOnDestroy(): void {
    if(this.fetchCommonFeaturesSub) {
      this.fetchCommonFeaturesSub.unsubscribe()
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    const { feature, ...otherChanges } =
      changes;

    if (feature && feature.currentValue) {
      // Handle change in property1
      this.skip = 0
      this.pageIndex = 1;
      setTimeout(() => {
        this.fetchCommonFeatureListings(1)
      })
    }
  }

  updateFeatureForm(feature) {
      this.featureForm.patchValue({
        name: feature.name,
        description: feature
      })
  }

  GetFeatureListingMasterData() {
    this.featureService.GetFeatureListingMasterData().subscribe((data) => {
      if (data !== null) {
        this.featureListingControlTypesArr = data.featureListingControlTypes;
        this.featureListingViewArr = data.featureListingView;
        this.selectedcontentType = data.featureListingControlTypes[0].id;
        this.selectedView = data.featureListingView[0].id;
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  GetFeatureDataWidgetList() {
    this.featureService.GetFeatureDataWidgetList().subscribe((data) => {
      if (data !== null) {
        this.featureWidgetListDataArr = data;
        this.selectedWidgetTyp = data[0].id;
        this.selectedWidgetTypeName = data[0].name;
        this.selectedWidgetSize = data[0].size;
        this.selectedWidgetRemainSize = data[0].remainingSize;
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }


  ngOnInit(): void {
    this.featureForm.setValue({
      name: this.feature ? this.feature.name : '',
      description: this.feature ? this.feature?.description : ''
    });
  }

  

  copyFeatureFromComponent($event: Event) {
    this.spinner.show()
    this.featureService.copyCommonFeature(this.sourceTitleId, this.destTitleId, +this.commonMenusFeaturesService.componentId, this.selectedFeatureId).subscribe((featureId) => {
        this.spinner.hide()
        this.commonMenusFeaturesService.ToastMessage('Feature copied successfully', 'bg-success')
        this.closeAndReset()
        this.fetchCommonFeatures()
    }, error => this.spinner.hide())
  }

  openFeatureListing() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg'
    };
    const modalRef = this.modalService.open(CreateFeatureListingComponent,ngbModalOptions);
    modalRef.componentInstance.title = 'Add ';
    modalRef.componentInstance.featureListingControlTypes = this.featureListingControlTypesArr;
    modalRef.componentInstance.featureListingView = this.featureListingViewArr;
    modalRef.componentInstance.selectedcontentType = this.selectedcontentType;
    modalRef.componentInstance.selectedView = this.selectedView;
    modalRef.componentInstance.featureWidgetListData = this.featureWidgetListDataArr;
    modalRef.componentInstance.selectedWidgetTypeName = this.selectedWidgetTypeName;
    modalRef.componentInstance.selectedWidgetSize = this.selectedWidgetSize;
    modalRef.componentInstance.selectedWidgetType = this.selectedWidgetTyp;
    modalRef.componentInstance.defaultWidgetTypeName = this.selectedWidgetTypeName;
    modalRef.componentInstance.defaultWidgetSize = this.selectedWidgetSize;
    modalRef.componentInstance.defaultWidgetType = this.selectedWidgetTyp;
    setTimeout(() => {
      this.container = modalRef.componentInstance.getRef()
    }, 1000)
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.featureService.createCommonFeatureList(result.features, this.feature.id).subscribe((res) => {
        if (res !== null) {
          if(!result.continue) {
               this.container = undefined
               modalRef.close();
          }
          this.commonMenusFeaturesService.ToastMessage('Feature List Created Successfully.', 'bg-success');
          this.fetchCommonFeatureListings(this.pageIndex);
        }
      }, (error) => {
      this.spinner.hide();
    });
    });
  }

  public pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageIndex = event.skip / event.take;
    this.pageIndex = this.pageIndex + 1;
    this.fetchCommonFeatureListings(this.pageIndex)
}


fetchCommonFeatureListings(pageNumer: number) {
  let featureId = this.feature.featureId
  let componentId = this.commonMenusFeaturesService.componentId
  let titleId = this.commonMenusFeaturesService.commonTitle.id
  this.spinner.show()
  this.featureService.getCommonFeatureListing(featureId, componentId, titleId, this.pageSize, pageNumer).subscribe((data) => {
      this.spinner.hide()
      if(data) {
        this.totalRows = data.recordCount
        this.gridView = {
          data: data.featureListings,
          total: data.recordCount
        };
        // this.draggingGridData = process(data.featureListings, { });
      } else {
        this.skip = 0
      }
  }, error => this.spinner.hide())
}

  refreshFesturesList($event: DropDownEvent) {
    if($event.type === 'system') {
      this.spinner.show();
      this.featuresData = []
      this.copybtnState = true
      this.sourceTitleId = $event.id
      this.spinner.show()
      this.commonMenusFeaturesService.fetchCommonFeatures(this.commonMenusFeaturesService.componentId ,$event.id.toString()).subscribe((features) => {
        this.spinner.hide();
        if (features && features.length > 0) {
          this.featuresData = features;
        }
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.selectedFeatureId =  $event.id
      this.copybtnState = false
    }
  }

  addFeatureAction() {
    this.isAddFeature = true;
    this.addFeatureForm.reset()
  }

  copyFeatureAction() {
    this.destinationList = this.commonMenusFeaturesService.commonMenuTitles;
    this.destTitleId = this.commonMenusFeaturesService.commonTitle.id
    this.sourceList = this.commonMenusFeaturesService.commonMenuTitles
    this.openFeatureModal()
  }

  openFeatureModal() {
    this.openFeatureMenu = true
  }

  deleteFeatureAction() {

  }

  featureEditingAction() {
    this.isFeatureEditing = true
  }

  cancelFeatureEditing() {
    this.isFeatureEditing = false;
    this.isAddFeature = false;
  }

  closeFeatureMenuModal($event: Event) {
    this.closeAndReset()
  }

  closeAndReset() {
    this.openFeatureMenu = false
    this.featuresData = [] 
    this.copybtnState = true
  }

  saveFeature() {
    let componentId = this.commonMenusFeaturesService.componentId
    let titleId = this.commonMenusFeaturesService.commonTitle.id
    this.spinner.show()
    if(this.isFeatureEditing) {
      this.featureService.updateCommonFeature(this.featureForm.value, this.feature.id.toString()).subscribe((featureId) => {
        if(featureId) {
          this.commonMenusFeaturesService.featureIdAdded = featureId.toString();
        }
        this.commonMenusFeaturesService.ToastMessage(
          "Common feature updated successfully",
          "bg-success"
        );
        this.spinner.hide()
        this.fetchCommonFeatures()
    }, error => this.spinner.hide(),
  )
    } else {
      this.featureService.addCommonFeature(componentId, titleId, this.addFeatureForm.value).subscribe((featureId) => {
        if(featureId) {
          this.commonMenusFeaturesService.featureIdAdded = featureId.toString();
        }
        this.commonMenusFeaturesService.ToastMessage(
          "Common feature created successfully",
          "bg-success"
        );
        this.spinner.hide()
        this.fetchCommonFeatures()
    }, error => this.spinner.hide(),
  )
    }
    this.cancelFeatureEditing()
  }

  /**
   * Delete common feature
   */
  deleteCommonFeature() {
    this.showConfirmation = {reqBy: 'Feature', show: true};
    this.cancelFeatureEditing()
  }

  /**
   * Delete a resource
   * @param status 
   */
  deleteConfirmation(status: boolean) {
    if (status) {
      this.spinner.show();
      this.showConfirmation.reqBy == 'Feature' ? this.postDeleteCommonFeature() :  this.postDeleteCommonFeatureListing()
    }
    this.showConfirmation = { reqBy: "", show: false, id: 0 };
  }

  /**
   * Delete confirmation
   */
  postDeleteCommonFeature() {
    this.featureService
        .deleteCommonFeature(this.feature.id.toString())
        .subscribe(
          (state) => {
            this.commonMenusFeaturesService.ToastMessage(
              "Common feature deleted successfully",
              "bg-success"
            );
            this.spinner.hide();
            this.fetchCommonFeatures();
            // this.commonMenusFeaturesService.
          },
          (error) => this.spinner.hide()
        );
  }

  /**
   * Delete common feature listing
   */
  postDeleteCommonFeatureListing() {
    this.featureService.deleteCommonFeatureListing(this.showConfirmation.id).subscribe((datRes) => {
      if(datRes) {
        let quo = this.totalRows%this.pageSize
        let rem = this.totalRows/this.pageSize
        if(quo == 1 && this.pageIndex == Math.trunc(rem) + 1) {
            if(this.pageIndex >= 2 && this.skip >= 1) {
              this.pageIndex = this.pageIndex - 1
              this.skip -= 1
            }
        }
        this.fetchCommonFeatureListings(this.pageIndex );
      }
      else {
             this.spinner.hide();
      }
    }, (error) => {
        this.spinner.hide();
    });
  }
 
  fetchCommonFeatures() {
    let componentId = this.commonMenusFeaturesService.componentId
    let titleId = this.commonMenusFeaturesService.commonTitle.id
    this.fetchCommonFeaturesSub = this.commonMenusFeaturesService.fetchCommonFeatures(componentId, titleId.toString()).subscribe((features) => {
        this.commonMenusFeaturesService.commonFeatures = features
    });
  }

  navigateToMenu() {
    this.commonMenusFeaturesService.menuIdSelected = this.feature.menuId
    this.commonMenusFeaturesService.menufeatureSubject.next('Menu')
  }

  editFeatureListing(gridData) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg'
    };
    const editId = gridData.id;
    const modalRef = this.modalService.open(CreateFeatureListingComponent, ngbModalOptions);
    modalRef.componentInstance.title = 'Edit ';
    modalRef.componentInstance.editId = editId;
    modalRef.componentInstance.editData = gridData;
    modalRef.componentInstance.featureListingControlTypes = this.featureListingControlTypesArr;
    modalRef.componentInstance.featureListingView = this.featureListingViewArr;
    modalRef.componentInstance.featureWidgetListData = this.featureWidgetListDataArr;
    modalRef.componentInstance.selectedWidgetSize = this.selectedWidgetSize;
    modalRef.componentInstance.selectedcontentType = this.selectedcontentType;
    modalRef.componentInstance.selectedWidgetType = this.selectedWidgetTyp;
    let found = this.featureWidgetListDataArr.find((item) => item.id == gridData.widgetId)
    modalRef.componentInstance.selectedWidgetTypeName = found ? found?.name : this.selectedWidgetTypeName;
    modalRef.componentInstance.selectedView = this.selectedView;
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.featureService.updateCommonFeatureList(result.features, editId).subscribe((res) => {
        if (res !== null) {
          this.commonMenusFeaturesService.ToastMessage('Common feature List Updated Successfully.', 'bg-success');
          modalRef.close();
          this.fetchCommonFeatureListings(this.pageIndex);
        }
      }, (error) => {
      this.spinner.hide();
      modalRef.componentInstance.errorMessage = error;
      setTimeout(() => {
        modalRef.componentInstance.errorMessage = '';
      }, 1000)
    });
    });
  }

  deleteFeatureListing(gridData) {
    this.showConfirmation = {reqBy: 'Listing', show: true, id: gridData.id};
  }

  startRearrangeAction() {
      this.startRearrange = true
  }

  closeReArrangeModal($event) {
      this.startRearrange = false
      if($event) {
        this.fetchCommonFeatureListings(this.pageIndex);
      }
  }

  showLoading($event: boolean) {
    if($event) {
      this.spinner.show()
    }else {
      this.spinner.hide()
    }
  }
  
  openModel(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'xl',
      ariaLabelledBy: 'modal-basic-title'
    };
    this.startRearrange = true
    this.modalService.open(content, ngbModalOptions).result.then((result) => {
    }, (reason) => {
      if(this.commonMenusFeaturesService.isReOrdered) {
        this.fetchCommonFeatureListings(this.pageIndex)
        this.commonMenusFeaturesService.isReOrdered = false
      }
    });
  }
}
