
import { Component, OnInit, Input,Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogboxService } from '../../services/confirmation-dialog/dialogbox.service';

@Component({
  selector: 'app-copy-system',
  templateUrl: './copy-system.component.html',
  styleUrls: ['./copy-system.component.scss']
})
export class CopySystemComponent implements OnInit {

 @Input() my_modal_title;
 @Output() submitFormData = new EventEmitter();
 faTimes = faTimes;
 systemList: any;
 sourceSystemId: number = null;
 destinationSystemId: number = null;
 isAdmin: boolean;
 errorVar = '' ;

 constructor(
  public activeModal: NgbActiveModal,
  public toastService: ToastService,
  private confirmationDialogService: DialogboxService,
  private spinner: NgxSpinnerService
  ) { }

 ngOnInit() {
    this.errorVar = '' ;

  }

  public submitForm() {
    this.errorVar = '' ;
    if(this.sourceSystemId == null && this.destinationSystemId == null){
      this.errorVar = 'Please select Source & Destination systems';
      this.spinner.hide();
      return;
    }
    if(this.sourceSystemId == null){
      this.errorVar = 'Please select "Source System"';
      this.spinner.hide();
      return;
    }
    if(this.destinationSystemId == null){
      this.errorVar = 'Please select "Destination System"';
      this.spinner.hide();
      return;
    }
    if(this.sourceSystemId == this.destinationSystemId){
      this.errorVar = 'Source and Destination cannot be same';
      this.spinner.hide();
      return;
    }
    this.openConfirmationDialog();
  }

  public onSourceSystemSelect(systemId){
    this.sourceSystemId = systemId;
  }

  public onDestinationSystemSelect(systemId){
    this.destinationSystemId = systemId;
  }

  public openConfirmationDialog() {
    this.spinner.show();
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to copy this system?')
    .then((confirmed) => {
      if (confirmed === true) {
        let systemDetails = {
          sourceSystemId: this.sourceSystemId,
          destinationSystemId: this.destinationSystemId
        };
        this.submitFormData.emit(systemDetails);  
        return;  
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    this.spinner.hide();
  }

  ToastMessage(message, type) {
    this.toastService.show(message, {
    classname: type,
    delay: 3000 ,
    autohide: true,
    headertext: type === 'bg-success' ? 'Success' : 'Error'
    });
  }
}


