import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
//import { join } from 'path';

@Injectable({
  providedIn: 'root'
})
export class CustomFormValidationsService {

  MatchDataTypeValidations(minValue: string, maxValue: string, dataType: string, extraValue: string = null, defaultValue: string) {
    return (formGroup: UntypedFormGroup) => {
      const MinValueControl = formGroup.controls[minValue];
      const MaxValueControl = formGroup.controls[maxValue];
      const DataTypeValueControl = formGroup.controls[dataType];
      const defaultValueControl = formGroup.controls[defaultValue];
      const dataTypeValue = DataTypeValueControl.value;
      const extraValueControl =  formGroup.controls[extraValue];
      const extraValueData =  extraValueControl.value;
      const temp = JSON.stringify(extraValueData);
      // This will return an array with strings "1", "2", etc.
      // tslint:disable-next-line:variable-name
      const temp_1 = temp.replace('"', '');
      const temp1 = temp_1.replace('"', '');
      const splitExtraValue = temp1.split(',');

      if (!MinValueControl || !MaxValueControl || !defaultValueControl) {
        return null;
      }

      if (MaxValueControl.errors && !MaxValueControl.errors && !defaultValueControl.errors) {
        return null;
      }
      if ( dataTypeValue != null) {
        switch (dataTypeValue.toString()) {
          case '1':
            if (this.inRange(MaxValueControl.value, BigInt(-128), BigInt(127)) === false) {
              MaxValueControl.setErrors({ oint8DataTypeInvaild: true });
              return 'test';
            }
            if (this.inRange(MinValueControl.value, BigInt(-128), BigInt(127)) === false) {
              MinValueControl.setErrors({ oint8NegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(-128), BigInt(127)) === false) {
              defaultValueControl.setErrors({ oint8NegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 127) {
                extraValueControl.setErrors({ oint8ExtraValuType: true});
              } else if (Math.sign(Number(element))  === -1 && Number(element) < -128) {
                extraValueControl.setErrors({ oint8ExtraValuType: true});
              }
            });
            break;
          case '2':
            if (this.inRange(MaxValueControl.value, BigInt(-32768), BigInt(32767)) === false) {
              MaxValueControl.setErrors({ oint16DataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(-32768), BigInt(32767)) === false) {
              MinValueControl.setErrors({ oint16NegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(-32768), BigInt(32767)) === false) {
              defaultValueControl.setErrors({ oint16NegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 32767) {
                extraValueControl.setErrors({ oint16ExtraValuType: true});
              } else if (Math.sign(Number(element))  === -1 && Number(element) < -32768) {
                extraValueControl.setErrors({ oint16ExtraValuType: true});
              }
            });
            break;
          case '3':
            if (this.inRange(MaxValueControl.value, BigInt(-2147483648), BigInt(2147483647)) === false) {
              MaxValueControl.setErrors({ oint32DataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(-2147483648), BigInt(2147483647)) === false) {
              MinValueControl.setErrors({ oint32NegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(-2147483648), BigInt(2147483647)) === false) {
              defaultValueControl.setErrors({ oint32NegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 2147483647) {
                extraValueControl.setErrors({ oint32ExtraValuType: true});
              } else if (Math.sign(Number(element))  === -1 && Number(element) < -2147483648) {
                extraValueControl.setErrors({ oint32ExtraValuType: true});
              }
            });
            break;
          case '4':
            if (this.inRange(MaxValueControl.value, BigInt("-9223372036854775808"), BigInt("9223372036854775807")) === false) {
              MaxValueControl.setErrors({ oint64DataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt("-9223372036854775808"), BigInt("9223372036854775807")) === false) {
              MinValueControl.setErrors({ oint64NegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt("-9223372036854775808"), BigInt("9223372036854775807")) === false) {
              defaultValueControl.setErrors({ oint64NegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (BigInt(element) > BigInt("9223372036854775807")) {
                extraValueControl.setErrors({ oint64ExtraValuType: true});
              } else if (Math.sign(Number(element))  === -1 && BigInt(element) < BigInt("-9223372036854775808")) {
                extraValueControl.setErrors({ oint64ExtraValuType: true});
              }
            });
            break;
          case '5':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(255)) === false) {
              MaxValueControl.setErrors({ oint8PositiveDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(255)) === false) {
              MinValueControl.setErrors({ oint8PositiveNegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(255)) === false) {
              defaultValueControl.setErrors({ oint8PositiveNegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 255) {
                extraValueControl.setErrors({ oint8PositiveExtraValuType: true});
              }
            });
            break;
          case '6':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(65535)) === false) {
              MaxValueControl.setErrors({ oint16PositiveDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(65535)) === false) {
              MinValueControl.setErrors({ oint16PositiveNegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(65535)) === false) {
              defaultValueControl.setErrors({ oint16PositiveNegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 65535) {
                extraValueControl.setErrors({ oint16PositiveExtraValuType: true});
              }
            });
            break;
          case '7':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              MaxValueControl.setErrors({ oint32PositiveDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              MinValueControl.setErrors({ oint32PositiveNegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              defaultValueControl.setErrors({ oint32PositiveNegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 4294967295) {
                extraValueControl.setErrors({ oint32PositiveExtraValuType: true});
              }
            });
            break;
          case '8':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt("18446744073709551615")) === false) {
              MaxValueControl.setErrors({ oint64PositiveDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt("18446744073709551615")) === false) {
              MinValueControl.setErrors({ oint64PositiveNegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt("18446744073709551615")) === false) {
              defaultValueControl.setErrors({ oint64PositiveNegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (BigInt(element) > BigInt("18446744073709551615")) {
                extraValueControl.setErrors({ oint64PosotiveExtraValuType: true});
              }
            });
            break;
          case '10':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(255)) === false) {
              MaxValueControl.setErrors({ oint8PositiveDataTypeInvaild: true });
            }
            if (MinValueControl.value != 0) {
              MinValueControl.setErrors({ oint8PositiveNegativeDataTypeBooleanInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(255)) === false) {
              defaultValueControl.setErrors({ oint8PositiveNegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 255) {
                extraValueControl.setErrors({ oint8PositiveExtraValuType: true});
              }
            });
            break;
          case '15':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(255)) === false) {
              MaxValueControl.setErrors({ Enum8PositiveDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(255)) === false) {
              MinValueControl.setErrors({ Enum8PositiveNegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(255)) === false) {
              defaultValueControl.setErrors({ Enum8PositiveNegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 255) {
                extraValueControl.setErrors({ Enum8PositiveExtraValuType: true});
              }
            });
            break;
          case '16':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(65535)) === false) {
              MaxValueControl.setErrors({ Enum16PositiveDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(65535)) === false) {
              MinValueControl.setErrors({ Enum16PositiveNegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(65535)) === false) {
              defaultValueControl.setErrors({ Enum16PositiveNegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 65535) {
                extraValueControl.setErrors({ Enum16PositiveExtraValuType: true});
              }
            });
            break;
          case '17':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              MaxValueControl.setErrors({ Enum32PositiveDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              MinValueControl.setErrors({ Enum32PositiveNegativeDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              defaultValueControl.setErrors({ Enum32PositiveNegativeDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 4294967295) {
                extraValueControl.setErrors({ Enum32PositiveExtraValuType: true});
              }
            });
            break;
          case '11':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(255)) === false) {
              MaxValueControl.setErrors({ bitmask8MaxDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(255)) === false) {
              MinValueControl.setErrors({ bitmask8MinDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(255)) === false) {
              defaultValueControl.setErrors({ bitmask8MinDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 255) {
                extraValueControl.setErrors({ bitmask8ExtraValuType: true});
              }
            });
            break;
          case '12':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(65535)) === false) {
              MaxValueControl.setErrors({ bitmask16MaxDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(65535)) === false) {
              MinValueControl.setErrors({ bitmask16MinDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(65535)) === false) {
              defaultValueControl.setErrors({ bitmask16MinDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 65535) {
                extraValueControl.setErrors({ bitmask16ExtraValuType: true});
              }
            });
            break;
          case '13':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(16777215)) === false) {
              MaxValueControl.setErrors({ bitmask24MaxDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(16777215)) === false) {
              MinValueControl.setErrors({ bitmask24MinDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(16777215)) === false) {
              defaultValueControl.setErrors({ bitmask24MinDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 16777215) {
                extraValueControl.setErrors({ bitmask24ExtraValuType: true});
              }
            });
            break;
          case '14':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              MaxValueControl.setErrors({ bitmask32MaxDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              MinValueControl.setErrors({ bitmask32MinDataTypeInvaild: true });
            }
            if (this.inRange(defaultValueControl.value, BigInt(0), BigInt(4294967295)) === false) {
              defaultValueControl.setErrors({ bitmask32MinDataTypeInvaild: true });
            }
            splitExtraValue.forEach(element => {
              if (Number(element) > 4294967295) {
                extraValueControl.setErrors({ bitmask32ExtraValuType: true});
              }
            });
            break;          
          case '15':
            MaxValueControl.clearValidators();
            MinValueControl.clearValidators();
            break;
          case '16':
            MaxValueControl.clearValidators();
            MinValueControl.clearValidators();
            break;
          case '17':
            MaxValueControl.clearValidators();
            MinValueControl.clearValidators();
            break;
          case '18':
            MaxValueControl.clearValidators();
            MinValueControl.clearValidators();
            break;
          case '20':
            if (this.inRange(MaxValueControl.value, BigInt(0), BigInt(255)) === false) {
              MaxValueControl.setErrors({ charMaxDataTypeInvaild: true });
            }
            if (this.inRange(MinValueControl.value, BigInt(0), BigInt(255)) === false) {
              MinValueControl.setErrors({ charMinDataTypeInvaild: true });
            }
            break;
          default:
            return true;
        }
      }
    };
  }
  public inRange(x: BigInt, min: BigInt, max: BigInt) {
    if (x >= min && x <= max) {
      // 👇️ this runs
      // console.log('✅ num is between the two numbers');
      return true
    } else {
      // console.log('⛔️ num is NOT between the two numbers');
      return false
    }
    
  }
  public ValidateBitmask(control: AbstractControl) {
    if (control.value !== null && control.value.length <= 2) {
      return { validBit: true };
    } else {
      return null;
    }
  }
  
  public setBitmaskValidation(dataType: string, bitmasks: string) {
    return (formGroup: UntypedFormGroup) => {
      const DataTypeValueControl = formGroup.controls[dataType];
      const dataTypeValue = DataTypeValueControl.value;
      const bitmasksControl = formGroup.controls[bitmasks];

      //For Custom Bitmask Validation
      if(dataTypeValue != null && dataTypeValue.toString() == "18"){
        const customValueControl = formGroup.controls["customs"];
        const customValue = customValueControl.value;
        if(customValue.length >0){
          const customDataTypeValue = customValue[0].data_types;
          if (customDataTypeValue != null) {
            this.setCustomBitmaskError(customValueControl['controls']);
          }
        }
      }
      //For Bitmasks
      else if (dataTypeValue != null) {
        switch (dataTypeValue.toString()) {
          case '11':
            if (bitmasksControl['controls'].length > 0) {
              // tslint:disable-next-line: prefer-for-of
              this.setBitmaskError(bitmasksControl['controls'], 7, 'bitmaskBit8InValid');
            }
            break;
          case '12':
            if (bitmasksControl['controls'].length > 0) {
              // tslint:disable-next-line: prefer-for-of
              this.setBitmaskError(bitmasksControl['controls'], 15, 'bitmaskBit16InValid' );
            }
          break;
          case '13':
            if (bitmasksControl['controls'].length > 0) {
              // tslint:disable-next-line: prefer-for-of
              this.setBitmaskError(bitmasksControl['controls'], 23, 'bitmaskBit24InValid');
            }
            break;
          case '14':
            if (bitmasksControl['controls'].length > 0) {
              // tslint:disable-next-line: prefer-for-of
              this.setBitmaskError(bitmasksControl['controls'], 31, 'bitmaskBit32InValid');
            }
            break;
        
          default:
            return true;
        }
      }
    };
  }

//Bitmask Validation
  public setBitmaskError(bitmasksControl,maxBitValue, errorMessage) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < bitmasksControl.length; i++) {
      const bitValueControl = bitmasksControl[i].controls.bits;
      this.setErrorForBitmasks(bitValueControl,maxBitValue);
    }
  }

  //Custom Bitmask Validation
  public setCustomBitmaskError(bitmasksControl) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < bitmasksControl.length; i++) {
      const subbitValueControl = bitmasksControl[i].controls['customs_bitmasks']['controls'];
      const maxValue = this.findMaxBitValue(bitmasksControl[i].controls['data_types'].value);
      if(subbitValueControl) {
        for(let j=0; j<subbitValueControl.length; j++){
          const bitValueControl = subbitValueControl[j].controls.bits;
          this.setErrorForBitmasks(bitValueControl,maxValue);
        }
      }
    }
  }

  //return Max bit values
  findMaxBitValue(dataType){
    //return Max bit values for Bitmask8
      if (dataType == '11') {
        return 7;
      }
      //return Max bit values for Bitmask16
      if (dataType == '12') {
        return 15;
      }
      //return Max bit values for Bitmask24
      if (dataType == '13') {
        return 23;
      }
      //return Max bit values for Bitmask32
      if (dataType == '14') {
        return 31;
      }
  }

//Setup Errors for Bitmasks
  setErrorForBitmasks(bitValueControl,maxBitValue){
    const bitValueData = bitValueControl.value;
    const temp = JSON.stringify(bitValueData);
    const temp_1 = temp.replace('"', '');
    const temp1 = temp_1.replace('"', '');
    let splitBitValue : any;
    if (bitValueData === '' || bitValueData === null) {
    } else {
      if (temp1.includes('-')) {
        splitBitValue = temp1.split('-');
      } else {
        splitBitValue = temp1;
      }
      if (temp1.includes('-') && splitBitValue.length > 0) {
        splitBitValue.forEach(element => {
          if (Number(element) > maxBitValue) {
            if (maxBitValue === 7) {
              bitValueControl.setErrors({'bitmaskBit8InValid':true});
            }
            if (maxBitValue === 15) {
              bitValueControl.setErrors({'bitmaskBit16InValid':true});
            }
            if (maxBitValue === 23) {
              bitValueControl.setErrors({'bitmaskBit24InValid':true});
            }
            if (maxBitValue === 31) {
              bitValueControl.setErrors({'bitmaskBit32InValid':true});
            }
          } else {
            bitValueControl.setErrors(null);
          }
        });
      } else if (splitBitValue !== null) {
        if (Number(splitBitValue) > maxBitValue) {
          if (maxBitValue === 7) {
            bitValueControl.setErrors({'bitmaskBit8InValid':true});
          }
          if (maxBitValue === 15) {
            bitValueControl.setErrors({'bitmaskBit16InValid':true});
          }
          if (maxBitValue === 23) {
            bitValueControl.setErrors({'bitmaskBit24InValid':true});
          }
          if (maxBitValue === 31) {
            bitValueControl.setErrors({'bitmaskBit32InValid':true});
          }

        } else {
          bitValueControl.setErrors(null);
        }
      }
    }
  }

  clearBitmaskError(bitmasksControl){
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < bitmasksControl.length; i++) {
      const bitValueControl = bitmasksControl[i].controls.bits;
      bitValueControl.clearValidators();
      bitValueControl.updateValueAndValidity();
    }
  }
  public setBitmaskEnumValidation(bitmasks,SrdodataType) {
    const bits = bitmasks.controls.bits.value;
    const dataType = Number(bitmasks.controls.data_type.value);
    const bitmaskEnumControls = bitmasks.controls.bitmasks_enums.controls;
    let errorMsg = null;
    if (bits !== null || bits !== undefined || bits !== '') {
      if (dataType === 1) {
      const temp = JSON.stringify(bits);
      // tslint:disable-next-line: variable-name
      const temp_1 = temp.replace('"', '');
      const temp1 = temp_1.replace('"', '');
      let splitBitValue : any;
      if (temp1.includes('-')) {
        splitBitValue = temp1.split('-'); 
      } else {
        splitBitValue = temp1;
      }
      let min;
      let max;
      if (temp1.includes('-') && splitBitValue.length > 0) {
        min = Number(splitBitValue[0]);
        max = Number(splitBitValue[1]);
      } else if (splitBitValue !== null) {
        min = max =Number(splitBitValue);
      }

      if ((min !== '' && max !== '') || (min !== null && max !== null)) {
        if (SrdodataType === 11 || SrdodataType === 12 || SrdodataType === 13 || SrdodataType === 14) {
          const exponent = Number(max - min +1);
          const powResult = this.pow(2, exponent);
          const enumCount = powResult;

          if (bitmaskEnumControls.length >= enumCount) {
            errorMsg = enumCount;
          } else {
            errorMsg = false;
          }
        }
      }
    }
    } else {
      errorMsg = null;
    }
    return errorMsg;
  }
  public setBitmaskEnumValidationForVal(bitmasks,SrdodataType) {
    const bits = bitmasks.controls.bits.value;
    const dataType = Number(bitmasks.controls.data_type.value);
    const bitmaskEnumControls = bitmasks.controls.bitmasks_enums.controls;
    let errorMsg = null;
    if (bits !== null || bits !== undefined || bits !== '') {
      if (dataType === 1) {
      const temp = JSON.stringify(bits);
      // tslint:disable-next-line: variable-name
      const temp_1 = temp.replace('"', '');
      const temp1 = temp_1.replace('"', '');
      let splitBitValue : any;
      if (temp1.includes('-')) {
        splitBitValue = temp1.split('-'); 
      } else {
        splitBitValue = temp1;
      }
      let min;
      let max;
      if (temp1.includes('-') && splitBitValue.length > 0) {
        min = Number(splitBitValue[0]);
        max = Number(splitBitValue[1]);
      } else if (splitBitValue !== null) {
        min = max =Number(splitBitValue);
      }

      if ((min !== '' && max !== '') || (min !== null && max !== null)) {
        if (SrdodataType === 11 || SrdodataType === 12 || SrdodataType === 13 || SrdodataType === 14) {
          const exponent = Number(max - min +1);
          const powResult = this.pow(2, exponent);
          const enumCount = powResult - 1;

          if (bitmaskEnumControls.length >= enumCount) {
            errorMsg = enumCount;
          } else {
            errorMsg = false;
          }
        }
      }
    }
    } else {
      errorMsg = null;
    }
    return errorMsg;
  }

  pow(base,exponent) {
    let result = 1;
    if (exponent === 0) {
      return 1;
    } else {
      while (exponent !== 0) {
        result *= base;
        --exponent;
      }
      return result;
    }
  }

  public setBitmaskEnumValidationForValValidations(value, flag) {
    const bits = value;
    let errorMsg = null;
    if (bits !== null || bits !== undefined || bits !== '') {
      const temp = JSON.stringify(bits);
      // tslint:disable-next-line: variable-name
      const temp_1 = temp.replace('"', '');
      const temp1 = temp_1.replace('"', '');
      let splitBitValue : any;
      if (temp1.includes('-')) {
        splitBitValue = temp1.split('-'); 
      } else {
        splitBitValue = temp1;
      }
      let min;
      let max;
      if (temp1.includes('-') && splitBitValue.length > 0) {
        min = Number(splitBitValue[0]);
        max = Number(splitBitValue[1]);
      } else if (splitBitValue !== null) {
        min = max = Number(splitBitValue);
      }

      if ((min !== '' && max !== '') || (min !== null && max !== null)) {
          const exponent = Number(max - min +1);
          const powResult = this.pow(2, exponent);
          if (flag === 1) {
            const enumCount = powResult;
            return enumCount;
          } else {
            const enumCount = powResult - 1;
            if (temp1.includes('-') ) {
              return enumCount;
            } else {
              return '0,1';
            }
          }
      }
    }
    return errorMsg;
  }
  
  getDataTypeRange(dataTypeValue, value ) {
    console.log(dataTypeValue, value);
    switch (dataTypeValue.toString()) {
      case '1':
        if (this.inRange(value, BigInt(-128), BigInt(127)) === false) {
          return false;
        }
        break;
      case '2':
        if (this.inRange(value, BigInt(-32768), BigInt(32767)) === false) {
          return false;
        }
        break;
      case '3':
        if (this.inRange(value, BigInt(-2147483648), BigInt(2147483647)) === false) {
          return false;
        }
        break;
      case '4':
        if (this.inRange(value, BigInt("-9223372036854775808"), BigInt("9223372036854775807")) === false) {
          return false;
        }
        break;
      case '5':
        if (this.inRange(value, BigInt(0), BigInt(255)) === false) {
          return false;
        }
        break;
      case '6':
        if (this.inRange(value, BigInt(0), BigInt(65535)) === false) {
          return false;
        }
        break;
      case '7':
        if (this.inRange(value, BigInt(0), BigInt(4294967295)) === false) {
          return false;
        }
        break;
      case '8':
        if (this.inRange(value, BigInt(0), BigInt("18446744073709551615")) === false) {
          return false;
        }
        break;
      case '10':
        if (this.inRange(value, BigInt(0), BigInt(255)) === false) {
          return false;
        }
        break;
      case '11':
        if (this.inRange(value, BigInt(0), BigInt(255)) === false) {
          return false;
        }
        break;
      case '12':
        if (this.inRange(value, BigInt(0), BigInt(65535)) === false) {
          return false;
        }
        break;
      case '13':
        if (this.inRange(value, BigInt(0), BigInt(16777215)) === false) {
          return false;
        }
        break;
      case '14':
        if (this.inRange(value,BigInt(0), BigInt(4294967295)) === false) {
          return false;
        }
        break;
      case '15':
        if (this.inRange(value, BigInt(0), BigInt(255)) === false) {
          return false;
        }
        break;
      case '16':
        if (this.inRange(value, BigInt(0), BigInt(65535)) === false) {
          return false;
        }
        break;
      case '17':
        if (this.inRange(value, BigInt(0), BigInt(4294967295)) === false) {
          return false;
        }
        break;
      default:
        return true;
    }
  }
}
