import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { FeatureService } from '../../services/system/feature.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-create-feature-listing',
  templateUrl: './create-feature-listing.component.html',
  styleUrls: ['./create-feature-listing.component.scss']
})
export class CreateFeatureListingComponent implements OnInit {
  public createFeatureListForm: UntypedFormGroup;
  public features: UntypedFormArray;
  submitted = null;
  faTimes = faTimes;
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;
  @Input() editId: number;
  @Input() editData: any;
  @Input() title: any;
  @Input() featureListingControlTypes: any;
  @Input() featureWidgetListData: any;
  @Input() featureListingView: any;
  @Input() selectedWidgetType: any;
  @Input() selectedcontentType: any;
  @Input() selectedWidgetTypeName;
  @Input() selectedWidgetRemainingSize: number;
  @Input() selectedWidgetSize: number;
  @Input() selectedView: any;
  @Output() submitFormData = new EventEmitter<FeatureListFormData>();
  @Input() defaultWidgetType: any;
  @Input() defaultWidgetTypeName: string;
  @Input() defaultWidgetSize: any;
  @Input() errorMessage = ''
  widgetTypeIndex: any = [];
  componentVersionListData: any = [];
  selectedComponent = '';
  componentVersion: any = [];
  selectedPageId: number;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private featureService: FeatureService,
    private cookie: CookieService,
    private spinner: NgxSpinnerService
  ) {
    this.GetComponentVersionList();
  }

  ngOnInit() {
    this.initform();
  }

  public getRef() {
    return this.container;
  }

  initform() {
    this.createFeatureListForm = this.fb.group({
      widgetType: ['', Validators.required],
      features: this.fb.array([this.createFeatures()])
    });
    this.selectedWidgetSize = this.featureWidgetListData[0].size;
    this.selectedWidgetRemainingSize = this.featureWidgetListData[0].remainingSize;
    this.widgetTypeIndex = (this.featureWidgetListData[0].dataDetails !== undefined) ? this.featureWidgetListData[0].dataDetails : [];
    if (this.selectedWidgetSize > 1) {
      this.addFeatureList(this.selectedWidgetSize);
    }
  }

  GetComponentVersionList() {
    this.spinner.show();
    this.featureService.GetComponentVersionList().subscribe((data) => {
      if (data !== null && data.length > 0) {
        this.componentVersionListData = data;
        this.selectedComponent = data[0].id;
      }
      this.selecteFormDefaultValue();
      setTimeout(() => {
        this.spinner.hide();
      }, 1500);
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  createFeatures(): UntypedFormGroup {
    return this.fb.group({
      contentType: ['1', [Validators.required]],
      components: ['', [Validators.required]],
      component_version: ['', [Validators.required]],
      srdo: ['', [Validators.required]],
      pages: ['', [Validators.required]],
      data1: [''],
      data2: [''],
      data3: ['', [Validators.required]],
      data4: ['', [Validators.required]],
      command_response: [''],
      view: ['', [Validators.required]],
      text: [''],
      command: [''],
      command_subtype: ['']
    });
  }

  get featureControls() {
    // tslint:disable-next-line: no-string-literal
    return this.createFeatureListForm.get('features')['controls'];
  }
  get contentType() {
    return this.createFeatureListForm.get('contentType');
  }
  get widgetType() {
    return this.createFeatureListForm.get('widgetType');
  }
  get getFeatueListControl() {
    return this.createFeatureListForm.get('features') as UntypedFormArray;
  }

  addFeatureList(widgetSize): void {
    const widgetSizeCount = Number(widgetSize);
    this.features = this.createFeatureListForm.get('features') as UntypedFormArray;
    for (let i = 0; i < widgetSizeCount; i++) {
      this.features.push(this.createFeatures());
    }
  }

  removeFeatureList(index: number) {
    this.features.removeAt(index);
  }

  changeWidgetType(event) {
    const selectedText = event.target.options[event.target.selectedIndex].text;
    this.selectedWidgetTypeName = selectedText;
    
    this.selectedWidgetType = event.target.value;
    const widgetListData = this.featureWidgetListData;
    this.getFeatueListControl.clear();
    if (widgetListData.length > 1) {
      const widgetArr = widgetListData.filter((item) => {
        // tslint:disable-next-line: radix
        return item.id === parseInt(this.selectedWidgetType);
      });

      if (widgetArr.length > 0) {
        this.selectedWidgetSize = widgetArr[0].size;
        this.selectedWidgetRemainingSize = widgetArr[0].remainingSize;
        this.widgetTypeIndex = (widgetArr[0].dataDetails !== undefined) ? widgetArr[0].dataDetails : [];
      } else {
        this.selectedWidgetSize = 0;
        this.selectedWidgetRemainingSize = 0;
        this.widgetTypeIndex = [];
      }
    }
    this.addFeatureList(this.selectedWidgetSize);
    this.selecteFormDefaultValue();
  }

  selecteFormDefaultValue() {
    if (this.editId !== undefined) {
      const contentType = this.editData.controlTypeId;
      this.changeContentType(0, 'edit');
      this.getPages(0, '');
      this.getSRDOs(0, '');
      this.removeValidators(0, +contentType);
      this.componentVersionListData.find((elem) => {
        if (elem.name === this.editData.componentName) {
          this.featureControls[0].controls.components.patchValue(elem.id);
          this.featureControls[0].controls.component_version.patchValue(this.editData.componentVersion);
        }
      });
      this.createFeatureListForm.patchValue({ widgetType: this.editData.widgetId });
      this.createFeatureListForm.get('widgetType').disable();
      this.featureControls[0].controls.contentType.patchValue(this.editData.controlTypeId);
      this.featureControls[0].controls.view.patchValue(this.editData.viewId);
      this.featureControls[0].controls.pages.patchValue(this.editData.data2);
      this.featureControls[0].controls.srdo.patchValue(this.editData.srdoId);
      this.featureControls[0].controls.data1.patchValue(this.editData.data1);
      this.featureControls[0].controls.data2.patchValue(this.editData.data2);
      this.featureControls[0].controls.data3.patchValue(this.editData.data3);
      this.featureControls[0].controls.data4.patchValue(this.editData.data4);
      this.featureControls[0].controls.command_response.patchValue(this.editData.commandResponseId);
      this.featureControls[0].controls.command.patchValue(this.editData.data1);
      this.featureControls[0].controls.command_subtype.patchValue(this.editData.data2);
      this.featureControls[0].controls.text.patchValue(this.editData.data1);
    } else {
      const selectedWidgetSize = this.selectedWidgetSize;
      this.createFeatureListForm.patchValue({ widgetType: this.selectedWidgetType });

      //getting Component List & VersionList values from cookies
      const cachedComponentValue = this.getComponentListDataInCookies();
      const cachedVersionValue = this.getVersionListDataInCookies();

      for (let i = 0; i < selectedWidgetSize; i++) {
        const contentType = this.featureControls[i].controls.contentType.value;

        //Setting Component List value from cookies
        if (this.selectedWidgetTypeName != 'SignalWidget' && this.selectedWidgetTypeName != 'SignalLandingWidget' && cachedComponentValue != undefined) {
          let object = this.componentVersionListData.find(elem => elem.id == cachedComponentValue);
          if (object != undefined) {
            this.featureControls[i].controls.components.patchValue(cachedComponentValue);
          }
          else {
            this.featureControls[i].controls.components.patchValue('');
          }
        } else {
          this.featureControls[i].controls.components.patchValue('');
        }

        //Setting Version List value from cookies
        if (this.selectedWidgetTypeName != 'SignalWidget' && this.selectedWidgetTypeName != 'SignalLandingWidget' && cachedVersionValue != undefined) {
          const componentVal = this.featureControls[i].controls.components.value;
          if (componentVal != '') {
            this.featureControls[i].controls.component_version.patchValue(cachedVersionValue);
            this.spinner.show()
            this.getSRDOPageDetailsByVersion("<option value=' '>Select an Option</option>", cachedComponentValue, cachedVersionValue, i, null);
          }
          else {
            this.featureControls[i].controls.component_version.patchValue('');
          }
        } else {
          this.featureControls[i].controls.component_version.patchValue('');
        }

        this.featureControls[i].controls.contentType.patchValue(this.selectedcontentType);
        this.featureControls[i].controls.view.patchValue(this.selectedView);
        this.featureControls[i].controls.data3.patchValue(0);
        this.featureControls[i].controls.data4.patchValue(0);
        this.removeValidators(i, +contentType);
        this.spinner.hide();
      }
      if (this.selectedWidgetTypeName === 'SignalWidget' || this.selectedWidgetTypeName === 'SignalLandingWidget') {
        this.setStaticDataForSignalWidget();
      }
    }
  }
  setStaticDataForSignalWidget() {
    this.componentVersionListData.find((elem) => {
      if (elem.name === 'GlobalOCSS') {
        for (let i = 0; i < this.selectedWidgetSize; i++) {
          this.featureControls[i].controls.components.patchValue(elem.id);
          this.featureControls[i].controls.component_version.patchValue('AAAFAA');
          this.spinner.show()
          this.getSRDOPageDetailsByVersion('', elem.id, 'AAAFAA', i, 0, true);
          this.featureControls[i].controls.pages.patchValue(0);
          this.getSRDOListbyPage(elem.id, 'AAAFAA', 0, '', i, 'Signal Configuration', true);
          setTimeout(() => {
            this.featureControls[i].controls.srdo.patchValue(this.selectedPageId);
            this.spinner.hide();
          }, 5500);
          this.featureControls[i].controls.data1.patchValue(1116);
          this.featureControls[i].controls.data2.patchValue(0);
        }
      }
    });
  }

  removeValidators(i, contentType) {
    this.submitted = false;
    if (contentType === 1) {
      // Remove validators
      this.featureControls[i].controls.command_response.clearValidators();
      this.featureControls[i].controls.text.clearValidators();
      this.featureControls[i].controls.command_response.updateValueAndValidity();
      this.featureControls[i].controls.text.updateValueAndValidity();
      // Set validators
      this.featureControls[i].controls.components.setValidators([Validators.required]);
      this.featureControls[i].controls.component_version.setValidators([Validators.required]);
      this.featureControls[i].controls.pages.setValidators([Validators.required]);
      this.featureControls[i].controls.srdo.setValidators([Validators.required]);
      this.featureControls[i].controls.data3.setValidators([Validators.required]);
      if(this.selectedWidgetTypeName && this.selectedWidgetTypeName != 'TextWidget') {
        this.featureControls[i].controls.data4.setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      } else {
        this.featureControls[i].controls.data4.setValidators([Validators.required]);
      }
      this.featureControls[i].controls.components.updateValueAndValidity();
      this.featureControls[i].controls.component_version.updateValueAndValidity();
      this.featureControls[i].controls.pages.updateValueAndValidity();
      this.featureControls[i].controls.srdo.updateValueAndValidity();
      this.featureControls[i].controls.data3.updateValueAndValidity();
      this.featureControls[i].controls.data4.updateValueAndValidity();
    } else if (contentType === 2) {
      // Remove validators
      this.featureControls[i].controls.data3.clearValidators();
      this.featureControls[i].controls.data4.clearValidators();
      this.featureControls[i].controls.command_response.clearValidators();
      this.featureControls[i].controls.text.clearValidators();
      this.featureControls[i].controls.data3.updateValueAndValidity();
      this.featureControls[i].controls.data4.updateValueAndValidity();
      this.featureControls[i].controls.command_response.updateValueAndValidity();
      this.featureControls[i].controls.text.updateValueAndValidity();
      // Set validators
      this.featureControls[i].controls.components.setValidators([Validators.required]);
      this.featureControls[i].controls.component_version.setValidators([Validators.required]);
      this.featureControls[i].controls.pages.setValidators([Validators.required]);
      this.featureControls[i].controls.srdo.setValidators([Validators.required]);
      this.featureControls[i].controls.components.updateValueAndValidity();
      this.featureControls[i].controls.component_version.updateValueAndValidity();
      this.featureControls[i].controls.pages.updateValueAndValidity();
      this.featureControls[i].controls.srdo.updateValueAndValidity();

    } else if (contentType === 3) {
      // Remove validator
      this.featureControls[i].controls.pages.clearValidators();
      this.featureControls[i].controls.srdo.clearValidators();
      this.featureControls[i].controls.data3.clearValidators();
      this.featureControls[i].controls.data4.clearValidators();
      this.featureControls[i].controls.pages.updateValueAndValidity();
      this.featureControls[i].controls.srdo.updateValueAndValidity();
      this.featureControls[i].controls.data3.updateValueAndValidity();
      this.featureControls[i].controls.data4.updateValueAndValidity();
      this.featureControls[i].controls.text.clearValidators();
      this.featureControls[i].controls.text.updateValueAndValidity();
      // Set validators
      this.featureControls[i].controls.command_response.setValidators([Validators.required]);
      this.featureControls[i].controls.command_response.updateValueAndValidity();
      this.featureControls[i].controls.components.setValidators([Validators.required]);
      this.featureControls[i].controls.component_version.setValidators([Validators.required]);
      this.featureControls[i].controls.components.updateValueAndValidity();
      this.featureControls[i].controls.component_version.updateValueAndValidity();
    } else if (contentType === 4) {
      // Remove validator
      this.featureControls[i].controls.components.clearValidators();
      this.featureControls[i].controls.component_version.clearValidators();
      this.featureControls[i].controls.pages.clearValidators();
      this.featureControls[i].controls.srdo.clearValidators();
      this.featureControls[i].controls.data3.clearValidators();
      this.featureControls[i].controls.data4.clearValidators();
      this.featureControls[i].controls.command_response.clearValidators();
      this.featureControls[i].controls.components.updateValueAndValidity();
      this.featureControls[i].controls.component_version.updateValueAndValidity();
      this.featureControls[i].controls.pages.updateValueAndValidity();
      this.featureControls[i].controls.srdo.updateValueAndValidity();
      this.featureControls[i].controls.data3.updateValueAndValidity();
      this.featureControls[i].controls.data4.updateValueAndValidity();
      this.featureControls[i].controls.command_response.updateValueAndValidity();
      // Set validators
      this.featureControls[i].controls.text.setValidators([Validators.required]);
      this.featureControls[i].controls.text.updateValueAndValidity();
    }
  }
  changeContentType(i, form) {
    let contentType;
    if (this.editId !== undefined && form === 'edit') {
      const controlTypeId = this.editData.controlTypeId;
      contentType = controlTypeId;
    } else {
      contentType = Number(this.featureControls[i].controls.contentType.value);
      if (Number(this.featureControls[i].controls.contentType.value) === 3 || Number(this.featureControls[i].controls.contentType.value) === 4) {
        this.resetDependentFields(i, 'contentType');
      }
      if ((this.selectedWidgetTypeName !== 'SignalWidget' || this.selectedWidgetTypeName === 'SignalLandingWidget') && (contentType === 1 || contentType === 2)) {
        if (this.featureControls[i].controls.components.value === null)
          this.resetDependentFields(i, 'contentType');
      }
    }
    if (this.editId !== undefined) {
      if (this.editData.controlTypeId === 3 && (Number(this.featureControls[i].controls.contentType.value) === 1 || Number(this.featureControls[i].controls.contentType.value) === 2)) {
        this.resetDependentFields(i, 'contentType');
      }
      if (this.editData.controlTypeId === 1 || this.editData.controlTypeId === 2) {
        if (Number(this.featureControls[i].controls.contentType.value) === 3) {
          this.resetDependentFields(i, 'contentType');
        }
      }
    }

    if (contentType === 1) {
      document.getElementById('text_div_' + i).style.display = 'none';
      document.getElementById('command_response_div_' + i).style.display = 'none';
      document.getElementById('command_div_' + i).style.display = 'none';
      document.getElementById('command_subtype_div_' + i).style.display = 'none';
      document.getElementById('view_div_' + i).style.display = 'block';
      document.getElementById('components_div_' + i).style.display = 'block';
      document.getElementById('component_version_div_' + i).style.display = 'block';
      document.getElementById('pages_div_' + i).style.display = 'block';
      document.getElementById('srdo_div_' + i).style.display = 'block';
      document.getElementById('div_data1_' + i).style.display = 'block';
      document.getElementById('div_data2_' + i).style.display = 'block';
      document.getElementById('div_data3_' + i).style.display = 'block';
      document.getElementById('div_data4_' + i).style.display = 'block';
    } else if (contentType === 2) {
      document.getElementById('text_div_' + i).style.display = 'none';
      document.getElementById('command_response_div_' + i).style.display = 'none';
      document.getElementById('command_div_' + i).style.display = 'none';
      document.getElementById('command_subtype_div_' + i).style.display = 'none';
      document.getElementById('view_div_' + i).style.display = 'block';
      document.getElementById('components_div_' + i).style.display = 'block';
      document.getElementById('component_version_div_' + i).style.display = 'block';
      document.getElementById('pages_div_' + i).style.display = 'block';
      document.getElementById('srdo_div_' + i).style.display = 'block';
      document.getElementById('div_data1_' + i).style.display = 'block';
      document.getElementById('div_data2_' + i).style.display = 'block';
      document.getElementById('div_data3_' + i).style.display = 'none';
      document.getElementById('div_data4_' + i).style.display = 'none';
    } else if (contentType === 3) {
      document.getElementById('text_div_' + i).style.display = 'none';
      document.getElementById('command_response_div_' + i).style.display = 'block';
      document.getElementById('command_div_' + i).style.display = 'block';
      document.getElementById('command_subtype_div_' + i).style.display = 'block';
      document.getElementById('view_div_' + i).style.display = 'block';
      document.getElementById('components_div_' + i).style.display = 'block';
      document.getElementById('component_version_div_' + i).style.display = 'block';
      document.getElementById('pages_div_' + i).style.display = 'none';
      document.getElementById('srdo_div_' + i).style.display = 'none';
      document.getElementById('div_data1_' + i).style.display = 'none';
      document.getElementById('div_data2_' + i).style.display = 'none';
      document.getElementById('div_data3_' + i).style.display = 'none';
      document.getElementById('div_data4_' + i).style.display = 'none';
    } else if (contentType === 4) {
      document.getElementById('text_div_' + i).style.display = 'block';
      document.getElementById('div_data1_' + i).style.display = 'none';
      document.getElementById('div_data2_' + i).style.display = 'none';
      document.getElementById('div_data3_' + i).style.display = 'none';
      document.getElementById('div_data4_' + i).style.display = 'none';
      document.getElementById('view_div_' + i).style.display = 'block';
      document.getElementById('components_div_' + i).style.display = 'none';
      document.getElementById('component_version_div_' + i).style.display = 'none';
      document.getElementById('command_response_div_' + i).style.display = 'none';
      document.getElementById('command_div_' + i).style.display = 'none';
      document.getElementById('command_subtype_div_' + i).style.display = 'none';
      document.getElementById('pages_div_' + i).style.display = 'none';
      document.getElementById('srdo_div_' + i).style.display = 'none';
    }
    //this.resetDependentFields(i, 'contentType');
    this.removeValidators(i, contentType);

  }

  onSelectChangeComponentList(i, event) {
    let component = this.featureControls[i].controls.components.value;
    this.featureControls[i].controls.component_version.patchValue('');

    let options = '<option value="" >Select an Option</option>';
    const pageId = document.getElementById('pages_' + i);
    pageId.innerHTML = options;

    const srdoId = document.getElementById('srdo_' + i);
    srdoId.innerHTML = options;

    //save component data in cookies
    if (this.editId == undefined) {
      if (this.selectedWidgetTypeName !== 'SignalWidget' && this.selectedWidgetTypeName !== 'SignalLandingWidget') {
        const cachedvalue = this.getComponentListDataInCookies();
        if (component !== '' && component != cachedvalue) {
          this.saveComponentListDataInCookies(component);
        }
      }
    }
  }


  getVersions(component, i) {
    const componentVersionData = this.componentVersionListData;
    const componentVersion = componentVersionData.find((elem) => elem.id == component);
    return componentVersion ? componentVersion.deployedVersions : componentVersion;
  }

  getPages(i, event) {
    let versionNumber: any;
    let componentId: any;
    let contentType: any;
    if (event === '') {
      versionNumber = this.editData.componentVersion;
      componentId = this.editData.deviceId;
      const controlTypeId = this.editData.controlTypeId;
      contentType = Number(controlTypeId);
    } else {
      versionNumber = event.target.value;
      componentId = this.featureControls[i].controls.components.value;
      contentType = Number(this.featureControls[i].controls.contentType.value);
      //save version data in cookies
      if (this.editId == undefined) {
        if (this.selectedWidgetTypeName !== 'SignalWidget' && this.selectedWidgetTypeName !== 'SignalLandingWidget') {
          const cachedVersionValue = this.getVersionListDataInCookies();
          if (versionNumber !== '' && versionNumber != cachedVersionValue) {
            this.saveVersionListDataInCookies(versionNumber);
          }
        }
      }
    }
    let options = '<option value="" >Select an Option</option>';
    this.resetDependentFields(i, 'component_version');
    if (contentType === 1 || contentType === 2) {
      const pageId = document.getElementById('pages_' + i);
      const srdoId = document.getElementById('srdo_' + i);
      pageId.innerHTML = options;
      srdoId.innerHTML = options;
      if (versionNumber !== '') {
        pageId.innerHTML = '';
        this.getSRDOPageDetailsByVersion(options, componentId, versionNumber, i, null);
      }
    } else if (contentType === 3) {
      const commandResponse = document.getElementById('command_response_' + i);
      if (versionNumber !== '') {
        commandResponse.innerHTML = '';
        this.spinner.show();
        this.featureService.getCommandByComponentIdVersionNumber(componentId.toString(), versionNumber).subscribe((data) => {
          if (data !== null) {
            if (data.length > 0) {
              data.map((commandRes) => {
                options += '<option command=\'' + commandRes.command + '\' commandSubType=\'' + commandRes.commandSubType + '\'';
                if (this.editId !== undefined) {
                  if (commandRes.id === Number(this.editData.commandResponseId)) {
                    options += ' selected =\'true\'';
                  }
                }
                options += ' value=\'' + commandRes.id + '\'>' + commandRes.name + '</option>';
              });
            }
          }
          commandResponse.innerHTML = options;
          this.spinner.hide();
        },
          ((error) => {
            this.spinner.hide();
          }));
      }
    }
  }
  getSRDOs(i, event) {
    let pageNumber;
    let componentId;
    let componentVersion;
    if (event === '') {
      pageNumber = this.editData.data2;
      componentId = this.editData.deviceId;
      componentVersion = this.editData.componentVersion;
    } else {
      this.resetDependentFields(i, 'pages');
      pageNumber = event.target.value;
      componentId = this.featureControls[i].controls.components.value;
      componentVersion = this.featureControls[i].controls.component_version.value;
    }
    let options = '<option  value="" >Select an Option</option>';
    const srdoId = document.getElementById('srdo_' + i);
    srdoId.innerHTML = options;
    let srdoEditId: any;
    if (this.editId !== undefined) {
      srdoEditId = Number(this.editData.srdoId);
    }
    this.featureControls[i].controls.data2.patchValue(pageNumber);
    if (pageNumber !== '') {
      if (componentId > 0) {
        this.getSRDOListbyPage(componentId, componentVersion, pageNumber, options, i);
      }
    }
  }

  getSrdoNo(event, i) {
    this.spinner.show();
    this.resetDependentFields(i, 'srdo');
    const srdoNo = event.target.options[event.target.selectedIndex].getAttribute('srdoIdNo');
    this.featureControls[i].controls.data1.patchValue(srdoNo);
    this.spinner.hide();
  }

  getCommandResponseDetails(event, i) {
    this.resetDependentFields(i, 'command_response');
    const command = event.target.options[event.target.selectedIndex].getAttribute('command');
    const commandSubType = event.target.options[event.target.selectedIndex].getAttribute('commandSubType');
    this.featureControls[i].controls.command.patchValue(command);
    this.featureControls[i].controls.command_subtype.patchValue(commandSubType);
  }
  submitForm(toContinue: Boolean) {
    this.submitted = true;
    if (this.createFeatureListForm.invalid) {
      return;
    }
    let featureList: any;
    const featureListDataArr = this.createFeatureListForm.value;
    const featureListAddData: FeatureListFormData = {features: [], continue: toContinue};
    let items = []
    const widgetId = featureListDataArr.widgetType !== '' ? featureListDataArr.widgetType : 0;
    if (featureListDataArr.features.length > 0) {
      let i = 0;
      for (const featureListData of featureListDataArr.features) {
        let widgetDataIdValue = 1;
        if (this.widgetTypeIndex.length > 0) {
          widgetDataIdValue = this.widgetTypeIndex[i].id;
        }
        const controlTypeId = Number(featureListData.contentType);
        let viewId = featureListData.view !== '' ? featureListData.view : 0;
        let componentId = featureListData.components !== '' ? featureListData.components : 0;
        let versionNumber = featureListData.component_version !== '' ? featureListData.component_version : 0;
        let data1 = featureListData.data1 != '' ? featureListData.data1 : '';
        let data2 = featureListData.data2 !== '' ? featureListData.data2 : '';
        let data3 = featureListData.data3 != '' ? featureListData.data3 : 0;
        let data4 = featureListData.data4 != '' ? featureListData.data4 : 0;
        let srdoId = featureListData.srdo !== '' ? featureListData.srdo : 0;
        const commandmdResponseId = featureListData.command_response !== '' ? featureListData.command_response : 0;
        const command = featureListData.command != '' ? featureListData.command : 0;
        const commandSubtype = featureListData.command_subtype != '' ? featureListData.command_subtype : 0;
        const text = featureListData.text !== '' ? featureListData.text : '';
        if (controlTypeId === 2 || controlTypeId === 4 || controlTypeId === 3) {
          data3 = '';
          data4 = '';
        }
        if (controlTypeId === 3) {
          data1 = command;
          data2 = commandSubtype;
          srdoId = 0;

        }
        if (controlTypeId === 4) {
          componentId = 0;
          versionNumber = '';
          data2 = '';
          data1 = text;
          srdoId = 0;
        }
        featureList = {
          controlTypeId,
          componentId,
          versionNumber,
          widgetId,
          viewId,
          data1,
          data2,
          data3,
          data4,
          srdoId,
          commandResponseId: commandmdResponseId,
          createdBy: 'test@gmail.com',
          widgetDataId: widgetDataIdValue
        };
        items.push(featureList);
        i++;
      }

    } else {
      featureList = {
        controlTypeId: 0,
        componentId: 0,
        versionNumber: 0,
        widgetId,
        viewId: 0,
        data1: 0,
        data2: 0,
        data3: 0,
        data4: 0,
        createdBy: 'test@gmail.com',
        widgetDataId: 0
      };
      items.push(featureList);
    }
    featureListAddData['features'] = items
    //this.activeModal.close(featureListDataArr);
    this.submitFormData.emit(featureListAddData);
  }
  resetFeatureList() {
    this.getFeatueListControl.reset();
  }
  resetDependentFields(i, fieldname) {
    this.featureControls[i].controls.data1.patchValue('');
    if (fieldname === 'contentType') {
      this.featureControls[i].controls.components.patchValue('');
    }
    if (fieldname === 'contentType' || fieldname === 'components') {
      this.featureControls[i].controls.component_version.patchValue('');
    }
    if (fieldname === 'contentType' || fieldname === 'component_version' || fieldname === 'components') {
      this.featureControls[i].controls.pages.patchValue('');
      this.featureControls[i].controls.command_response.patchValue('');
      this.featureControls[i].controls.command.patchValue('');
      this.featureControls[i].controls.command_subtype.patchValue('');
    }
    if (fieldname === 'contentType' || fieldname === 'component_version' || fieldname === 'components' || fieldname === 'pages') {
      this.featureControls[i].controls.srdo.patchValue('');
      this.featureControls[i].controls.data1.patchValue('');
      this.featureControls[i].controls.data2.patchValue('');
      this.featureControls[i].controls.data3.patchValue(0);
      this.featureControls[i].controls.data4.patchValue(0);
      this.featureControls[i].controls.text.patchValue('');
    }
    if (fieldname === 'srdo') {
      this.featureControls[i].controls.data1.patchValue('');
      if (this.editId === undefined) {
        this.featureControls[i].controls.data3.patchValue(0);
        this.featureControls[i].controls.data4.patchValue(0);
      }
    }
    if (fieldname === 'command_response') {
      this.featureControls[i].controls.command.patchValue('');
      this.featureControls[i].controls.command_subtype.patchValue('');
    }
  }
  clearModelForm() {
    for (let i = 0; i < this.selectedWidgetSize; i++) {
      this.featureControls[i].controls.components.patchValue('');
      this.featureControls[i].controls.component_version.patchValue('');
      this.featureControls[i].controls.pages.patchValue('');
      this.featureControls[i].controls.srdo.patchValue('');
      this.featureControls[i].controls.command_response.patchValue('');
      this.featureControls[i].controls.command.patchValue('');
      this.featureControls[i].controls.data1.patchValue('');
      this.featureControls[i].controls.data2.patchValue('');
      this.featureControls[i].controls.data3.patchValue(0);
      this.featureControls[i].controls.data4.patchValue(0);
      this.spinner.hide();
    }
    this.submitted = false;
  }
  getSRDOPageDetailsByVersion(options, componentId, versionNumber, i, optionSelect, loader = null) {
    this.spinner.show();
    this.featureService.getPageByComponentIdVersionNumber(componentId, versionNumber).subscribe((data) => {
      if (data !== null) {
        if (data.length > 0) {
          data.map((pages) => {
            options += '<option value=\'' + pages.pageNumber + '\' ';
            if (this.editId !== undefined) {
              if (pages.pageNumber == Number(this.editData.data2) && componentId == Number(this.editData.deviceId)) {
                options += ' selected =\'true\'';
              }
            }
            if (optionSelect !== null) {
              if (pages.pageNumber === Number(optionSelect)) {
                options += ' selected =\'true\'';
              }
            }
            options += '>' + pages.pageText + '</option>';
          });
        }
      }
      if(document.getElementById('pages_' + i)) {
        document.getElementById('pages_' + i).innerHTML = options;
      }
      this.spinner.hide();
    },((error) => {
        this.spinner.hide();
    }));
  }
  getSRDOListbyPage(componentId, componentVersion, pageNumber, options, i, selectOption = null, loader = null) {
    this.spinner.show();
    this.featureService.getSrdoByComponentIdVersionNumber(componentId, componentVersion, pageNumber).subscribe((data) => {
      if (data !== null) {
        if (data.length > 0) {
          data.map((srdoObj) => {
            options += '<option srdoIdNo=\'' + srdoObj.srdoId + '\'';
            if (this.editId !== undefined) {
              if (srdoObj.id === Number(this.editData.srdoId)) {
                options += ' selected =\'true\'';
              }
            }
            if (selectOption !== null) {
              if (srdoObj.srdoName === selectOption) {
                this.selectedPageId = srdoObj.id;
                options += ' selected =\'true\'';
              }
            }
            options += ' value=\'' + srdoObj.id + '\'>' + srdoObj.srdoName + '</option>';
          });
        }
      }
      this.spinner.hide();
      document.getElementById('srdo_' + i).innerHTML = (options === null) ? '<option value="" >Select an Option</option>' : options;
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  saveComponentListDataInCookies(value) {
    this.cookie.set('componentListValue', value);
  }

  saveVersionListDataInCookies(value) {
    this.cookie.set('versionListValue', value);
  }

  getComponentListDataInCookies() {
    return this.cookie.get('componentListValue');
  }
  getVersionListDataInCookies() {
    return this.cookie.get('versionListValue');
  }

  closePopUp() {
    this.spinner.hide();
    this.activeModal.dismiss('Cross click');
  }
}

export interface FeatureListFormData {
  features: any[],
  continue: Boolean
}
