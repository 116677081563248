import { Component, OnInit, Input, OnChanges, Inject, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { WidgetService } from '../../services/widget/widget.service';
import { ToastService } from '../../services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { UserAccess } from 'src/app/global/user-access';

const createFormGroup = dataItem => new UntypedFormGroup({
  id: new UntypedFormControl(dataItem.id),
  index: new UntypedFormControl(dataItem.index),
  value: new UntypedFormControl(dataItem.value, Validators.required)
});

@Component({
  selector: 'app-input-data',
  templateUrl: './input-data.component.html',
  styleUrls: ['./input-data.component.scss']
})
export class InputDataComponent implements OnChanges {
  @ViewChild('kgrid', { static: true }) Kgrid: ElementRef;
  public isNew: boolean;
  faEdit = faEdit;
  public gridData: any[];
  public formGroup: UntypedFormGroup;
  private editedRowIndex: number;
  phoneAdminAccess = false;
  @Input() items: any = [];
  @Input() dataWidgetId: number;
  // tslint:disable-next-line: no-output-rename
  @Output('getDataWidget') getDataWidget: EventEmitter<any> = new EventEmitter();

  constructor(private widgetService: WidgetService, 
    private toastService: ToastService, 
    private spinner: NgxSpinnerService,
    private useraccess: UserAccess) {
    this.loadItems();
  }

  ngOnChanges() {
    this.closeEditor((this.Kgrid), this.editedRowIndex);
    if (this.items) {
      this.loadItems();
    }
  }

  private loadItems(): void {
    this.checkAccess("");
    if (this.items != null || this.items !== undefined) {
      this.gridData = this.items;
    }
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.formGroup = createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const inputData = formGroup.value;
    Object.assign(
      this.items.find(({ id }) => id === inputData.id),
      inputData
    );
    sender.closeRow(rowIndex);
    this.spinner.show();
    this.widgetService.updateInputData(inputData).subscribe((res) => {
      if (res === true) {
        this.ToastMessage('Input Data Successfully Updated.', 'bg-success', 'Success');
        this.spinner.hide();
      } else {
        this.ToastMessage('HTTP ERROR....', 'bg-danger', 'Error');
        this.spinner.hide();
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  ToastMessage(message, type, Header) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: Header
    });
  }

  getDataWidgetDetails() {
    this.getDataWidget.emit(this.dataWidgetId);
  }

  public checkAccess(action: string, dataItem: number = -1) {
    this.phoneAdminAccess = this.useraccess.getUserAccess("PhoneAdministrator", action, dataItem !== -1 ? +dataItem : -1);
  }
}

