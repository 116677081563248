import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-copy-modal',
  templateUrl: './copy-modal.component.html',
  styleUrls: ['./copy-modal.component.scss']
})
export class CopyModalComponent implements OnInit {
  @Input() opened: boolean = false;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() modal_model: DisplayItem[] = [];
  @Output() selectionChange: EventEmitter<DisplayModel> = new EventEmitter();
  constructor() { }
  @Input() copybtnState: boolean;
  @Input() details = {};
  @Input() errorMessage = '';
  @Input() title = 'Copy SRDO'
  @Input() buttonTitle = 'Copy'
  @Input() innerTitle = 'Destination component details:'
  responseDesc = ''
  ngOnInit() {
  }

  public close(status) {
    this.responseDesc = ''
    this.opened = false;
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

  public copybtnClicked(event) {
    this.responseDesc = ''
    this.closedEvent.emit(true); // True indicates user clicked on copy
  }

  public valueChanged(value: any, title: string): void {
    this.responseDesc = ''
    if(value.displayText) {
      this.responseDesc = value.displayText
    }
    value.extra = title
    this.selectionChange.emit(value)
  }

  returnZero() {
    return 0
 }

}

export interface DisplayItem {
  title: string,
  values: any[],
  disabled: boolean,
  selectedValue: DisplayModel
}

export interface DisplayModel {
  id: string,
  name: string
  extra?:string
}

