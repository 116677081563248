import { Component } from '@angular/core';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { AuditDataService } from '../services/audit/audit.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent  {

  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  public gridView: any[];
  opened = false
  data? = ''
  title = 'Data Changes'


  public view: Observable<GridDataResult>;
  public state: State = {
    skip: 0,
    take: 50
  };
  public sort: SortDescriptor[] = [{
      field: "timeStamp",
      dir: "desc",
  }];

  constructor(public service: AuditDataService, private spinner: NgxSpinnerService,
    ) {
    this.view = service;
    this.spinner.show()
    this.service.query(this.state, this.sort[0]);
  }

  public pageChange(state: PageChangeEvent): void {
    this.state.skip = state.skip;
    this.service.query(state, this.sort[0]);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.service.query(this.state, this.sort[0]);
  }

  showDataWithModal(dataItem: any) {
    this.opened = true
    this.data = dataItem?.data
    this.title = `${dataItem?.action} Changes` 
  }

  close(state: string) {
    this.opened = false
  }


}

interface AuditData {

}
