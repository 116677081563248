import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/Operators';
import { UserAccess } from './user-access';

@Injectable({
  providedIn: 'root'
})
export class RolesResolverService implements Resolve<any> {
  constructor(private userAccess: UserAccess, private cookie: CookieService, private msalService: MsalService) {}
  /*
  //Prefetching the data
  //It used to fetch user roles before routing
  */
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let username = this.msalService.instance.getActiveAccount().username;
    const headerData = { userEmailId: username };
    if(this.userAccess.actions && this.userAccess.actions.roles) {
      return of(true);
    }
    return this.userAccess.getUserClaims(headerData).pipe(
      catchError(error => {
        return of(false);
      })
    );
  }
}
