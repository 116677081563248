import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getServiceUrl, AppService } from 'src/app/global/global';
import { User } from 'src/app/models/user/user.model';
import { Access, UserAccess } from 'src/app/global/user-access';
import { ViewAccess } from 'src/app/access/view-access/view-access.component';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**
   * Add User
   * returns true
   * memberof UserService
   */
  addUser(data) {
    const body = JSON.stringify(data);
    return this.http.post(getServiceUrl(AppService.AddUser), body, this.httpOptions);
  }

   /**
   * Update User
   * returns true
   * memberof UserService
   */
  updateUser(data) {
    const body = JSON.stringify(data);
    return this.http.put(getServiceUrl(AppService.UpdateUser), body, this.httpOptions);
  }

  /**
   * User List Data
   * param {} headerData
   * returns {Observable<User>}
   * memberof UserService
   */
  getUsersList(headerData): Observable<User[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<User[]>(getServiceUrl(AppService.UserList), httpOptions);
  }

  /**
   * Delete User with id
   * param  Id
   * returns
   * memberof SystemListService
   */
  deleteUser(Id) {
    return this.http.delete(getServiceUrl(AppService.DeleteUser, Id), this.httpOptions);
  }

  /**
   * User Claims Data
   * param {} headerData
   * returns {Observable<UserAccess>}
   * memberof UserService
   */
  getUsersClaims(headerData): Observable<Access> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'userEmailId': headerData.userEmailId
      })
    };
    return this.http.get<Access>(getServiceUrl(AppService.GetUserClaims), httpOptions);
  }

  /**
   * Add User
   * returns true
   * memberof UserService
   */
   requestAccess(data, prefferedName) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'eventType': '0',
        'preferredName': prefferedName
      })
    };
    const body = JSON.stringify(data);
    return this.http.post(getServiceUrl(AppService.SendEmailNotification), body, httpOptions);
  }

  /**
   * Get Pending Access Requests
   */
   getPendingAccess(email: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'userEmail': email
      })
    };
    return this.http.get<ViewAccess>(getServiceUrl(AppService.GetUserAccessRequestData), httpOptions);
  }

}