import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SrdoDataService } from 'src/app/services/component/srdo-data.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { UserAccess } from 'src/app/global/user-access';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-pagedetails',
  templateUrl: './pagedetails.component.html',
  styleUrls: ['./pagedetails.component.scss']
})
export class PagedetailsComponent implements OnInit {

  @ViewChild('kgrid') Kgrid: ElementRef;
  public isNew: boolean;
  faEdit = faEdit;
  public gridData: any[];
  public formGroup: UntypedFormGroup;
  private editedRowIndex: number;
  pageAccess = false;

  constructor(
    private srdoDataService: SrdoDataService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public toastService: ToastService,
    private useraccess: UserAccess,
    public commonService: CommonService,


  ) { }

  ngOnInit() {
    this.router.paramMap.subscribe(params => {
      if (this.router.snapshot.paramMap.get('id') != null) {
        this.spinner.show();
        const componentVersionId = this.router.snapshot.paramMap.get('componentVersionId');
        this.checkAccess('SavePageMappingDetail', Number(this.router.snapshot.paramMap.get('id')));
        this.getPageDetails(Number(componentVersionId));
      }
    });
  }

  getPageDetails(componentId) {
    this.srdoDataService.getPageMappingDetails(componentId).subscribe((data) => {
      if (data.length > 0) {
        this.gridData = data;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    }, () => {
      this.spinner.hide();
    });
  }
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.formGroup.get('pageNumber').disable()
    sender.editRow(rowIndex, this.formGroup);
  }
  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formGroup = new UntypedFormGroup({
      pageNumber: new UntypedFormControl(dataItem.pageNumber),
      pageText: new UntypedFormControl(dataItem.pageText, Validators.required)
    });
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const inputData = formGroup.getRawValue();
    if(sender !== null)
      sender.closeRow(rowIndex);
    const formData = {
      pageNumber : inputData.pageNumber,
      pageText :  inputData.pageText,
      componentId: this.router.snapshot.paramMap.get('componentVersionId')
    };
    this.spinner.show();
    this.srdoDataService.updatePageDetails(formData).subscribe((res) => {
      if (res === true) {
        this.ToastMessage('Page Details Successfully Updated.', 'bg-success', 'Success');
        this.getPageDetails(this.router.snapshot.paramMap.get('componentVersionId'));
      } else {
        this.ToastMessage('HTTP ERROR....', 'bg-danger', 'Error');
        this.spinner.hide();
      }
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  ToastMessage(message, type, Header) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: Header
    });
  }
  public checkAccess(action: string, dataItem: number = -1) {
    this.pageAccess = this.useraccess.getUserAccess('components', action, dataItem);
  }
}
