// src/app/services/interceptor.service.ts
import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest,
  HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, mergeMap, retry } from 'rxjs/Operators';
import { ToastService } from '../services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from '@progress/kendo-angular-notification';
import { MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(public toastService: ToastService, private authService: MsalService , 
    private notificationService: NotificationService,
    private cookie: CookieService,
    ) {
     }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return from(this.authService.acquireTokenSilent({ account: this.authService.instance.getAllAccounts()[0],
      scopes: [`${environment.msalConfig.auth.clientId}/.default`] })).pipe(
      mergeMap(tokenResponse => {
        const token = tokenResponse.accessToken;
        const authReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(authReq).pipe(
          retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
            this.ToastMessage(errorMessage, 'bg-danger');
          } else {
            const httpErrorCode = error.status;
            const httpError = error.error;
            switch (httpErrorCode) {
              case 404:
                errorMessage = `The requested page could not be found`;
                break;
              case 401:
                window.location.replace('/error')
                  break;
                case 400:
                  errorMessage = error.error;
                  break;
              default:
                errorMessage = httpError;
                break;
            }
            if (errorMessage) {
              this.ToastMessage(errorMessage, 'bg-danger');
            }
          }
          return throwError(errorMessage);
        })
      );
      })
    );
  }
  

  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: false,
      headertext: 'Error',
    });
  }

  handleBadRequest(httpError) {
    let errorMessage = '';
    if (typeof httpError === 'object') {
      const erroApiMsg = JSON.parse(JSON.stringify(httpError));
      let erroMsg = '';
      for (const errorkey in erroApiMsg) {
        if (erroApiMsg.hasOwnProperty(errorkey)) {
          erroMsg += erroApiMsg[errorkey];
        }
      }
      errorMessage = erroMsg;
    } else {
      errorMessage = httpError;
    }
    return errorMessage;
  }
}
