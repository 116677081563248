import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserAccess } from '../../global/user-access';
import { CommonService } from '../../services/common.service';
import { CommandResponseService } from '../../services/component/command-response.service';


@Component({
  selector: 'app-component-data-text-file',
  templateUrl: './component-data-text-file.component.html',
  styleUrls: ['./component-data-text-file.component.scss']
})
export class ComponentDataTextFileComponent implements OnInit {

  textImportFile: UntypedFormGroup;
  faFileImport = faFileImport;
  textFileData: {};
  @Input() routerId: number;
  submitted = false;
  private subscription: Subscription;
  ImportDataText = false;
  constructor(private commandService: CommandResponseService,
              private formBuilder: UntypedFormBuilder,
              private router: ActivatedRoute,
              public toastService: ToastService,
              private spinner: NgxSpinnerService,
              public commonService: CommonService,
              private useraccess: UserAccess ) { }

  ngOnInit() {
    this.textImportFile = this.formBuilder.group({
      formFile: ['', Validators.required]
    });
    this.subscription = this.commonService.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'call_child') {
        this.spinner.show();
        this.commandService.GetUploadedTextFileService(
          res.value).subscribe(
          ( restext ) => {
            this.textFileData = restext.dataText;
            this.spinner.hide();
        }, () => {
          this.spinner.hide();
        });
      }
    });
    // tslint:disable-next-line: deprecation
    this.router.paramMap.subscribe(params => {
      if (this.router.snapshot.paramMap.get('id') != null) {
        this.checkAccess(CommandDataTextFieldActions.ImportDataText, Number(this.router.snapshot.paramMap.get('id')));
        this.commandService.GetUploadedTextFileService(
        this.router.snapshot.paramMap.get( 'componentVersionId')).subscribe(
        ( restext ) => {
          this.textFileData = restext.dataText;
          this.spinner.hide();
        }, () => {
        this.spinner.hide();
        });
      }
    });
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.textImportFile.get('formFile').setValue(file);
      this.onSubmit();
    }
  }

  onSubmit() {
    const formData = new FormData();
    this.spinner.show();
    formData.append('formFile', this.textImportFile.get('formFile').value);
    this.commandService.textFileUploadService(formData, this.router.snapshot.paramMap.get( 'componentVersionId')).subscribe(
      (res) => {
        if (res === true) {
          this.commandService.GetUploadedTextFileService(
            this.router.snapshot.paramMap.get( 'componentVersionId')).subscribe(
            ( restext ) => {
              this.spinner.hide();
              this.textFileData = restext.dataText;
              this.ToastMessage('Successfully uploaded.', 'bg-success');
          });
        }
      }, (() => {
        this.spinner.hide();
      }));
  }
  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: 'Success'
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public checkAccess(action: string, dataItem: number = -1) {
    switch(action) {
      case CommandDataTextFieldActions.ImportDataText:
        this.ImportDataText = this.useraccess.getUserAccess('components', action, dataItem !== -1 ? +dataItem : -1);
        break;
      default:
        break;
    }
  }
}
export enum CommandDataTextFieldActions {
  ImportDataText = 'ImportDataText',
}

