import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version = '';
  currentYear: number ;
  faCopyRight = faCopyright;
  constructor() { }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    this.version = environment.version;
    this.currentYear = new Date(). getFullYear();

  }

}
