import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SrdoModel } from 'src/app/models/component/srdo-data.model';
import { getServiceUrl, AppService } from 'src/app/global/global';

@Injectable({
  providedIn: 'root'
})

export class SrdoDataService {

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  /**
   * SRDO List Data
   * param {} headerData
   * returns {Observable<any>}
   * memberof SrdoDataService
   */
  getSrdoMasterListData(): Observable<any> {
    return this.http.get<any>(getServiceUrl(AppService.GetSRDOMasterData), this.httpOptions);
  }
  /**
   * Get SRDO Data based on Srdo Id
   * param {} headerData
   * returns {Observable<SrdoDataModel>}
   * memberof SrdoDataService
   */


  getSrdoDataList(Id): Observable<SrdoModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        srdoid: Id
      })
    };
    return this.http.get<SrdoModel>(getServiceUrl(AppService.SrdoList), httpOptions);
  }

  srdoFileUploadService(data, componentId) {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentid: componentId
      })
    };
    return this.http.post(getServiceUrl(AppService.SRDOImport), data, this.httpOptions);
  }

  getSRDOlistbyComponentService(componentId) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        componentid: componentId
      })
    };
    return this.http.get(getServiceUrl(AppService.SrdoListByComponent), this.httpOptions);
  }
  /**
     * Get Pages based on comp id and version 
     * headerData
     * returns {Observable}
     * memberof getPageByComponentIdVersionNumber
     */
  getPageByComponentIdVersionNumber(componentid: string, versionnumber: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentId: componentid,
        versionNumber: versionnumber
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.getPageByComponentIdVersionNumber), this.httpOptions);
  }
  /**
   * Get Pages based on comp id and version 
   * headerData
   * returns {Observable}
   * memberof getSrdoByComponentIdVersionNumber
   */
  getSrdoByComponentIdVersionNumber(componentId, versionNumber, pageNumber): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        versionNumber: versionNumber.toString(),
        pageNumber
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.getSrdoByComponentIdVersionNumber), this.httpOptions);
  }

  /**
    * Delete SRDO with id
    * param  Id
    * returns
    * memberof SRDO service
    */

  deleteSrdo(Id) {
    return this.http.delete(getServiceUrl(AppService.SRDODelete, Id), this.httpOptions);
  }

 /**
    // tslint:disable-next-line: jsdoc-format
    * Add SRDO with id
    * param  JSON data
    * returns true
    * memberof SRDO service
    */

   addSRDOData(srdoData,componentId){
    this.httpOptions = {
      headers: new HttpHeaders({
        componentId : componentId.toString(),
    })
   };
    return this.http.post(getServiceUrl(AppService.addSRDOData), srdoData, this.httpOptions);
  }

  /**
    * Get Next SRDO id with page number and component Id
    * param  page, Componet Id
    * returns
    * memberof SRDO service
    */
  getNextSrdoOfCompnent(page,componentId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        pageNumber : page.toString(),
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.GetNextSrdoOfComponent), this.httpOptions);
  }
  /**
    // tslint:disable-next-line: jsdoc-format
    * Update SRDO with id
    * param  JSON data
    * returns true
    * memberof SRDO service
    */

   updateSRDOData(srdoData){
    return this.http.put(getServiceUrl(AppService.UpdateSRDO), srdoData, this.httpOptions);
  }

  getPageMappingDetails(componentId): Observable<any> {
    this.httpOptions = {
        headers: new HttpHeaders({
          componentId: componentId.toString(),
        })
      };
    return this.http.get<any>(getServiceUrl(AppService.GetPageMappingDetails), this.httpOptions);
  }
  updatePageDetails(data) {
    return this.http.post(getServiceUrl(AppService.SavePageMappingDetail), data, this.httpOptions);
  }

  /**
   * Copy SRDO to Component 
   * sourceSRDOID - Source SRDO
   * componentId - Destination component
   * pageNumber - Page number of Source SRDO
   * returns {Observable}
   */
   copySRDOFromOtherComp(sourceSRDOID, componentId, pageNumber: string): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        SourceSrdoDbId: sourceSRDOID,
        targetComponentId: componentId,
        targetPageNumber: pageNumber.toString()
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.CopySRDO), this.httpOptions);
  }

}
