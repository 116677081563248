import { environment } from './../../environments/environment';
export enum AppService {
    AllComponents = 'AllComponents',
    ComponentList = 'ComponentList',
    AddComponent = 'AddComponent',
    UpdateComponent = 'UpdateComponent',
    DeleteComponent = 'DeleteComponent',
    EnableRemodificationOfComponentData = 'EnableRemodificationOfComponentData',
    GetBaseVersionComponents = 'GetBaseVersionComponents',
    GetComponentHierarchy = 'GetComponentHierarchy',
    Test = 'Test',
    Stage = 'Stage',
    DownloadTestArtifacts = 'DownloadTestArtifacts',
    GetVersionListByComponent = 'GetVersionListByComponent',
    GetComponentDataText = 'GetComponentDataText',
    Importdatatext = 'Importdatatext',
    AddSystem = 'AddSystem',
    SystemList = 'SystemList',
    UpdateSystem = 'UpdateSystem',
    DeploySystem = 'DeploySystem',
    ImportFeatureFile = 'ImportFeatureFile',
    GetFeatureList = 'GetFeatureList',
    AllSystems = 'AllSystems',
    FeatureListingByFeatureId = 'FeatureListingByFeatureId',
    ImportListing = 'ImportListing',
    DataWidgetList = 'DataWidgetList',
    MenuWidgetList = 'MenuWidgetList',
    WidgetDetailsById = 'WidgetDetailsById',
    import = 'Import',
    GetMenuHierarchy = 'GetMenuHierarchy',
    MenuById = 'MenuById',
    GetSecurityLevel = 'GetSecurityLevel',
    GetSRDOMasterData = 'GetSRDOMasterData',
    DefaultMenu = 'DefaultMenu',
    DeleteSystem = 'DeleteSystem',
    Deploy = 'Deploy',
    DownloadSystemArtifacts = 'DownloadSystemArtifacts',
    TestArtifacts = 'System/DownloadSystemTestArtifacts',
    AddMenuWidget = 'AddMenuWidget',
    UpdateMenuWidget = 'UpdateMenuWidget',
    DeleteMenuWidget = 'DeleteMenuWidget',
    FeatureAdd = 'Feature/Add',
    AddDataWidget = 'AddDataWidget',
    UpdateDataWidget = 'UpdateDataWidget',
    DeleteDataWidget = 'DeleteDataWidget',
    FeatureUpdate = 'Feature/Update',
    FeatureDelete = 'Feature/delete',
    InputDataUpdate = 'InputData/Update',
    MenuAdd = 'Menu/Add',
    DeleteMenu = 'Menu/Delete',
    RemoveFeatureLink = 'RemoveFeatureLink',
    FeatureListBySystem = 'FeatureListBySystem',
    UnMappedFeatureListBySystem = 'UnMappedFeatureListBySystem',
    updateMenu = 'Menu/Update',
    ChangePosition = 'ChangePosition',
    DefaultAll = 'DefaultAll',
    FeatureListingMasterData = 'GetFeatureListingMasterData',
    GetFeatureDataWidgetList = 'GetDataWidgetListWithremainingSize',
    GetComponentVersionList = 'GetComponentVersionList',
    getPageByComponentIdVersionNumber = 'getPageByComponentIdVersionNumber',
    getSrdoByComponentIdVersionNumber = 'getSrdoByComponentIdVersionNumber',
    getCommandByComponentIdVersionNumber = 'getCommandByComponentIdVersionNumber',
    GetFeatureListing = 'ListingByFeatureId',
    AddFeatureList = 'Listing/Add',
    SwapFeatureListingPosition = 'Listing/Reorder',
    deleteFeatureListing = 'Listing/delete',
    featureListingUpdate = 'Listing/Update',
    SRDODelete = 'Delete',
    addSRDOData = 'Add',
    GetNextSrdoOfComponent = 'GetNextSrdoOfComponent',
    UpdateSRDO = 'Update',
    SrdoList = 'SrdoList',
    SRDOImport = 'import',
    SrdoListByComponent = 'SrdoListByComponent',
    AddCommand = 'AddCommand',
    DeleteCommand = 'DeleteCommand',
    UpdateCommand = 'UpdateCommand',
    AddResponseArgument = 'AddCommandResponse',
    AddCommandArgument = 'AddCommandArgument',
    DeleteResponse = 'DeleteResponse',
    DeleteResponseArgument = 'DeleteResponseArgument',
    DeleteCommandArgument = 'DeleteCommandArgument',
    UpdateCommandArgument = 'UpdateCommandArgument',
    UpdateCommandResponse = 'UpdateCommandResponse',
    GetBitmaskDataType = 'GetBitmaskDatatype',
    AddVersion = 'AddVersion',
    RenameVersion = 'RenameVersion',
    DeleteVersion = 'DeleteVersion',
    AddUser = 'AddUser',
    UserList = 'GetUsers',
    DeleteUser = 'DeleteUser',
    UpdateUser = 'UpdateUser',
    GetUserClaims = 'GetUserClaims',
    SystemTest = 'SystemTest',
    GetRegions = 'GetRegions',
    GetNextSubTypeOfComponent = 'GetNextSubTypeOfComponent',
    GetPageMappingDetails = 'GetPageMappingDetail',
    SavePageMappingDetail = 'SavePageMappingDetail',
    CopySystem = 'CopySystemData',
    CopySRDO = 'CopySrdo',
    CopyMenu = 'CopyMenus',
    CopyCommand = 'CopyCommandResponse',
    copyResponseCode = 'CopyResponseWithArgumentData',
    CommandResponseDataImport = 'CommandResponseDataImport',
    GetCommandResponseData = 'GetCommandResponseData',
    GetCommandResponseData_2 = 'GetCommandResponseData',
    GetCommandsByComponentId = 'GetCommandsByComponentId',
    GetComponentResourceList = 'GetComponentResourceList',
    SaveResource = 'SaveComponentResource',
    DeleteResource = 'DeleteComponentResource',
    UpdateResource = 'UpdateComponentResource',
    GetLinkedFeaturesList = 'GetMappedFeatures',
    SendEmailNotification = 'SendEmailNotification',
    GetUserAccessRequestData = 'GetUserAccessRequestData',
    GetComponentDataForNavigation = 'GetComponentDataForNavigation',
    CopyFeatureDataByFeatureId = 'CopyFeatureDataByFeatureId',
    GetComponentNavigationIdForMenu = 'GetComponentNavigationIdForMenu',
    GetAuditLogData = 'GetAuditLogData',
    GetToolTips = 'GetToolTips',
    GetComponentAssociatedUsers = 'GetComponentAssociatedUsers',
    GetSystemAssociatedUsers = 'GetSystemAssociatedUsers',
    GetSystemInfoByDataWidgetId = 'GetSystemInfoByDataWidgetId',
    ImportCommonMenu = 'ImportCommonMenu',
    GetCommonMenuHierarchy = 'GetCommonMenuHierarchy',
    GetCommonMenuTitleList = 'GetCommonMenuTitleList',
    AddCommonMenuTitle = 'AddCommonMenuTitle',
    GetCommonMenuById = 'GetCommonMenuById',
    AddCommonMenu = 'AddCommonMenu',
    EditCommonMenu = 'UpdateCommonMenu',
    LinkCommonMenuToSystemLayer = 'LinkCommonMenuToSystemLayer',
    DeleteCommonMenu = 'DeleteCommonMenu',
    DelinkCommonMenuFromSystemLayer = 'DelinkCommonMenuFromSystemLayer',
    CopySystemMenuToComponentLayer = 'CopySystemMenuToComponentLayer',
    RemoveCommonFeatureLink = 'RemoveCommonFeatureLink',
    AddCommonFeature = 'AddCommonFeature',
    GetCommonFeatures = 'GetCommonFeaturesList',
    GetCommonFeatureData = 'GetCommonFeatureData',
    UpdateCommonFeature = 'UpdateCommonFeature',
    DeleteCommonFeature = 'DeleteCommonFeature',
    CopyCommonFeatureData = 'CopyCommonFeatureData',
    GetListingDataByCommonFeatureId = 'GetListingDataByCommonFeatureId',
    AddCommonFeatureListing = 'AddCommonFeatureListing',
    UpdateCommonFeatureListing = 'UpdateCommonFeatureListing',
    DeleteCommonFeatureListing = 'DeleteCommonFeatureListing',
    ReorderCommonFeatureListing = 'ReorderCommonFeatureListing',
    RearrangeCommonMenuPosition = 'RearrangeCommonMenuPosition',
    UpdateCommonMenuTitle = 'UpdateCommonMenuTitle',

}

export function getServiceUrl(serviceType: AppService, id: string = '', id2: string = ''): string {
    let serviceUrl = environment.apiUrl;
    switch (serviceType) {
        case AppService.AllComponents:
        case AppService.ComponentList:
        case AppService.AddComponent:
        case AppService.UpdateComponent:
        case AppService.GetBaseVersionComponents:
        case AppService.GetComponentHierarchy:
        case AppService.Test:
        case AppService.Stage:
        case AppService.Deploy:
        case AppService.GetComponentDataText:
        case AppService.Importdatatext:
        case AppService.DownloadTestArtifacts:
        case AppService.SavePageMappingDetail:
        case AppService.GetComponentVersionList:
        case AppService.GetLinkedFeaturesList:
        case AppService.EnableRemodificationOfComponentData:
        case AppService.GetComponentAssociatedUsers:
            serviceUrl += '/Component/' + serviceType;
            break;
        case AppService.GetComponentResourceList:
        case AppService.SaveResource:
        case AppService.UpdateResource:
            serviceUrl += '/Component/' + serviceType;
            break;
        case AppService.DeleteResource:
            serviceUrl += '/Component/' + serviceType + '/' + id;
            break;
        case AppService.GetVersionListByComponent:
            serviceUrl += '/Version/' + serviceType;
            break;
        case AppService.DeleteComponent:
            serviceUrl += '/Component/' + serviceType + '/' + id;
            break;
        case AppService.GetPageMappingDetails:
            serviceUrl += '/Component/' + serviceType;
            break;
        case AppService.AddSystem:
        case AppService.SystemList:
        case AppService.UpdateSystem:
        case AppService.DeploySystem:
        case AppService.GetSystemAssociatedUsers:
            serviceUrl += '/System/' + serviceType;
            break;
        case AppService.AllSystems:
            serviceUrl += '/System/' + serviceType;
            break;
        case AppService.DeleteSystem:
            serviceUrl += '/System/' + serviceType + '/' + id;
            break;
        case AppService.ImportFeatureFile:
            serviceUrl += '/Feature/import';
            break;
        case AppService.GetFeatureList:
            serviceUrl += '/Feature/FeatureListBySystem';
            break;
        case AppService.FeatureListingByFeatureId:
        case AppService.AddCommonFeature:
        case AppService.GetCommonFeatures:
        case AppService.GetCommonFeatureData:
        case AppService.UpdateCommonFeature:
        case AppService.DeleteCommonFeature:
        case AppService.CopyCommonFeatureData:
        case AppService.GetListingDataByCommonFeatureId:
        case AppService.AddCommonFeatureListing:
        case AppService.UpdateCommonFeatureListing:
        case AppService.DeleteCommonFeatureListing:
        case AppService.ReorderCommonFeatureListing:
            serviceUrl += '/Feature/' + serviceType;
            break;
        case AppService.ImportListing:
            serviceUrl += '/Feature/' + serviceType;
            break;
        case AppService.DataWidgetList:
        case AppService.MenuWidgetList:
        case AppService.WidgetDetailsById:
        case AppService.GetFeatureDataWidgetList:
        case AppService.GetSystemInfoByDataWidgetId:
            serviceUrl += '/Widget/' + serviceType;
            break;
        case AppService.import:
        case AppService.ImportCommonMenu:
        case AppService.GetCommonMenuTitleList:
        case AppService.AddCommonMenuTitle:
        case AppService.AddCommonMenu:
        case AppService.EditCommonMenu:
        case AppService.LinkCommonMenuToSystemLayer:
        case AppService.DeleteCommonMenu:
        case AppService.DelinkCommonMenuFromSystemLayer:
        case AppService.CopySystemMenuToComponentLayer:
        case AppService.RemoveCommonFeatureLink:
        case AppService.RearrangeCommonMenuPosition:
        case AppService.UpdateCommonMenuTitle:
            serviceUrl += '/menu/' + serviceType;
            break;
        case AppService.GetMenuHierarchy:
        case AppService.GetCommonMenuHierarchy:
            serviceUrl += '/menu/' + serviceType;
            break;
        case AppService.MenuById:
        case AppService.GetCommonMenuById:
            serviceUrl += '/menu/' + serviceType;
            break;
        case AppService.GetSecurityLevel:
        case AppService.GetSRDOMasterData:
        case AppService.GetToolTips:
            serviceUrl += '/MasterData/' + serviceType;
            break;
        case AppService.DefaultMenu:
            serviceUrl += '/Widget/' + serviceType;
            break;
        case AppService.Deploy:
            serviceUrl += '/System/' + serviceType;
            break;
        case AppService.DownloadSystemArtifacts:
            serviceUrl += '/System/' + serviceType;
            break;
        case AppService.TestArtifacts:
            serviceUrl += '/' + serviceType;
            break;
        case AppService.AddMenuWidget:
            serviceUrl += '/Widget/MenuWidget/Add';
            break;
        case AppService.UpdateMenuWidget:
            serviceUrl += '/Widget/MenuWidget/Update';
            break;
        case AppService.DeleteMenuWidget:
            serviceUrl += '/Widget/MenuWidget/delete/' + id;
            break;
        case AppService.FeatureAdd:
            serviceUrl += '/' + serviceType;
            break;
        case AppService.AddDataWidget:
            serviceUrl += '/Widget/DataWidget/Add';
            break;
        case AppService.UpdateDataWidget:
            serviceUrl += '/Widget/DataWidget/Update';
            break;
        case AppService.DeleteDataWidget:
            serviceUrl += '/Widget/DataWidget/delete/' + id;
            break;
        case AppService.FeatureUpdate:
            serviceUrl += '/' + serviceType;
            break;
        case AppService.FeatureDelete:
            serviceUrl += '/' + serviceType + '/' + id;
            break;
        case AppService.InputDataUpdate:
            serviceUrl += '/Widget/DataWidget/' + serviceType;
            break;
        case AppService.MenuAdd:
            serviceUrl += '/' + serviceType;
            break;
        case AppService.DeleteMenu:
            serviceUrl += '/' + serviceType + '/' + id;
            break;
        case AppService.FeatureListBySystem:
            serviceUrl += '/Feature/' + serviceType;
            break;
        case AppService.UnMappedFeatureListBySystem:
        case AppService.GetComponentDataForNavigation:
        case AppService.CopyFeatureDataByFeatureId:
            serviceUrl += '/Feature/' + serviceType;
            break;
        case AppService.updateMenu:
            serviceUrl += '/' + serviceType;
            break;
        case AppService.ChangePosition:
        case AppService.GetComponentNavigationIdForMenu:
            serviceUrl += '/Menu/' + serviceType;
            break;
        case AppService.DefaultAll:
            serviceUrl += '/Widget/' + serviceType;
            break;
        case AppService.GetRegions:
        case AppService.FeatureListingMasterData:
        case AppService.GetBitmaskDataType:
            serviceUrl += '/MasterData/' + serviceType;
            break;
        case AppService.getPageByComponentIdVersionNumber:
        case AppService.getSrdoByComponentIdVersionNumber:
        case AppService.addSRDOData:
        case AppService.UpdateSRDO:
        case AppService.GetNextSrdoOfComponent:
        case AppService.SrdoList:
        case AppService.SRDOImport:
        case AppService.SrdoListByComponent:
            serviceUrl += '/SRDO/' + serviceType;
            break;
        case AppService.SRDODelete:
            serviceUrl += '/SRDO/' + serviceType + '/' + id;
            break;
        case AppService.getCommandByComponentIdVersionNumber:
        case AppService.copyResponseCode:
            serviceUrl += '/CommandResponseData/' + serviceType;
            break;
        case AppService.CommandResponseDataImport:
            serviceUrl += '/CommandResponseData/import';
        break;
        case AppService.GetCommandsByComponentId:
            serviceUrl += '/CommandResponseData/' + serviceType + '/' + id;
            break;
        case AppService.GetCommandResponseData_2:
            serviceUrl += '/CommandResponseData/' + serviceType + '/' + id + '/' + id2;
            break;
        case AppService.GetCommandResponseData:
            serviceUrl += '/CommandResponseData/' + serviceType + '/' + id;
            break;
        case AppService.GetFeatureListing:
        case AppService.AddFeatureList:
        case AppService.featureListingUpdate:
            serviceUrl += '/Feature/' + serviceType;
            break;
        case AppService.SwapFeatureListingPosition:
            serviceUrl += '/Feature/' + serviceType;
            break;
        case AppService.deleteFeatureListing:
            serviceUrl += '/Feature/' + serviceType + '/' + id;
            break;
        case AppService.AddCommand:
        case AppService.GetNextSubTypeOfComponent:
        case AppService.AddResponseArgument:
            serviceUrl += '/CommandResponseData/' + serviceType;
            break;
        case AppService.DeleteCommand:
        case AppService.DeleteResponse:
        case AppService.DeleteResponseArgument:
            serviceUrl += '/CommandResponseData/' + serviceType + '/' + id;
            break;
        case AppService.UpdateCommand:
            serviceUrl += '/CommandResponseData/' + serviceType + '/' + id;
            break;
        case AppService.AddCommandArgument:
            serviceUrl += '/CommandResponseData/' + serviceType;
            break;
        case AppService.DeleteCommandArgument:
            serviceUrl += '/CommandResponseData/' + serviceType + '/' + id;
            break;
        case AppService.DeleteVersion:
            serviceUrl += '/Version/' + serviceType + '/' + id;
            break;
        case AppService.UpdateCommandArgument:
        case AppService.UpdateCommandResponse:
            serviceUrl += '/CommandResponseData/' + serviceType;
            break;
        case AppService.CopyCommand:
            serviceUrl += '/CommandResponseData/' + serviceType;
            break;
        case AppService.AddVersion:
        case AppService.RenameVersion:
            serviceUrl += '/Version/' + serviceType;
            break;
        case AppService.AddUser:
        case AppService.UserList:
        case AppService.UpdateUser:
        case AppService.GetUserClaims:
        case AppService.GetAuditLogData:
            serviceUrl += '/User/' + serviceType;
            break;
        case AppService.DeleteUser:
            serviceUrl += '/User/' + serviceType + '/' + id;
            break;
        case AppService.SystemTest:
            serviceUrl += '/System/TestSystem';
            break;
        case AppService.RemoveFeatureLink:
            serviceUrl += '/Menu/' + serviceType;
            break;
        case AppService.CopyMenu:
            serviceUrl += '/Menu/' + serviceType;
            break;
        case AppService.CopySystem:
            serviceUrl += '/System/' + serviceType;
            break;
        case AppService.CopySRDO:
            serviceUrl += '/SRDO/' + serviceType;
            break;
        case AppService.SendEmailNotification:
        case AppService.GetUserAccessRequestData:
            serviceUrl += '/User/'  + serviceType;
            break;
    }
    return serviceUrl;
}
