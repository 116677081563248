import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { faCopy, faEdit, faFileImport, faPlus, faTimes, faTrash, faCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnumArgument, EnumValues } from 'src/app/common/enum-edit/enum-edit.component';
import { DataType } from 'src/app/models/component/srdo-data.model';
import { SrdoDataService } from 'src/app/services/component/srdo-data.service';
import { CommandResponseService } from '../../services/component/command-response.service';
import { CustomFormValidationsService } from '../../shared/formValidations/custom-form-validations.service';

@Component({
  selector: 'app-create-response-argument',
  templateUrl: './create-response-argument.component.html',
  styleUrls: ['./create-response-argument.component.scss']
})

export class CreateResponseArgumentComponent implements OnInit {

  public createResponseArgumentForm: UntypedFormGroup;
  public arguments: UntypedFormArray;
  submitted = false;
  faTimes = faTimes;
  faEdit = faEdit;
  faPlus = faPlus;
  faTrash = faTrash;
  faCopy = faCopy;
  faFileImport = faFileImport;
  @Input() editId: number;
  @Input() editData: any;
  @Input() responseCodes: [any];
  @Input() toolTips: DataType[];
  @Input() title: any;
  @Input() isReopened : boolean;
  @Output() submitFormData = new EventEmitter();
  primitiveDataType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 21, 22, 23, 24, 25]; //20 CHAR, 21-24 Hex, 25 - Text
  enumDataType = [15, 16, 17];
  bitmaskDataType = [11, 12, 13, 14];
  argumentDataType: DataType[] = [];
  dataTypeName: any = '';
  extraValueSplit: any = '';
  bitmaskDataTypeArr = [];
  commandId: number;
  responseCategoryArr = [];
  public formGroup: UntypedFormGroup;
  public gridView: any = [{}];
  errorVar = '' ;
  displayEnumRows = {};
  displayBitmaskEnumRows = {};
  formDataToEnumEdit = ''
  isBitMaskEnum = false
  validationErrorMessageBitMask = 'Value should be a number'
  validationErrorMessage = {}
  maxminError = 'Maximum value is less than the minimum value. Please check your data and try again.'
  colorClasses = {
    Alert: '#FFB81C',
    Success: '#75B80C',
    Stop: '#CC3333',
    Info: '#233E95'
  };  
  constructor(
    public activeModal: NgbActiveModal,
    public fb: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private srdoDataService: SrdoDataService,
    private commandResponseService: CommandResponseService,
    private customFormValidationsService: CustomFormValidationsService,
  ) {
    this.getDataType();
  }
  ngOnInit() {
    this.errorVar = '' ;
    this.initform();
  }

  hideAdd(control, i, EnumIndex, bitmaskIndex?) {
    if (control === 'enum') {
      document.getElementById('index_' + i + '_' + EnumIndex).style.display = 'none';
      document.getElementById('value_' + i + '_' + EnumIndex).style.display = 'none';
      let enumbtn = document.getElementById('add_enumbtn_' + i + '_' + EnumIndex)
      if(enumbtn) {
        enumbtn.style.display = 'none';
      }
      document.getElementById('index_span_' + i + '_' + EnumIndex).style.display = 'block';
      document.getElementById('value_span_' + i + '_' + EnumIndex).style.display = 'block';
      document.getElementById('edit_enumbtn_' + i + '_' + EnumIndex).style.display = 'inline-block';
    } else {
      document.getElementById('bit_index_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('bit_value_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('add_bitmask_enumbtn_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';

      document.getElementById('bit_index_span_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('bit_value_span_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('edit_bitmask_enumbtn_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'inline-block';

    }
  }
  
  hideEdit(control, i, EnumIndex, bitmaskIndex?) {
    if (control === 'enum') {
      document.getElementById('index_' + i + '_' + EnumIndex).style.display = 'block';
      document.getElementById('value_' + i + '_' + EnumIndex).style.display = 'block';
      let enumbtn = document.getElementById('add_enumbtn_' + i + '_' + EnumIndex)
      if(enumbtn) {
        enumbtn.style.display = 'inline-block';
      }
      document.getElementById('index_span_' + i + '_' + EnumIndex).style.display = 'none';
      document.getElementById('value_span_' + i + '_' + EnumIndex).style.display = 'none';
      document.getElementById('edit_enumbtn_' + i + '_' + EnumIndex).style.display = 'none';
    } else {
      document.getElementById('bit_index_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('bit_value_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('add_bitmask_enumbtn_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'inline-block';
      document.getElementById('bit_index_span_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('bit_value_span_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('edit_bitmask_enumbtn_' + i + '_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
    }
  }

  initform() {
    this.createResponseArgumentForm = this.fb.group({
      responseCode: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(255)]],
      displayText: [null, Validators.required],
      description: [null, Validators.required],
      responseCategoryId: [1, Validators.required],
      arguments: this.fb.array([])
    });
    if (this.editId !== undefined) {
      this.editresponseArgument(this.editData);
      if (this.editId !== undefined) {
        setTimeout(() => {
          this.hideEditPageBtn();
        }, 500);
      }
    }
  }

  createResponseArgs(): UntypedFormGroup {
    return this.fb.group({
      argument_text: [null, [Validators.required]],
      argument_description: [null, Validators.required],
      dataType: [null, [Validators.required]],
      dataPosition: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(65535)]],
      count: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(255)]],
      min: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      max: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      enums: this.fb.array([this.enums]),
      bitmasks: this.fb.array([this.bitmask]),
      extraValues: [null],
      argument_id: null
    }, {
      validator: this.customFormValidationsService.MatchDataTypeValidations('min', 'max', 'dataType', 'extraValues', null),
    });
  }

  get enums(): UntypedFormGroup {
    return this.fb.group({
      index: '',
      value: ''
    });
  }

  deleteEnums(argument, index) {
    argument.get('enums').removeAt(index);
  }

  addEnums(argument) {
    argument.get('enums').push(this.enums);
  }

  get bitmask(): UntypedFormGroup {
    return this.fb.group({
      bits: [null],
      data_type: [null],
      value: [null],
      bitmasks_enums: this.fb.array([this.bitmaskEnums])
    });
  }

  deleteBitmasks(argument, index) {
    argument.get('bitmasks').removeAt(index);
  }

  addBitmask(argument) {
    argument.get('bitmasks').push(this.bitmask);
    const bitMaskControl = argument.controls.bitmasks.controls;
    if (bitMaskControl !== undefined || bitMaskControl.length > 0) {
      this.setBitmasksValidator(bitMaskControl);
    }
  }

  get bitmaskEnums() {
    return this.fb.group({
      bit_index: [null],
      bit_value: [null]
    });
  }

  addBitmaskEnum(bitmasks) {
    bitmasks.get('bitmasks_enums').push(this.bitmaskEnums);
  }

  deleteBitmaskEnums(bitmasks, index) {
    bitmasks.get('bitmasks_enums').removeAt(index);
  }

  get argumentControls() {
    return this.createResponseArgumentForm.get('arguments')['controls'];
  }

  get dataType() {
    return this.createResponseArgumentForm.get('dataType');
  }
  get getArgumentControls() {
    return this.createResponseArgumentForm.get('arguments') as UntypedFormArray;
  }

  addResponseArgument(): void {
    this.arguments = this.createResponseArgumentForm.get('arguments') as UntypedFormArray;
    this.arguments.push(this.createResponseArgs());
  }

  removeResponseArgument(index: number) {
   // this.argumentControls.removeAt(index);
    this.arguments = this.createResponseArgumentForm.get('arguments') as UntypedFormArray;
    this.arguments.removeAt(index);
    if(Object.keys(this.validationErrorMessage).includes(index.toString())) {
      delete this.validationErrorMessage[index]
    }
  }

  getDataType() {
    this.srdoDataService.getSrdoMasterListData().subscribe((data) => {
      if (data !== null) {
        const dataType = data.srdoDataTypes.filter((x) => x.name != 'Command');
        const argumentDataType = dataType.filter((dataTypeVal) => {
          return dataTypeVal.name !== 'Custom';
        });
        this.argumentDataType = argumentDataType;

        this.responseCategoryArr = data.responseCategories;
      }
      this.getBitmaskDataType();
      this.spinner.hide();
    },
      (() => {
        this.spinner.hide();
      }));
  }

  getBitmaskDataType() {
    this.commandResponseService.getBitmaskDataType().subscribe((data) => {
      if (data !== null) {
        this.bitmaskDataTypeArr = data;
      }
      this.spinner.hide();
    },
      (() => {
        this.spinner.hide();
      }));
  }

  editresponseArgument(editDataSet) {
    this.createResponseArgumentForm.patchValue({
      responseCode: editDataSet.responseCode,
      displayText: editDataSet.displayText,
      description: editDataSet.description,
      responseCategoryId: editDataSet.responseCategory.id
    });
    if(this.isReopened) {
      this.createResponseArgumentForm.get('responseCode')?.disable()
    }
    this.createResponseArgumentForm.setControl('arguments', this.editarguments(editDataSet.responseArguments));
    const argumentsControl = this.createResponseArgumentForm.get('arguments')['controls'];
    if(argumentsControl) {
      argumentsControl.forEach((element, i) => {
        this.setValidation(i)
      })
    }
  }

  /** 
   * Editing the command response argument
   * 
   * @param argumentsVal - Array of form controls
   * @returns FormArray - Array of form controls
  **/
  editarguments(argumentsVal): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    if (argumentsVal.length > 0) {
      argumentsVal.forEach((element, index) => {
        formArray.push(this.fb.group({
          argument_text: [element.displayText, [Validators.required]],
          argument_description: [element.description, [Validators.required]],
          dataType: [element.dataType.id.toString()],
          dataPosition: [element.dataPosition, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(65535)]],
          count: [element.count, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(255)] ],
          min: [element.minValue, element.dataType.id === 20 ? [Validators.required, Validators.min(0), Validators.pattern('^[0-9]*$')]: [Validators.required, Validators.pattern('^-?[0-9]*$')]],
          max: [element.maxValue, element.dataType.id === 20 ? [Validators.required, Validators.max(255), Validators.pattern('^[0-9]*$')]: [Validators.required, Validators.pattern('^[0-9]*$')]],
          enums: this.setEnums(element),
          bitmasks: this.setBitmasks(element),
          argument_id : element.id
        }));
      });
    }
    // iterate over the FormArray controls
    for (let i = 0; i < formArray.length; i++) {
      formArray.at(i).get('dataType').disable({ onlySelf: true });
    }
    return formArray;
  }

  setEnums(enumsArr) {
    if (enumsArr.dataTypeDetails!=null  && enumsArr.dataTypeDetails.length > 0) {
      const formEnumArray = new UntypedFormArray([]);
      enumsArr.dataTypeDetails.forEach((element, i) => {
        formEnumArray.push(this.fb.group({
          index: element.index,
          value: element.value
        }));
      });
      return formEnumArray;
    }
  }

  setBitmasks(bitmasksArr) {
    if (bitmasksArr.dataTypeDetails !=null && bitmasksArr.dataTypeDetails.length > 0) {
      const formBitMaskArray = new UntypedFormArray([]);
      bitmasksArr.dataTypeDetails.forEach((element, i) => {
        formBitMaskArray.push(this.fb.group({
          bits: element.index,
          data_type: [{ value: element.dataTypeId, disabled: true }],
          value: element.text,
          bitmasks_enums: this.setBitmasksEnums(element)
        }));
      });
      return formBitMaskArray;
    }
  }

  setBitmasksEnums(argumentsArr) {
    if (argumentsArr.dataTypeId === 1 && argumentsArr.value.length > 0) {
      const formBitMaskArray = new UntypedFormArray([]);
      argumentsArr.value.forEach((element, i) => {
        formBitMaskArray.push(this.fb.group({
          bit_index: element.index,
          bit_value: element.value,
        }));
      });
      return formBitMaskArray;
    }
  }

  hideEditPageBtn() {
    const argumentsControl = this.createResponseArgumentForm.get('arguments')['controls'];
    if(argumentsControl) {
      argumentsControl.forEach((element, i) => {
        const bitmasksControl = element.controls.bitmasks.controls; //bitmask row
        const enumControl = element.controls.enums.controls; //enum row
        //Hiding enum input field during edit
        enumControl.forEach((bitmask, eIndex) => {
          this.hideAdd('enum', i, eIndex, '');
        });
        //Hiding bitmask enum input field during edit
        bitmasksControl.forEach((bitmask, j) => {
          if (bitmask.controls.bitmasks_enums.controls !== undefined) {
            const bitmasksEnumControl = bitmask.controls.bitmasks_enums.controls;
            bitmasksEnumControl.forEach((bitmaskEnum, k) => {
              this.hideAdd('bitmask_enum', i, k, j);
            });
          }
        });
      });
    }
  }

/* 
   Returns an array of invalid control/group names, or a zero-length array if 
   no invalid controls/groups where found 
   source - https://stackoverflow.com/questions/45220073/how-to-find-the-invalid-controls-in-angularv2-onwards-reactive-form/52312518#52312518
*/
public findInvalidControlsRecursive(formToInvestigate:UntypedFormGroup|UntypedFormArray):AbstractControl[] {
  var invalidControls:AbstractControl[] = [];
  let recursiveFunc = (form:UntypedFormGroup|UntypedFormArray) => {
    Object.keys(form.controls).forEach(field => { 
      const control = form.get(field);
      if (control.invalid) invalidControls.push(control);
      if (control instanceof UntypedFormGroup) {
        recursiveFunc(control);
      } else if (control instanceof UntypedFormArray) {
        recursiveFunc(control);
      }        
    });
  }
  recursiveFunc(formToInvestigate);
  return invalidControls;
}

  submitForm() {
    this.submitted = true;
    this.errorVar = '' ;

    // tslint:disable-next-line:no-shadowed-variable
    const invalid = [];
    const controls = this.createResponseArgumentForm.controls;
    // tslint:disable-next-line:no-shadowed-variable
    for (const name in controls) {
      if (controls[name].invalid) {
        switch(name){
          case 'responseCode':
            invalid.push('Response Code')
            break;
          case 'displayText':
            invalid.push(' Text')
            break;
          case 'description':
            invalid.push(' Description ')
            break;
        }
        if(name === "arguments") {
          invalid.push(' Arguments')
          let controlss = this.findInvalidControlsRecursive(<UntypedFormArray>controls[name])
          controlss.forEach((item) => {
            item.markAsTouched()
          })
       } else {
        controls[name].markAsTouched()
       }
      } else {
        if (controls.arguments.value.length > 0) {
            if (Object.keys(controls.arguments.value).length > 0) {
              let i = 0;
              for (const argLoop in controls.arguments.value) {
                let data_type = this.createResponseArgumentForm.getRawValue().arguments[argLoop].dataType;
                // let data_type = controls.arguments.value[argLoop].dataType;
                if (data_type === '11' || data_type == '12' || data_type === '13' || data_type == '14') {
                  if (Object.keys(controls.arguments.value[argLoop].bitmasks).length > 0) {
                    // tslint:disable-next-line:forin
                    let bitmaskArr = []
                    controls.arguments.value[argLoop].bitmasks[i].bitmasks_enums?.forEach((item, index) => {
                      bitmaskArr.push(Number(item.bit_index));
                    })
                    if(this.hasDuplicateIndexes(controls.arguments.value[argLoop].bitmasks) || this.hasNegitiveIndexes(bitmaskArr)) {
                      this.errorVar = 'Please provide valid bitmask data';
                      return;
                    }
                    for (const bitmaskLoop in controls.arguments.value[argLoop].bitmasks) {
                        if (controls.arguments.value[argLoop].bitmasks[bitmaskLoop].bits == '' || controls.arguments.value[argLoop].bitmasks[bitmaskLoop].value == '' || 
                            controls.arguments.value[argLoop].bitmasks[bitmaskLoop].bits == null || controls.arguments.value[argLoop].bitmasks[bitmaskLoop].value == null) {
                          this.errorVar = 'Please check the bitmasks data.';
                          // this.submitted =false;
                          return;
                        }
                        if (controls.arguments.value[argLoop].bitmasks[bitmaskLoop].data_type === '1') {
                          if (controls.arguments.value[argLoop].bitmasks[bitmaskLoop].bitmasks_enums.length > 0) {
                            // tslint:disable-next-line:forin
                            for (const enumLoop in controls.arguments.value[argLoop].bitmasks[bitmaskLoop].bitmasks_enums) {
                              if (controls.arguments.value[argLoop].bitmasks[bitmaskLoop].bitmasks_enums[enumLoop].bit_index === null) {
                                  this.errorVar = 'Please check the bitmask enum data.';
                                  // this.submitted =false;
                                  return;
                                }
                            }
                          }
                        }
                      }
                    }  
                } else if (data_type === '15' || data_type == '16' || data_type === '17'){
                  if (Object.keys(controls.arguments.value[argLoop].enums).length > 0) 
                  {
                    for (const enumLoop in controls.arguments.value[argLoop].enums) {
                      if (controls.arguments.value[argLoop].enums[enumLoop].index === '' || controls.arguments.value[argLoop].enums[enumLoop].value === '') {
                        this.errorVar = 'Please check the enum data.';
                        // this.submitted =false;
                        return;
                      }
                    }
                    const enumArray: any[] = [];
                    let enumsArr : any[]= Object.values(controls.arguments.value[argLoop].enums)
                    enumsArr.forEach((element, index) => {
                      enumArray.push(Number(enumsArr[index].index));
                    });
                    if (this.hasDuplicates(enumArray) || this.hasNegitiveIndexes(enumArray)) {
                      this.errorVar = 'Please provide valid enumeration data';
                      // this.submitted =false;
                      return;
                    }
                  }
                }
              }
            }
        }
      }
    }
    if (invalid.length  > 0) {
      this.errorVar = 'Form has some errors.';
      // this.submitted = false;
    }
    if (this.createResponseArgumentForm.invalid) {
      return ;
    }
    const ResponseArgumentDataArr = this.createResponseArgumentForm.getRawValue();
    const responseCode = ResponseArgumentDataArr.responseCode;
    if(this.checkForResponseCodeUniqueness(responseCode, this.editId === undefined ? 0 : this.editId)) {
      this.errorVar = 'Response code should be unique.';
      return;
    }
    const displayText = ResponseArgumentDataArr.displayText;
    const responseCategoryId = ResponseArgumentDataArr.responseCategoryId;
    const description = ResponseArgumentDataArr.description;
    const responseArgumentsArr = [];
    const createdBy = 'test@gmail.com';
    let respArgList: any;
    if (ResponseArgumentDataArr.arguments.length > 0) {
      let i = 0;
      for (const argumentData of ResponseArgumentDataArr.arguments) {
        const argumentText = argumentData.argument_text;
        const argumentDescription = argumentData.argument_description;
        const dataType = this.editId !== undefined ? Number(this.argumentControls[i].controls.dataType.value) :
          Number(argumentData.dataType);
        const dataPosition = argumentData.dataPosition !== null ? argumentData.dataPosition : 0;
        const count = argumentData.count !== null ? argumentData.count : 0;
        const min = argumentData.min !== null ? argumentData.min : 0;
        const max = argumentData.max !== null ? argumentData.max : 0;
        let enumsArr = argumentData.enums;
        const bitmasksArr = argumentData.bitmasks;
        let dataTypeDetails: any = [];
        const primitiveDataTypeFound = this.primitiveDataType.find((primDataType) => {
          return primDataType === dataType;
        });
        const enumDataTypeFound = this.enumDataType.find((enumDataTyp) => {
          return enumDataTyp === dataType;
        });
        const bitmaskDataTypeFound = this.bitmaskDataType.find((bitmaskDataTyp) => {
          return bitmaskDataTyp === dataType;
        });
        if (primitiveDataTypeFound) {
          dataTypeDetails = [{
            inputMin: min,
            inputMax: max,
          }];
        }
        if( Number(argumentData.max) < Number(argumentData.min)) {
          this.errorVar = this.maxminError;
          return;
        }
        if (enumsArr && enumsArr.length > 0) {
          if (enumDataTypeFound) {
            for (const enm of enumsArr) {
              if ((Number( max) < Number( enm.index))  ||
              (Number( min ) > Number( enm.index))) {
                this.errorVar = 'Enumeration value should be between min and max range';
                // this.submitted =false;
                return;
              }
              const enumData = {
                index: enm.index,
                value: enm.value,
                defaultValue: null,
                extraValues: null,
                inputMax: max,
                inputMin: min
              };
              dataTypeDetails.push(enumData);
            }
          }
        }
        if (bitmasksArr && bitmasksArr.length > 0) {
          if (bitmaskDataTypeFound) {
            let bitmaskData;
            const bitmaskControl = this.argumentControls[i].controls.bitmasks.controls;
            let j = 0;
            for (const bitmask of bitmasksArr) {
              const bitMaskDataType = this.editId !== undefined ? bitmaskControl[j].controls.data_type.value : bitmask.data_type;
              const bitmasksEnumsArr = bitmask.bitmasks_enums;
              const bitEnumObjArr = [];
              if (Number(bitMaskDataType) === 1) {
                let enmValu = '';

                const enumArray: any[] = [];
                bitmasksEnumsArr.forEach((element, index) => {
                  enumArray.push(Number(bitmasksEnumsArr[index].bit_index));
                });

                if (this.hasDuplicates(enumArray) || this.hasNegitiveIndexes(enumArray)) {
                  this.errorVar = 'Please provide valid enumeration data';
                  // this.submitted =false;
                  return;
                }
                for (const bitEnum of bitmasksEnumsArr) {
                  const messageFlagBits = this.customFormValidationsService.setBitmaskEnumValidationForValValidations(bitmask.bits, 0);
                  const messageFlag = this.customFormValidationsService.setBitmaskEnumValidationForValValidations(bitmask.bits, 1);
                 
                  if (enmValu < messageFlag  && enmValu === '') {
                    enmValu = bitEnum.bit_index;
                  } else if (enmValu < bitEnum.bit_index) {
                    enmValu = bitEnum.bit_index;
                  }
                  if (enmValu >= messageFlag) {
                    this.errorVar = 'Value should not be greater than ' + (messageFlag-1);
                    // this.submitted =false;
                    return;
                  }

                  const enumObj = {
                    index: bitEnum.bit_index,
                    value: bitEnum.bit_value,
                  };
                  bitEnumObjArr.push(enumObj);
                  if (messageFlag < bitEnumObjArr.length) {
                    this.errorVar = 'Enumeration bit rows should not be greater than ' + messageFlagBits;
                    // this.submitted =false;
                    return;
                  }
                }

                bitmaskData = {
                  index: bitmask.bits,
                  dataTypeId: bitMaskDataType,
                  value: bitEnumObjArr,
                  inputMax: max,
                  inputMin: min,
                  text: bitmask.value
                };
              } else {
                bitmaskData = {
                  index: bitmask.bits,
                  dataTypeId: bitMaskDataType,
                  value: bitmask.value,
                  inputMax: max,
                  inputMin: min,
                  text: bitmask.value
                };
              }
              dataTypeDetails.push(bitmaskData);
              j++;
            }
          }
        }
        let responseArguments;
        if (argumentData.argument_id === null) {
          responseArguments = {
            id: 0,
            commandId: this.commandId,
            displayText: argumentText,
            description: argumentDescription,
            dataType,
            dataTypeDetails,
            dataPosition,
            count
          };
        } else {
          responseArguments = {
            id: argumentData.argument_id,
            displayText: argumentText,
            description: argumentDescription,
            dataType,
            dataTypeDetails,
            dataPosition,
            count
          };
        }
        responseArgumentsArr.push(responseArguments);
        i++;
      }
    } else {
      const responseArguments = {
        displayText: '',
        description: '',
        dataType: 0,
        dataTypeDetails: {},
        dataPosition: null,
        count: null
      };
    }
    respArgList = {
      displayText,
      responseCode,
      responseCategoryId,
      description,
      createdBy,
      responseArguments: responseArgumentsArr
    };
    this.submitFormData.emit(respArgList);
  }

  checkForResponseCodeUniqueness(responseCode: number, id: number) {
    let found = false
    if(this.responseCodes) {
        this.responseCodes.forEach((item) =>  {
        if(item.id !== id && item.responseCode === +responseCode) {
          found = true
        }
        });
    }
     return found;
  }

  resetFeatureList() {
    this.clearModelForm();
  }

  hasDuplicates(arr) {
    return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x));
  }

  hasNegitiveIndexes(arr: number[]) {
    let items = arr.find(x => x < 0);
    if(items) {
      return true
    }
    return false
  }

  hasDuplicateIndexes(arr) {
    let arrayindexes = []
    arr.forEach((x) => {
      arrayindexes.push(x.bits)
    })
    return arrayindexes.some(x => arrayindexes.indexOf(x) !== arrayindexes.lastIndexOf(x));
  }

  clearModelForm() {
    this.createResponseArgumentForm.reset();
    this.getArgumentControls.clear();
    this.submitted = false;
  }

  getBitmaskEnumDisplay(bitmaskDataType) {
    if (Number(bitmaskDataType) === 1) {
      return 'inline-block';
    } else {
      return 'none';

    }
  }
  getDisplay(dataTypeVal, type) {
    if (type === 'Enum') {
      const enumFound = this.enumDataType.find((enumDataTyp) => {
        return enumDataTyp === Number(dataTypeVal);
      });
      if (enumFound) {
        return 'block';
      } else {
        return 'none';
      }
    }
    if (type === 'Bitmask') {
      const bitmaskFound = this.bitmaskDataType.find((enumDataTyp) => {
        return enumDataTyp === Number(dataTypeVal);
      });
      if (bitmaskFound) {
        return 'block';
      } else {
        return 'none';
      }
    }

  }

  setValidation(argumentIndex) {
    const argumentsControl = this.createResponseArgumentForm.get('arguments')['controls'];
    const bitmasksControls = argumentsControl[argumentIndex].controls.bitmasks.controls;
    const dataType = Number(argumentsControl[argumentIndex].controls.dataType.value);
    this.setDefaultMaxMinValidators(argumentIndex);
    if (dataType === 11 || dataType === 12 || dataType === 13 || dataType === 14) {
      if (bitmasksControls !== undefined || bitmasksControls.length > 0) {
        this.setBitmasksValidator(bitmasksControls);
      }
    } else if (dataType === 20) {
      const maxControl = argumentsControl[argumentIndex].controls.max;
      const minControl = argumentsControl[argumentIndex].controls.min;
      minControl.setValidators([Validators.min(0), Validators.required, Validators.pattern('^[0-9]*$')]);
      maxControl.setValidators([Validators.max(255), Validators.required, Validators.pattern('^[0-9]*$')]);
      maxControl.updateValueAndValidity();
    }  
    else {
      if (bitmasksControls && bitmasksControls.length > 0) {
        this.removeBitmasksValidator(bitmasksControls);
      }
    }
    if(this.isReopened) {
      let dataPosition = argumentsControl[argumentIndex].controls.dataPosition;
      if(dataPosition) {
        dataPosition.disable()
      }
      let count = argumentsControl[argumentIndex].controls.count;
      if(count) {
        count.disable()
      }
    }
    this.setDataTypeRanges(dataType, argumentIndex)
  }

  setDataTypeRanges(dataType: number, argumentIndex: number){
    const argumentsControl = this.createResponseArgumentForm.get('arguments')['controls'];
    let min : BigInt= BigInt(0);
    let max: BigInt = BigInt(255);
    let pattern = '^-?[0-9]*$'
    let unsignedpattern = '^[0-9]*$'
    let floatpattern = /^-?\d*[.,]?\d*$/;
    let max8 = BigInt(255)
    let max16 = BigInt(65535)
    let max24 = BigInt(16777215)
    let max32 = BigInt(4294967295)
    let max64 = BigInt(18446744073709551615)
    switch (dataType) {
      case 1://OInt 8
        min =BigInt(-128)
        max = BigInt(127)
        this.setMinMaxValidators(min, max, pattern, argumentIndex)
        break;
      case 2://OInt 16
        min = BigInt(-32768)
        max = BigInt(32767)
        this.setMinMaxValidators(min, max, pattern, argumentIndex)
        break;
      case 3://OInt 32
        min = BigInt(-2147483648)
        max = BigInt(2147483647)
        this.setMinMaxValidators(min, max, pattern, argumentIndex)
        break;
      case 4://OInt 64
        min = BigInt("-9223372036854775808")
        max = BigInt("9223372036854775807")
        this.setMinMaxValidators(min, max, pattern, argumentIndex)
        break;
      case 5://OUInt 8
      case 21://Hex 8
      case 11://Bitmask 8
      case 15://Enumeration 8
      case 19://Command
      case 20://Char
      case 25:
        this.setMinMaxValidators(min, max8, unsignedpattern, argumentIndex)
        break;
      case 6://OUInt 16
      case 22://Hex 16
      case 12://Bitmask 16
      case 16://Enumeration 16
        min = BigInt(0)
        max = BigInt(65536)
        this.setMinMaxValidators(min, max16, unsignedpattern, argumentIndex)
        break;
      case 7://OUInt 32
      case 23://Hex 32
      case 17://Enumeration 32
      case 14://Bitmask 32
        min = BigInt(0)
        max = BigInt(4294967296)
        this.setMinMaxValidators(min, max32, unsignedpattern, argumentIndex)
        break;
      case 13://Bitmask 24
        min = BigInt(0)
        this.setMinMaxValidators(min, max24, unsignedpattern, argumentIndex)
        break;
      case 8://OUInt 64
      case 24://Hex 64
        min = BigInt(0)
        max = BigInt("18446744073709551615")
        this.setMinMaxValidators(min, max, unsignedpattern, argumentIndex)
        break;
      case 10://Boolean
          argumentsControl[argumentIndex].controls.min.setValidators([Validators.min(0), Validators.max(0), Validators.required, Validators.pattern(unsignedpattern)])
          argumentsControl[argumentIndex].controls.max.setValidators([RangeValidator(min, max), Validators.required, Validators.pattern(unsignedpattern)])
          argumentsControl[argumentIndex].controls.min.updateValueAndValidity();
          argumentsControl[argumentIndex].controls.max.updateValueAndValidity();
          this.validationErrorMessage[argumentIndex] = `Min value must be 0 & Max value must be in range of 0 to 255`
      break;
      case 9://Float
          argumentsControl[argumentIndex].controls.min.setValidators([Validators.required, Validators.pattern(floatpattern)])
          argumentsControl[argumentIndex].controls.max.setValidators([Validators.required, Validators.pattern(floatpattern)])
          argumentsControl[argumentIndex].controls.min.updateValueAndValidity();
          argumentsControl[argumentIndex].controls.max.updateValueAndValidity();
          this.validationErrorMessage[argumentIndex] = `Value must be a valid decimal number`
      break;
    }
  }

  setMinMaxValidators(min: BigInt, max: BigInt, pattern:  string, argumentIndex: number) {
    const argumentsControl = this.createResponseArgumentForm.get('arguments')['controls'];
    argumentsControl[argumentIndex].controls.max.setValidators([RangeValidator(min, max), Validators.required, Validators.pattern(pattern)])
    argumentsControl[argumentIndex].controls.min.setValidators([RangeValidator(min, max), Validators.required, Validators.pattern(pattern)])
    argumentsControl[argumentIndex].controls.min.updateValueAndValidity();
    argumentsControl[argumentIndex].controls.max.updateValueAndValidity();
    this.validationErrorMessage[argumentIndex] = `Value must be in the range of ${min} to ${max}`
  }

  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formGroup = new UntypedFormGroup({
      index: new UntypedFormControl(dataItem.index, [Validators.required,
      Validators.pattern('^[0-9]{1,3}'), Validators.max(99999)]),
      value: new UntypedFormControl(dataItem.value, [Validators.required])
    });
  }
  setBitmasksValidator(bitmasksControls) {
    bitmasksControls.forEach((element) => {
      element.controls.bits.setValidators([Validators.required]);
      element.controls.bits.updateValueAndValidity();
      element.controls.data_type.setValidators([Validators.required]);
      element.controls.data_type.updateValueAndValidity();
      element.controls.value.setValidators([Validators.required]);
      element.controls.value.updateValueAndValidity();
    });
  }
  removeBitmasksValidator(bitmasksControls) {
    bitmasksControls.forEach((element) => {
      element.controls.bits.clearValidators();
      element.controls.bits.updateValueAndValidity();
      element.controls.data_type.clearValidators();
      element.controls.data_type.updateValueAndValidity();
      element.controls.value.clearValidators();
      element.controls.value.updateValueAndValidity();
    });
  }

/**
 * Set Validations for max & min values.
 *
 * Default validation rules.
 *
 * @param {number} argumentIndex
 **/
  setDefaultMaxMinValidators(argumentIndex) {
    const argumentsControl = this.createResponseArgumentForm.get('arguments')['controls'];
    const maxControl = argumentsControl[argumentIndex].controls.max;
    const minControl = argumentsControl[argumentIndex].controls.min;
    minControl.setValidators([Validators.required, Validators.pattern('^-?[0-9]*$')]);
    maxControl.setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
    maxControl.updateValueAndValidity();
    minControl.updateValueAndValidity();
  }


 /*
  * Construct the enums to show in free enum edit
  */
  constructEnumData(enumData: any) {
    let enums = [];
    let value = ""
    if (this.isBitMaskEnum === false) {
      enums = enumData.get('enums').getRawValue()
      enums.forEach((item) => {
        if (item.value && item.value.length > 0) {
          value += `${item.index}:${item.value}\n`
        }
      });
    } else {
      enums = enumData.bitmasks_enums
      enums.forEach((item) => {
        if (item.bit_value && item.bit_value.length > 0) {
          value += `${item.bit_index}:${item.bit_value}\n`
        }
      });
    }
    return value;
  }

  /*
   * Show free enum edit view and hide enum rows table
   */
  importEnums(index: number, argument: any, isBitMaskEnum: boolean) {
    this.isBitMaskEnum = isBitMaskEnum
    let argumentss = this.createResponseArgumentForm.get('arguments') as UntypedFormArray
    let min = argumentss.controls[index].get("min").getRawValue()
    let max = argumentss.controls[index].get("max").getRawValue()
    let item = this.displayEnumRows[index.toString()]
    if (item) {
        item.show = true
        item.enumdata = this.constructEnumData(argument)
        item.min = min
        item.max = max
    }
      else {
        let item: EnumEditProperties = { show: true, enumdata: this.constructEnumData(argument), min: min, max: max }
        this.displayEnumRows[index.toString()] = item
      }
  }

  /*
   * Show free bitmask enum edit view and hide enum rows table
   */
  importBitMaskEnum(index: number, argumentIndex: number, argument: any, isBitMaskEnum: boolean) {
    this.isBitMaskEnum = isBitMaskEnum
    let item = this.displayBitmaskEnumRows[argumentIndex]
    if (item && item[index]) {
      item[index].show = true
      item[index].enumdata = this.constructEnumData(argument.get('bitmasks').controls[index].value)
      item[index].bits = argument.get('bitmasks').controls[index].value.bits
    } else {
      let item: EnumEditProperties = { show: true, enumdata: this.constructEnumData(argument.get('bitmasks').controls[index].value), bits: argument.get('bitmasks').controls[index].value.bits, min: 0, max: 0 }
      this.displayBitmaskEnumRows[argumentIndex] = {}
      this.displayBitmaskEnumRows[argumentIndex][index.toString()] = item
    }
  }

  /*
   * Save free enum edit values
  */
  saveEnumData($event: EnumValues) {
    if (this.isBitMaskEnum === false) {
      let item = this.displayEnumRows[$event.index]
      if (item) {
        item.show = false
      }
      let argumentss = this.createResponseArgumentForm.get('arguments') as UntypedFormArray
      let clearall = argumentss.controls[$event.index].get("enums") as UntypedFormArray
      clearall.clear()
      $event.indexes.forEach(item => {
        clearall.push(
          this.fb.group({
            index: item,
            value: $event.values[item]
          })
        );
      })
    } else {
      let argumentss = this.createResponseArgumentForm.get('arguments') as UntypedFormArray;
      let controls = argumentss.controls[$event.argumentIndex].get("bitmasks") as UntypedFormArray;
      let clearall = controls.controls[$event.index].get("bitmasks_enums") as UntypedFormArray;
      clearall.clear()
      $event.indexes.forEach(item => {
        clearall.push(
          this.fb.group({
            bit_index: item,
            bit_value: $event.values[item]
          })
        );
      })
    }
    this.closeBitMaskEnumEdit({ "index": $event.index, "argument_index": $event.argumentIndex })
  }

  /*
    Close free enum edit
  */
  closeEnumEdit($event: number) {
    //this.displayEnumRows = true
    let item = this.displayEnumRows[$event]
    if (item) {
      item.show = false
    }
  }

  /*
    Close free bitmask enum edit
  */
  closeBitMaskEnumEdit(indexes: EnumArgument) {
    let item = this.displayBitmaskEnumRows[indexes.argument_index][indexes.index]
    if (item) {
      item.show = false
    }
  }

  /*
    Display error message
  */
  displayErrorMessage(message: string) {
    this.errorVar = message;
    //enum edit
    // if (this.displayEnumRows === false) {
    //   this.enumErrorMessage = message;
    // } else { // bitmask enum edit
    //   this.bitmaskEnumErrorMessage = message;
    // }
  }

  addToolTip(tooltip: DataType, oField: HTMLInputElement) {
    let start = oField.selectionStart
    let end = oField.selectionEnd
    let currentValue = oField.value
    let newValue = tooltip.description
    let finalValue = `${currentValue.substring(0, start)}${newValue}${currentValue.substring(end)}`
    oField.value = finalValue
    oField.focus()
    oField.selectionEnd= start + newValue.length;
    this.createResponseArgumentForm.patchValue({
      description: finalValue  })
  }
  
}

export interface EnumEditProperties {
  show: boolean,
  enumdata: string,
  min: number,
  max: number,
  bits?: string
}

export function RangeValidator(min: BigInt, max: BigInt): ValidatorFn {  
  return (control: AbstractControl): { [key: string]: any } | null =>  
    (control.value >= min && control.value <= max) ?  null : control.value
}
