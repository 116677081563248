import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonMenu, MenuTitle, MenuValue } from "src/app/models/component/component.data.model";
import { CommonFeature, CommonMenuModel } from "src/app/models/system/feature/feature.module";
import { ComponentListService } from "src/app/services/component/component-data.service";
import { SrdoDataService } from "src/app/services/component/srdo-data.service";
import { MenuService } from "src/app/services/system/menu.service";
import { faTrash, faEdit, faPlus, faFileImport, faClone, faSitemap, faLink, faUnlink, faRetweet } from '@fortawesome/free-solid-svg-icons';
import { CommonMenusFeaturesService } from "src/app/services/common-menus-features/common-menus-features.service";
import { UserConfirmActions } from "src/app/common/confirm-dailog/confirm-dailog.component";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-add-edit-common-menu",
  templateUrl: "./add-edit-common-menu.component.html",
  styleUrls: ["./add-edit-common-menu.component.scss"],
})
export class AddEditCommonMenuComponent implements AfterViewInit, OnChanges {
  @Input() selectedCommonMenu: CommonMenuModel;
  @Input() widgetList: any[];
  @Input() selectedTitle: MenuTitle; //Added this to check if it linked to any system or not
  @Input() securityList: any[];
  @Input() featureList: CommonFeature[];
  @Input() featureListForAdd: CommonFeature[];
  @Input() data: any[];
  @Input() showRootMenuOption: boolean;
  @Input() hasMenuTitles: boolean;
  @Output() showSuccessMessage = new EventEmitter<string>();
  @Output() refreshCommonMenuHierarchy = new EventEmitter<number>();
  @Output() showCopyMenuClick = new EventEmitter<boolean>();
  @Input() deployaccess: boolean;
  addOrEditCommonMenuTitle = "";
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faSitemap = faSitemap;
  faFileImport = faFileImport;
  faClone = faClone;
  faLink = faLink;
  faUnlink = faUnlink;
  faRetweet = faRetweet;
  openArrangeMenu = false
  copyMenuData: CommonMenu[] = []
  public defaultItemComp = {
    name: "Please select",
    id: undefined,
  };
  public disableAddEditMenuItems = true;
  menuValues: MenuValue[] = [
    {
      //0
      name: "Title",
      value: "",
      key: "title",
      selectable: false,
      visible: true,
      valueId: "",
      list: [],
      errors: false,
    },
    {
      //1
      name: "Security",
      value: { name: "Please select", id: undefined },
      key: "securityId",
      valueId: "id",
      selectable: true,
      visible: true,
      list: [],
      errors: false,
    },
    {
      //2
      name: "Widget",
      value: { name: "Please select", id: undefined },
      key: "widgetId",
      valueId: "id",
      selectable: true,
      visible: true,
      list: [],
      errors: false,
    },
    {
      //3
      name: "Feature Linked",
      value: { name: "Please select", id: undefined },
      key: "referencedFeatureId",
      valueId: "id",
      selectable: true,
      visible: true,
      list: [],
      errors: false,
    },
    {
      //4
      name: "Component",
      value: { name: "Please select", id: undefined },
      key: "baseComponentId",
      valueId: "id",
      visible: false,
      selectable: true,
      list: [],
      errors: false,
    },
    {
      name: "Version",
      value: { name: "Please select", id: undefined },
      key: "versionComponentId",
      valueId: "id",
      selectable: true,
      visible: false,
      list: [],
      backupList: [],
      errors: false,
    },
    {
      name: "Page No",
      value: { name: "Please select", id: undefined },
      key: "pageId",
      valueId: "id",
      selectable: true,
      visible: false,
      list: [],
      backupList: [],
      errors: false,
    },
    {
      name: "SRDO",
      value: { name: "Please select", id: undefined },
      key: "srdoId",
      selectable: true,
      valueId: "srdoId",
      visible: false,
      list: [],
      backupList: [],
      errors: false,
    },
    {
      name: "Value",
      value: "",
      key: "value",
      valueId: "id",
      selectable: false,
      visible: false,
      list: [],
      errors: false,
    },
  ];
  public commonMenuForm: FormGroup;
  public showOrHideTitle = false;
  public showOrHideActions = false;
  public showOrHideForm = false;
  public showOrHideSaveCancel = false;
  rootMenuDetails: any = {}; //Contains Component Id and Title Id as selectedCommonMenu will be undefined in this case
  showConfirmation = false;
  multilineText = `Warnings:
     \t 1. All the nested child menus will be deleted if this menu is deleted
     \t 2. All the system menu nodes having link to this menu will be delinked/updated
  `

  constructor(
    private componentService: ComponentListService,
    private srdoDataService: SrdoDataService,
    private menuService: MenuService,
    private fb: FormBuilder,
    private menufeatureService: CommonMenusFeaturesService,
    private commonService: CommonService,
    private route: Router,
    private spinner: NgxSpinnerService
  ) {}
  ngAfterViewInit(): void {}

  /**
   * Life cycle method
   */
  ngOnInit(): void {
    this.commonMenuForm = this.fb.group({
      title: ["", [Validators.required, Validators.minLength(1)]],
      securityId: [{}, [Validators.required]],
      widgetId: [{}, [Validators.required]],
      referencedFeatureId: [{}, []],
      baseComponentId: [{}, []],
      versionComponentId: [{}, []],
      pageId: [{}, []],
      srdoId: [{}, []],
      value: ["", []],
    });
  }

  /**
   * Select drop down values of the menu selected
   * Setting value from parent view - menu selected item setting
   */
  selectDropDownValues() {
    this.menuValues.forEach((menuValue, index) => {
      if (menuValue.selectable) {
        menuValue.value =
          menuValue.list.length > 0
            ? menuValue.list.find(
                (item) => item.id == this.selectedCommonMenu[menuValue.key]
              )
            : this.defaultItemComp;
      } else {
        menuValue.value = this.selectedCommonMenu[menuValue.key];
      }
    });
    this.setDropDownVsibility();
  }

  /**
   * Hide or Show component drop downs based on isDataRequested
   * It calls only from selectDropDownValues - See above method
   */
  setDropDownVsibility() {
    let componentList = this.componentService.componentData;
    this.menuValues[4].list = componentList;
    let isDataRequested = false;
    let widget = this.widgetList.find(
      (item) => item.id == this.selectedCommonMenu.widgetId
    );
    if (widget) {
      isDataRequested = widget.isDataRequested;
      if (isDataRequested) {
        if(this.menuValues[4].value == undefined) {
          this.resetMenuValuesWithComponentId()
        }
        this.updateMenuVisibleProperty(true);
      } else {
        this.updateMenuVisibleProperty(false);
      }
    }
  }

  resetMenuValuesWithComponentId() {
    this.menuValues.forEach((item, index) => {
      if (index > 4) {
        if (this.menuValues[4].value == undefined && item.selectable) {
          item.list = [];
          item.value = this.defaultItemComp;
        }
      }
    });
  }

  /**
   * isDataRequested visibility
   * @param visbility
   */
  updateMenuVisibleProperty(visbility: boolean) {
    this.menuValues.forEach((item, index) => {
      if (index > 3) {
        item.visible = visbility;
      }
    });
  }

  /**
   * This is just to show/hide extra drop downs based on user drop down selections (Widget)
   * @param widgetId
   */
  updateOnIsDataRequested(widgetId: number) {
    let isDataRequested = false;
    let widget = this.widgetList.find((item) => item.id == widgetId);
    if (widget) {
      isDataRequested = widget.isDataRequested;
      if (isDataRequested) {
        this.updateMenuVisibleProperty(true);
      } else {
        this.updateMenuVisibleProperty(false);
      }
    }
  }

  /**
   * Menu option changes - When user manually selects drop down values
   * @param value
   */
  public dropDownOptionsChanges(value: any, key: string): void {
    if (key == "widgetId") {
      this.updateOnIsDataRequested(value.id);
    } else if (key == "baseComponentId") {
      this.fetchVersions(value.id);
    } else if (key == "versionComponentId") {
      this.fetchPageNos(this.menuValues[4].value.id);
    } else if (key == "pageId") {
      this.fetchSRDOs(this.menuValues[5].value.id, value.id);
    } else {
      //this.fetchSRDOs(this.menuValues[] ,value.id)
    }
  }

  /**
   * Helper function
   * @param obj
   * @param oldKey
   * @param newKey
   */
  renameKey(obj: any, oldKey: string, newKey: string) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  /**
   * fetch component versions
   * @param componentId
   */
  fetchVersions(componentId: string) {
    this.spinner.show();
    this.componentService.getversionlistbycomponent(componentId).subscribe(
      (data) => {
        this.spinner.hide()
        // this.versionList = res
        if (data) {
          Object(data).forEach((obj) => {
            this.renameKey(obj, "versionNumber", "name");
            this.renameKey(obj, "versionNumberId", "id");
          });
          this.menuValues[5].list = data;
          this.menuValues[5].value = this.defaultItemComp;
          this.menuValues[6].list = [];
          this.menuValues[7].list = [];
          this.menuValues[6].value = this.defaultItemComp;
          this.menuValues[7].value = this.defaultItemComp;
        }
      },
      (error) => this.spinner.hide()
    );
  }

  /**
   * Fetch page nos
   * @param compversionId
   */
  fetchPageNos(compversionId: string) {
    this.spinner.show()
    this.srdoDataService
      .getPageByComponentIdVersionNumber(compversionId, "")
      .subscribe(
        (data) => {
          // this.spinner.hide()
          this.spinner.hide();
          if (data) {
            Object(data).forEach((obj) => {
              this.renameKey(obj, "pageText", "name");
              this.renameKey(obj, "pageNumber", "id");
            });
            this.menuValues[6].list = data;
            this.menuValues[6].value = this.defaultItemComp;
            this.menuValues[7].list = [];
            this.menuValues[7].value = this.defaultItemComp;
          }
        },
        (error) => this.spinner.hide()
      );
  }

  /**
   * Fetch srdos
   * @param versionId
   * @param pageNo
   */
  fetchSRDOs(versionId: string, pageNo: string) {
    this.spinner.show();;
    this.srdoDataService
      .getSrdoByComponentIdVersionNumber(versionId, "", pageNo)
      .subscribe(
        (data) => {
          this.spinner.hide();
          Object(data).forEach((obj) => {
            this.renameKey(obj, "srdoName", "name");
          });
          this.menuValues[7].list = data;
          this.menuValues[7].value = this.defaultItemComp;
        },
        (error) => this.spinner.hide()
      );
  }

  /**
   * Common menu section
   *
   * @param itemArgs
   * @returns
   */
  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === -1;
  }

  /**
   * Listen from parent actions - Root menu creation
   * We have two adds 1. From parent which is create root menu 2. Add click in Right Side view actions
   * @param actionName
   */
  public addRootMenu($event: MouseEvent) {
    this.showOrHideForm = true;
    this.showOrHideActions = false;
    this.addOrEditCommonMenuTitle = "Create Root Menu";
    // this.rootMenuDetails = rootMenuDetails
    this.showOrHideSaveCancel = this.showOrHideTitle = !this.showOrHideActions;
    this.menuValues[3].list = this.featureListForAdd;
    this.resetMenuValues();
    // }
  }

  /**
   * Intial values of component, versions, pages and srdos from parent
   * @param values
   * @param index
   * @param value
   */
  public updateFromParents(values: any[], index: number, value?: any) {
    this.menuValues[index].list = values;
    this.menuValues[index].backupList = values;
    if (value) {
      this.menuValues[index].value = value;
    }
  }

  /**
   * Submit to server - Common menu add (Root and child)
   */
  postAddCommonMenuData() {
    this.spinner.show();
    let isRooTmenu = this.addOrEditCommonMenuTitle.includes("Root");
    let editAction = this.addOrEditCommonMenuTitle.includes("Edit");
    let data = this.commonMenuForm.value;
    const menuData = {
      id: isRooTmenu ? 0 : this.selectedCommonMenu.id,
      name: data.title,
      parentId: isRooTmenu ? 0 : this.selectedCommonMenu.menuId,
      securityId: data.securityId?.id,
      menuWidgetId: data.widgetId?.id,
      featureId: data.referencedFeatureId?.dbid
        ? data.referencedFeatureId.dbid
        : 0,
      createdBy: "user@example.com",
      modifiedBy: "user@example.com",
      menuHeirarchy: 0,
      baseComponentId: data.baseComponentId?.id,
      versionComponentId: data.versionComponentId?.id,
      pageId: data.pageId?.id,
      srdoId: data.srdoId?.srdoId,
      value: data?.value,
    };
    let componentId = 0;
    let titleId = 0;
    if (isRooTmenu) {
      componentId = +this.menufeatureService.componentId;
      titleId = this.menufeatureService.commonTitle.id;
    } else {
      componentId = this.selectedCommonMenu.componentId;
      titleId = this.selectedCommonMenu.titleId;
    }
    if (editAction) {
      //Edit
      this.menuService
        .editCommonMenuData(menuData, componentId, titleId)
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res !== null) {
              this.addOrEditCommonMenuTitle = "";
              this.showSuccessMessage.emit("Common menu updated successfully");
              this.resetFormActionsAndTitle();
              this.fetchCommonFeatures(res);
            }
          },
          (error) => this.spinner.hide()
        );
    } else {
      //Add
      this.menuService
        .addCommonMenuData(menuData, componentId, titleId)
        .subscribe(
          (res) => {
            this.spinner.hide()
            if (res !== null) {
              this.showSuccessMessage.emit("Common menu created successfully");
              this.resetFormActionsAndTitle();
              this.fetchCommonFeatures();
            }
          },
          (error) => this.spinner.hide()
        );
    }
  }

  /**
   * Reset button actions and title
   */
  resetFormActionsAndTitle() {
    this.rootMenuDetails = {};
    this.showOrHideActions = false;
    this.addOrEditCommonMenuTitle = "";
    this.showOrHideSaveCancel = this.showOrHideTitle = !this.showOrHideActions;
  }

  /**
   * Reset errors
   */
  resetFormErrors() {
    this.menuValues.map((item) => (item.errors = false));
  }

  /**
   * Reset menu values during ADD
   * We have two adds 1. From parent which is create root menu 2. Add click in Right Side view actions
   * It will get called in above two cases
   */
  resetMenuValues() {
    this.disableAddEditMenuItems = false;
    this.menuValues.forEach((menuItem, index) => {
      if (menuItem.selectable) {
        //Values
        if (index > 4) {
          menuItem.list = [];
        }
        menuItem.value = this.defaultItemComp;
      } else {
        menuItem.value = "";
      }
      //Visibility
      if (index > 3) {
        menuItem.visible = false;
      }
    });
  }

  /**
   * Form Submit
   */
  onFormSubmit() {
    //Form validations
    let value = this.commonMenuForm.value;
    this.commonMenuForm.markAllAsTouched();
    this.commonMenuForm.get("title").value.length === 0
      ? (this.menuValues[0].errors = true)
      : (this.menuValues[0].errors = false);
    this.commonMenuForm.get("securityId").value.id == undefined
      ? (this.menuValues[1].errors = true)
      : (this.menuValues[1].errors = false);
    this.commonMenuForm.get("widgetId").value.id == undefined
      ? (this.menuValues[2].errors = true)
      : (this.menuValues[2].errors = false);
    let widget = value.widgetId;
    if (widget) {
      let isDataRequested = widget.isDataRequested;
      if (
        isDataRequested &&
        this.menuValues[4].value &&
        this.menuValues[4].value.id != undefined
      ) {
        this.commonMenuForm.get("versionComponentId").value.id == undefined
          ? (this.menuValues[5].errors = true)
          : (this.menuValues[5].errors = false);
        this.commonMenuForm.get("pageId").value.id == undefined
          ? (this.menuValues[6].errors = true)
          : (this.menuValues[6].errors = false);
        this.commonMenuForm.get("srdoId").value.id == undefined
          ? (this.menuValues[7].errors = true)
          : (this.menuValues[7].errors = false);
      }
    }
    let haveerrors = this.menuValues.find((item) => item.errors == true);
    if (haveerrors) {
      return;
    }
    this.postAddCommonMenuData();
  }

  /**
   * Life cycle method - observing state change
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    const {
      widgetList,
      securityList,
      featureList,
      selectedCommonMenu,
      data,
      ...otherChanges
    } = changes;

    if (widgetList && widgetList.currentValue) {
      // Handle change in property1
      this.menuValues[2].list = widgetList.currentValue;
    }
    if (securityList && securityList.currentValue) {
      // Handle change in property2
      this.menuValues[1].list = securityList.currentValue;
    }
    if (featureList && featureList.currentValue) {
      this.menuValues[3].list = featureList.currentValue;
    }
    if (selectedCommonMenu && selectedCommonMenu.currentValue) {
      this.selectedCommonMenu = selectedCommonMenu.currentValue;
      this.showOrHideForm = this.showOrHideActions = true;
      this.showOrHideSaveCancel = this.showOrHideTitle =
        !this.showOrHideActions;
      this.disableAddEditMenuItems = true;
      this.selectDropDownValues();
    }

    if(data && data.currentValue) {
      if(this.openArrangeMenu) {
        this.copyMenuData = structuredClone(data.currentValue)
      }
    }

    if (selectedCommonMenu && selectedCommonMenu.currentValue === undefined) {
      this.selectedCommonMenu = selectedCommonMenu.currentValue;
      this.addOrEditCommonMenuTitle = "";
      this.showOrHideForm =
        this.showOrHideTitle =
        this.showOrHideSaveCancel =
        this.showOrHideActions =
          false;
    } else if (selectedCommonMenu === undefined) {
      this.addOrEditCommonMenuTitle = "";
      this.showOrHideForm =
        this.showOrHideTitle =
        this.showOrHideSaveCancel =
        this.showOrHideActions =
          false;
    }
  }

  /**
   * Edit common menu
   * @param $event
   */
  editMenu($event) {
    this.disableAddEditMenuItems = false;
    this.addOrEditCommonMenuTitle = "Edit menu";
    this.showOrHideForm = true;
    this.showOrHideActions = false;
    this.showOrHideSaveCancel = this.showOrHideTitle = !this.showOrHideActions;
  }

  /**
   * Add menu as a child
   * @param $event
   */
  addChildMenu($event: MouseEvent) {
    this.addOrEditCommonMenuTitle = "Add as a child";
    this.showOrHideForm = true;
    this.showOrHideActions = false;
    this.showOrHideSaveCancel = this.showOrHideTitle = !this.showOrHideActions;
    this.resetMenuValues();
    this.menuValues[3].list = this.featureListForAdd;
  }

  /**
   * Delete common menu event
   * @param $event
   */
  deleteMenu($event) {
    this.showConfirmation = true;
  }

  /**
   * Delete common menu
   */
  deletCommonMenu(deleteLinkedFeatures: boolean) {
    this.spinner.show()
    let componentId = this.selectedCommonMenu.componentId;
    let titleId = this.selectedCommonMenu.titleId;
    let menuId = this.selectedCommonMenu.menuId;
    this.menuService.deleteCommonMenu(componentId, titleId, menuId, deleteLinkedFeatures).subscribe(
      (res) => {
        if (res) {
          this.showSuccessMessage.emit("Common menu deleted successfully");
          this.fetchCommonFeatures(0);
        }
      },
      (error) => this.spinner.hide()

    );
  }

  confirmationEvent(event: UserConfirmActions) {
    this.showConfirmation = false;
    if (event.confirmAction) {
      this.deletCommonMenu(event.userConfirmAction,);
    }
  }

  /**
   * Set V - Versions, P - Page Nos, S - SRDOs list to list
   */
  updateVPSValues() {
    this.menuValues[5].list = this.menuValues[5].backupList;
    this.menuValues[6].list = this.menuValues[6].backupList;
    this.menuValues[7].list = this.menuValues[7].backupList;
  }

  /**
   * Add as a child - Cancel
   * @param $event
   */
  cancelChildMenu($event: MouseEvent) {
    this.disableAddEditMenuItems = true; //Disable once cancelled
    this.resetFormErrors();
    if (this.selectedCommonMenu) {
      this.showOrHideForm = true;
      this.showOrHideActions = true;
      this.showOrHideSaveCancel = this.showOrHideTitle =
        !this.showOrHideActions;
      if (this.selectedCommonMenu.baseComponentId > 0) {
        this.updateVPSValues();
      }
      this.menuValues[3].list = this.featureList;
      this.selectDropDownValues();
    } else {
      this.showOrHideForm = false;
      this.showOrHideActions = false;
      this.showOrHideSaveCancel = false;
      this.addOrEditCommonMenuTitle = "";
    }
  }

  copySystemMenu($event) {
    this.showCopyMenuClick.emit(true);
  }

  navigateToFeature() {
    this.menufeatureService.featureIdSelected = this.selectedCommonMenu.referencedFeatureId;
    this.menufeatureService.menufeatureSubject.next("Feature");
  }

  navigateToSRDO() {
    let componentName = this.commonMenuForm.value['baseComponentId'].name
    let srdo =  this.commonMenuForm.value['srdoId'].name 
    let data = {
      controlType: "SRDO",
      componentName: componentName,
      versionId: this.selectedCommonMenu.versionComponentId,
      srdoId: this.selectedCommonMenu.srdoId,
      commandResponseId: 0,
      linkName: srdo,
    };
    this.commonService.selectedSRDOCommand = data;
    this.route.navigateByUrl("/component-data");
  }

  unlinckCommonFeature() {
    this.spinner.show()
    this.menuService.unlinkCommonFeature(this.selectedCommonMenu.id).subscribe(
      (status) => {
        this.spinner.hide()
        if (status) {
          this.refreshCommonMenuHierarchy.emit(this.selectedCommonMenu.menuId);
          this.menufeatureService.ToastMessage(
            "Feature unlinked successfully",
            "bg-success"
          );
        } else {
          this.menufeatureService.ToastMessage(
            "Feature unlink failed",
            "bg-danger"
          );
        }
        this.fetchCommonFeatures(this.selectedCommonMenu.menuId);
      },
      (error) => this.spinner.hide()

    );
  }

  /**
   * Fetch common features
   * @param menuId 
   */
  fetchCommonFeatures(menuId = 0) {
    this.spinner.show()
    let componentId = this.menufeatureService.componentId;
    let titleId = this.menufeatureService.commonTitle.id;
    this.menufeatureService
      .fetchCommonFeatures(componentId, titleId.toString())
      .subscribe((features) => {
        this.menufeatureService.commonFeatures = features;
        this.spinner.hide()
        this.refreshCommonMenuHierarchy.emit(menuId);
      });
  }

  /**
   * Open reorder common menu
   */
  reOrderCommonMenu() {
    //https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
    this.copyMenuData = structuredClone(this.data)
    this.openArrangeMenu = true
  }

  /**
   * Close Menu arrange model
   * @param $event 
   */
  closeArrangeMenuModal($event) {
    this.openArrangeMenu = false
  }

  refreshHierarchy() {
    this.refreshCommonMenuHierarchy.emit(0)
  }
}
