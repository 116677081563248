import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComponentListService } from 'src/app/services/component/component-data.service';
import { ComponentInfo } from 'src/app/models/component/component.data.model';
import { SystemListService } from 'src/app/services/system/system-list.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { User } from 'src/app/models/user/user.model';
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent extends DialogContentBase {
  public compOwnervalue: Multi[];
  public compUservalue: Multi[];
  public compReadOnlyValue: Multi[];
  public sysOwnervalue: Multi[];
  public sysUservalue: Multi[];
  public sysReadOnlyValue: Multi[];
  public adminvalue: boolean;
  public phoneadminvalue: boolean;
  public globalreadvalue: boolean;

  public components: ComponentInfo[] = [];
  public systemsdata: any = {};
  public systems: any = [];
  addUserForm: UntypedFormGroup;
  public editItem: User = null;
  public headertitle = "Add User";
  public buttontitle = "Assign";
  isAdmin = false;

  ngOnInit() {
    // https://blog.angular-university.io/angular-debugging/
    // In order to defer the code inside ngAfterViewInit to another Javascript turn
    setTimeout(() => {
      this.getComponentList();
      this.getSystemsList();
    });

    if (this.editItem != null) {
      this.headertitle = "Edit User";
      this.buttontitle = "Update";
      if (this.editItem.roles != null) {
        for (let i = 0; i < this.editItem.roles.length; i++) {
          let roleid = this.editItem.roles[i].roleId;
          let entities = this.editItem.roles[i].mappedEntities;
          let final: Multi[] = [];
          entities.forEach(function (entity) {
            final.push({ "id": Number(entity.entityId), "name": entity.entityName });
          })
          switch (roleid) {
            case 1://Adminstartor
              this.adminvalue = true;
              break;
            case 2://System Owner
              this.sysOwnervalue = final;
              break;
            case 3://System User
              this.sysUservalue = final;
              break;
            case 4://Component Owner
              this.compOwnervalue = final;
              break;
            case 5: //Component User
              this.compUservalue = final;
              break;
            case 6: //Phone Adminstrator
              this.phoneadminvalue = true;
              break;
            case 7: //Component Read Only
              this.compReadOnlyValue = final;
              break;
            case 8: //System Read Only
              this.sysReadOnlyValue = final;
              break;
            case 9: //Global Read Only
              this.globalreadvalue = true;
              break;
              
              
            default:
              break;
          }
        }
      }
    }


    this.addUserForm = this.formBuilder.group({
      username: [{ value: this.editItem !== null ? this.editItem.userName : '', disabled: this.editItem !== null ? true : false }, Validators.required],
      email: [{ value: this.editItem !== null ? this.editItem.emailId : '', disabled: this.editItem !== null ? true : false }, [Validators.required, Validators.email]],
      componentowners: [[]],
      componentusers: [[]],
      componentreadonly: [[]],
      systemowners: [[]],
      systemusers: [[]],
      systemreadonly: [[]],
      admin: [false],
      phoneadmin: [false],
      globalread: [false],
    });
  }

  getComponentList() {
    this.spinner.show();
    this.componentService.getComponentList(false).subscribe((data) => {
      this.spinner.hide();
      this.components = data;
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  getSystemsList() {
    this.spinner.show();
    this.systemService.getAllSystemList(false).subscribe((data) => {
      this.spinner.hide();
      this.systemsdata = data;
      this.systems = this.systemsdata;
    },
      ((error) => {
        this.spinner.hide();
      }));
  }

  constructor(public dialog: DialogRef, private componentService: ComponentListService,
    private systemService: SystemListService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder, 
    public toastService: ToastService,
    private authService: MsalService) {
    super(dialog);
  }

  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: 'Success'
    });
  }

  public onCancelAction(): void {
    this.dialog.close({ text: 'Cancel' });
  }

  public onConfirmAction(): void {
    let compowners: [] = this.addUserForm.get('componentowners').value;
    let systemowners: [] = this.addUserForm.get('systemowners').value;
    let systemusers: [] = this.addUserForm.get('systemusers').value;
    let compusers: [] = this.addUserForm.get('componentusers').value;
    let compreadonly: [] = this.addUserForm.get('componentreadonly').value;
    let systemreadonly: [] = this.addUserForm.get('systemreadonly').value;
    let admin: boolean = this.addUserForm.get('admin').value;
    let enteredemail: string = this.addUserForm.get('email').value;
    let enteredusername: string = this.addUserForm.get('username').value;
    let phoneadmin: boolean = this.addUserForm.get('phoneadmin').value;
    let globalread: boolean = this.addUserForm.get('globalread').value;
    let roles: Role[] = [];

    //Component Owners
    if (compowners != null) {
      if (compowners.length > 0) {
        let entities = [] as Array<Entity>
        for (let i = 0; i < compowners.length; i++) {
          let entity: Entity = {
            entityId: compowners[i]['id'],
            entityName: compowners[i]['name']
          }
          entities.push(entity)
        }
        let owner: Role = {
          roleId: 4,
          roleName: 'ComponentOwner',
          mappedEntities: entities
        }
        roles.push(owner);
      }
    }

    //Component Users
    if (compusers != null) {
      if (compusers.length > 0) {
        let entities = [] as Array<Entity>
        for (let i = 0; i < compusers.length; i++) {
          let entity: Entity = {
            entityId: compusers[i]['id'],
            entityName: compusers[i]['name']
          }
          entities.push(entity)
        }
        let user: Role = {
          roleId: 5,
          roleName: 'ComponentUser',
          mappedEntities: entities
        }
        roles.push(user);
      }
    }
    //Component Read Only
    if (compreadonly != null) {
      if (compreadonly.length > 0) {
        let entities = [] as Array<Entity>
        for (let i = 0; i < compreadonly.length; i++) {
          let entity: Entity = {
            entityId: compreadonly[i]['id'],
            entityName: compreadonly[i]['name']
          }
          entities.push(entity)
        }
        let user: Role = {
          roleId: 7,
          roleName: 'ComponentReadOnly',
          mappedEntities: entities
        }
        roles.push(user);
      }
    }


    //System Users
    if (systemusers != null) {
      if (systemusers.length > 0) {
        let entities = [] as Array<Entity>
        for (let i = 0; i < systemusers.length; i++) {
          let entity: Entity = {
            entityId: systemusers[i]['id'],
            entityName: systemusers[i]['name']
          }
          entities.push(entity)
        }
        let user: Role = {
          roleId: 3,
          roleName: 'SystemUser',
          mappedEntities: entities
        }
        roles.push(user);
      }
    }

    //System Owners
    if (systemowners != null) {
      if (systemowners.length > 0) {
        let entities = [] as Array<Entity>
        for (let i = 0; i < systemowners.length; i++) {
          let entity: Entity = {
            entityId: systemowners[i]['id'],
            entityName: systemowners[i]['name']
          }
          entities.push(entity)
        }
        let owner: Role = {
          roleId: 2,
          roleName: 'SystemOwner',
          mappedEntities: entities
        }
        roles.push(owner);
      }
    }

    //System Read Only
    if (systemreadonly != null) {
      if (systemreadonly.length > 0) {
        let entities = [] as Array<Entity>
        for (let i = 0; i < systemreadonly.length; i++) {
          let entity: Entity = {
            entityId: systemreadonly[i]['id'],
            entityName: systemreadonly[i]['name']
          }
          entities.push(entity)
        }
        let user: Role = {
          roleId: 8,
          roleName: 'SystemReadOnly',
          mappedEntities: entities
        }
        roles.push(user);
      }
    }

    //Adminstrator
    if (admin == true) {
      let admin: Role = {
        roleId: 1,
        roleName: 'Administrator',
        mappedEntities: null
      }
      roles.push(admin);
    }

    //Adminstrator
    if (phoneadmin == true) {
      let admin: Role = {
        roleId: 6,
        roleName: 'PhoneAdministrator',
        mappedEntities: null
      }
      roles.push(admin);
    }

    //Global Read Only
    if (globalread == true) {
      let admin: Role = {
        roleId: 9,
        roleName: 'GlobalReadOnly',
        mappedEntities: null
      }
      roles.push(admin);
    }
    

    this.spinner.show();
    if (this.editItem != null) {

      let edituser: EditUser = {
        userName: this.editItem.userName,
        emailId: this.editItem.emailId,
        id: this.editItem.userId,
        modifiedBy: this.authService.instance.getActiveAccount().username,
        modifiedOn: new Date(),
        roles: roles
      }
      this.userService.updateUser(edituser).subscribe((res) => {
        this.spinner.hide();
        if (res === true) {
          this.ToastMessage('User Updated Successfully.', 'bg-success');
        } else {
          const response = JSON.stringify(res);
          const resData = JSON.parse(response);
          this.ToastMessage(res, 'bg-danger');
        }
        this.dialog.close({ text: 'Submit', primary: true });
      }, () => {
        this.spinner.hide();
        this.dialog.close({ text: 'Submit', primary: true });
      });
    } else {
      let adduser: AddUser = {
        userName: enteredusername,
        emailid: enteredemail,
        createdBy: this.authService.instance.getActiveAccount().username,
        createdOn: new Date(),
        roles: roles
      }
      this.userService.addUser(adduser).subscribe((res) => {
        this.spinner.hide();
        if (res === true) {
          this.ToastMessage('User Created Successfully.', 'bg-success');
        } else {
          const response = JSON.stringify(res);
          const resData = JSON.parse(response);
          this.ToastMessage(res, 'bg-danger');
        }
        this.dialog.close({ text: 'Submit', primary: true });
      }, () => {
        this.spinner.hide();
        this.dialog.close({ text: 'Submit', primary: true });
      });
    }
  }
}

export interface Role {
  roleId: number;
  roleName: string;
  mappedEntities: Entity[]
}

export interface Entity {
  entityId: string;
  entityName: string;
}

export interface AddUser {
  userName: string;
  emailid: string;
  createdBy: string;
  createdOn: Date;
  roles: Role[];
}

export interface EditUser {
  id: number;
  userName: string;
  emailId: string;
  modifiedBy: string;
  modifiedOn: Date;
  roles: Role[];
}

export interface Multi {
  id: number;
  name: string;
}
