import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import { ComponentListService } from 'src/app/services/component/component-data.service';
import { DialogboxService } from 'src/app/services/confirmation-dialog/dialogbox.service';
import { ToastService } from 'src/app/services/toast/toast.service';
@Component({
  selector: 'app-component-version',
  templateUrl: './component-version.component.html',
  styleUrls: ['./component-version.component.scss']
})
export class ComponentVersionComponent implements OnInit {

  @Input() title;
  @Input() baselineComponentId;
  @Input() isDeployedFlag;
  @Input() isStaged: boolean;
  @Input() isBaseline;
  @Input() selectedBaselineComponentName;
  @Input() selectedComponentName;
  @Input() selectedComponentId;
  ComponentVerForm: UntypedFormGroup;
  submitted = false;
  faTimes = faTimes;
  @Output() submitComponentVersion = new EventEmitter();
  @Output() getversionlistofcomponent = new EventEmitter();
  constructor(private formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal,private confirmationDialogService: DialogboxService, 
              private componentService: ComponentListService, public toastService: ToastService,  private spinner: NgxSpinnerService,
              private commonService: CommonService) { }

  ngOnInit() {
    this.ComponentVerForm = this.formBuilder.group({
      new_version_name: [null, [Validators.required]],
      action_type: [null, [Validators.required]]
    });
    this.ComponentVerForm.patchValue({
      action_type: ''
    });
  }

  public submitForm() {
    this.submitted = true;
    if (this.ComponentVerForm.invalid) {
      return;
    }
    const compVerFormValue = this.ComponentVerForm.value;
    this.submitComponentVersion.emit(compVerFormValue);
    if(this.ComponentVerForm.get('action_type').value === 'reopen') 
    {
      this.spinner.show()
      let data = {"componentId": this.baselineComponentId}
      this.componentService.reOpenComponentVersion(data).subscribe((status) => {
        this.spinner.hide()
          if(status) {
            this.ToastMessage('Component reopened successfully.', 'bg-success');
            this.commonService.isReopened = true;
            this.commonService.reopenedVersionAction()
          }
      }, (error) => {
        this.spinner.hide()
      });
    }
  }
  public deleteComponentVersion($event: any) {
    const obj = {
      action_type: this.ComponentVerForm.get('action_type').value
    };
    if (this.ComponentVerForm.get('action_type').value === 'delete') {
      this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
      .then((confirmed) => {
        if (confirmed === true) {
          this.spinner.show();
          this.submitComponentVersion.emit(obj); 
          this.componentService.DeleteVersion(this.baselineComponentId).subscribe((res) => {
            this.getversionlistofcomponent.emit(this.selectedComponentId);
            this.spinner.hide();
            this.ToastMessage('Component Version Deleted Successfully.', 'bg-success');
          }, (error: any) => {
            this.spinner.hide(); //Bug 732826 fix
          });
        } else {
          this.spinner.hide();
        }
      });
    } else if(this.ComponentVerForm.get('action_type').value === 'reopen') {
      this.ComponentVerForm.get('new_version_name').clearValidators()
      this.ComponentVerForm.get('action_type').setValidators([Validators.required])
      this.ComponentVerForm.get('new_version_name').updateValueAndValidity();
      this.ComponentVerForm.get('action_type').updateValueAndValidity();
    }
  }
  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000 ,
      autohide: true,
      headertext: type === 'bg-success' ? 'Success' : 'Error'
    });
  }
  getDelete(){
    if (this.isBaseline) {
      return false;
    } else if (this.isDeployedFlag || this.isStaged) {
      return false;
    } else {
      return true;
    }
  }
}
