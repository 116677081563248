import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonMenu } from 'src/app/models/component/component.data.model';
import { SystemModel } from 'src/app/models/system/system.data.model';

@Component({
  selector: 'app-copy-feature-modal',
  templateUrl: './copy-feature-modal.component.html',
  styleUrls: ['./copy-feature-modal.component.scss']
})
export class CopyFeatureModalComponent implements OnInit {
  @Input() opened: boolean = false;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() destinationList: Array<SystemModel | CommonMenu> = [];
  @Input() selectedDestId: number;
  @Output() selectionChangeEvent: EventEmitter<DropDownEvent> = new EventEmitter();
  @Input() sourceList: Array<SystemModel | CommonMenu> = [];
  @Input() titles: String[] = []
  selectedSourceSystem : any;
  selectedSourceFeature: any;
  @Input() featuresList : []
  @Input() errorMessage = '';
  @Input() copybtnState: boolean = true;
  @Output() copyFeatureSystem: EventEmitter<boolean> = new EventEmitter();
  placeHolder = {
    name: "Please select", 
    id: null
  };
  constructor() { }

  ngOnInit(): void {
  }

  /*
   * Close button action 
   * status - false -> Hides the modal
  */
  public close(status: boolean) {
    this.opened = false;
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

   /*
   * System list drop down change event
   * value - System Object
  */
   public sourceSystemValueChanged(value: any): void {
    let data: DropDownEvent = {type: 'system', id: value}
    this.selectionChangeEvent.emit(data);
    this.selectedSourceFeature = null
  }

  /*
   * System list drop down change event
   * value - System Object
  */
  public sourceFeatureValueChanged(value: any): void {
    let data: DropDownEvent = {type: 'feature', id: value.featureId ? value.featureId : value.id}
    this.selectedSourceFeature = value
    this.selectionChangeEvent.emit(data);
  }

  /*
  * Copy button action
  */
  copybtnClicked(event: boolean) {
    this.copyFeatureSystem.emit(true)
  }

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return itemArgs.dataItem.id === null;
  }

}

export interface DropDownEvent {
  type: string,
  id: number
}
