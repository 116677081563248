import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, toODataString } from '@progress/kendo-data-query';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/Operators';
import { AppService, getServiceUrl } from 'src/app/global/global';

export abstract class AuditService extends BehaviorSubject<GridDataResult> {
    public loading: boolean;

    constructor(private http: HttpClient) {
        super(null);
    }

    public query(state: any, sort: SortDescriptor): void {
        this.fetch(state, sort).subscribe((x) => super.next(x));
    }

    protected fetch(state: any, sort: SortDescriptor): Observable<GridDataResult> {
        // const queryStr = `columnName=${sort.field}&sort=${sort.dir}&pageNo=${state.skip/state.take + 1}&recordsCount=${state.take}`;
        let sortDirection = '';
        if(sort.dir) {
            sortDirection = sort.dir === 'asc' ? 'Ascending' : 'Descending'
        }
        this.loading = true;
        let headerData = { "pageSize" : `${state.take}`, "pageIndex" : `${state.skip/state.take + 1}`};
        if(sortDirection.length > 0) {
            headerData['sortingOrder'] =  sortDirection
            if(sort.field.length > 0) {
                headerData['sortingColumn'] =  sort.field
            } 
        } 
       
        return this.http.get(getServiceUrl(AppService.GetAuditLogData), {headers: headerData}).pipe(
            map(
                (response) =>
                    <GridDataResult>{
                        data: response['auditLogs'],
                        total: response['recordCount']
                    }
            ),
            delay(2000),
            tap(() => (this.loading = false)),
            catchError(() => {
                this.loading = false
                return throwError(() => new Error('oops sommething happend'));
              })
           
        );
    }
}

@Injectable()
export class AuditDataService extends AuditService {
    constructor(http: HttpClient) {
        super(http);
    }

    queryAll(st?: any, srt?: any): Observable<GridDataResult> {
        const state = Object.assign({}, st);
        const sort = Object.assign({}, srt);
        delete state.skip;
        delete state.take;

        return this.fetch(state, sort);
    }
}