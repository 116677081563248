import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-display-list-modal',
  templateUrl: './display-list-modal.component.html',
  styleUrls: ['./display-list-modal.component.scss']
})
export class DisplayListModalComponent implements OnInit {

  @Input() title = ''
  @Input() data: LinkedFeatureSystem[] = []
  @Input() opened: boolean = false;
  @Input() menuEnabled: boolean = false;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  public gridView: LinkedFeatureSystem[]; 
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.gridView = this.data
  }

  public close(status: boolean) {
    this.opened = false;
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

  navigateToSystemData(dataItem: LinkedFeatureSystem, isMenuClicked: boolean) {
    dataItem.isMenuClicked = isMenuClicked
    this.commonService.selectedFeature = dataItem
  }
}

export interface LinkedFeatureSystem {
  id: string,
  systemId: number,
  systemName: string,
  featureId: number,
  featureName: string,
  menuId:number,
  menuName:string,
  isNavigable: boolean,
  isMenuClicked: boolean
}