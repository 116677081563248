import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../shared/shared.module';
import { CommandArgumentComponent } from './command-argument/command-argument.component';
import { CommandResponseDataComponent } from './command-response-data/command-response-data.component';
import { ComponentDataRoutingModule } from './component-data-routing.module';
import { ComponentDataTextFileComponent } from './component-data-text-file/component-data-text-file.component';
import { ComponentDataComponent } from './component-data/component-data.component';
import { ComponentListComponent } from './component-list/component-list.component';
import { ComponentVersionComponent } from './component-version/component-version.component';
import { CreateResponseArgumentComponent } from './create-response-argument/create-response-argument.component';
import { PagedetailsComponent } from './pagedetails/pagedetails.component';
import { SrdoDataComponent } from './srdo-data/srdo-data.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UploadsModule } from "@progress/kendo-angular-upload";
import { ListViewModule } from "@progress/kendo-angular-listview";
import { DefaultFileImportComponent } from '../common/default-file-import/default-file-import.component';
import { DisplayListModalComponent } from '../common/display-list-modal/display-list-modal.component';
import { EnumEditComponent } from '../common/enum-edit/enum-edit.component';
import { RearrangeModalComponent } from '../common/rearrange-modal/rearrange-modal.component';
import { ResourcesComponent } from './resources/resources.component';
import { CustomIconComponent } from '../common/custom-icon/custom-icon.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { CommonMenuComponent } from './common-menu-features/common-menu/common-menu.component';
import { AddEditCommonMenuComponent } from './common-menu-features/add-edit-common-menu/add-edit-common-menu.component';
import { CommonFeaturesComponent } from './common-features/common-features.component';
import { FeatureCardComponent } from './common-features/feature-card/feature-card.component';
import { FeatureDetailsComponent } from './common-features/feature-details/feature-details.component';
import { CommonMenuFeaturesComponent } from './common-menu-features/common-menu-features.component';
import { CommonMenusFeaturesService } from '../services/common-menus-features/common-menus-features.service';
import { RearrangeComponent } from './common-features/rearrange/rearrange.component';
import { RearrangeCommonMenuComponent } from './common-menu-features/rearrange-common-menu/rearrange-common-menu.component';
import { DragAndDropModule } from '@progress/kendo-angular-utils';
import {  IconsModule } from '@progress/kendo-angular-icons';
@NgModule({
  declarations: [
    ComponentDataComponent,
    ComponentListComponent,
    SrdoDataComponent,
    CommandResponseDataComponent,
    ComponentDataTextFileComponent,
    CreateResponseArgumentComponent,
    CommandArgumentComponent,
    ComponentVersionComponent,
    PagedetailsComponent,
    EnumEditComponent,
    ResourcesComponent,
    RearrangeModalComponent,
    DefaultFileImportComponent,
    DisplayListModalComponent,
    CustomIconComponent,
    CommonMenuComponent,
    AddEditCommonMenuComponent,
    CommonFeaturesComponent,
    FeatureCardComponent,
    FeatureDetailsComponent,
    CommonMenuFeaturesComponent,
    RearrangeComponent,
    RearrangeCommonMenuComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgbModule,
    ComponentDataRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSmartModalModule.forRoot(),
    SharedModule,
    GridModule,
    BrowserModule,
    BrowserAnimationsModule,
    DropDownsModule,
    PopupModule,
    InputsModule,
    DialogModule,
    ButtonsModule,
    UploadsModule,
    TreeViewModule,
    ListViewModule,
    DragAndDropModule,
    IconsModule
    ],
    providers: [CommonMenusFeaturesService]
})
export class ComponentDataModule { }
