import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { SystemModel } from 'src/app/models/system/system.data.model';

@Component({
  selector: "app-copy-menu-modal",
  templateUrl: "./copy-menu-modal.component.html",
  styleUrls: ["./copy-menu-modal.component.scss"],
})
export class CopyMenuModalComponent  {
  @Input() errorMessage = "";
  @Input() copybtnState: boolean = true;
  @Input() opened: boolean = false;
  @Input() destinationData = [];
  @Input() sourceData = [];
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() sourceMenuSelectionEvent: EventEmitter<any> = new EventEmitter();
  @Output() destMenuSelectionEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectionChangeEvent: EventEmitter<number> = new EventEmitter();
  @Output() heirarchyChangeEvent: EventEmitter<number> = new EventEmitter();
  @Output() copyMenuSystem: EventEmitter<boolean> = new EventEmitter();
  @Input() sourceList: Array<SystemModel> = [];
  @Input() destinationList: Array<SystemModel> = [];
  @Input() selectedDestId: number;
  @Input() selectedSourceId: number;
  @Input() parentRelation = [];
  @Input() destExpandedKeys = [];
  @Input() destSelectedKeys = [];
  @Input() srcSelectedKeys = [];
  @Input() hirarchySelectionValue = 1;
  confirmationValue = false;
  @Input() confimationMessage = "";
  @Input() showConfirmation = false;
  @Input() destinationTitle = "Destination System*";
  defaultItem: { name: string; id: number } = {
    name: "Please Select Source System",
    id: null,
  };

  /*
   * Close button action
   * status - false -> Hides the modal
   */
  public close(status) {
    this.opened = false;
    this.confirmationValue = false;
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

  /*
   * Tree View Menu Selection
   * event - TreeItem -> Selected item
   */
  public onSourceMenuItemSelected(event: TreeItem): void {
    this.sourceMenuSelectionEvent.emit(event);
  }

  /*
   * Tree View Menu Selection
   * event - TreeItem -> Selected item
   */
  public onDestMenuItemSelected(event: TreeItem): void {
    this.destMenuSelectionEvent.emit(event);
  }

  /*
   * System list drop down change event
   * value - System Object
   */
  public sourceSystemValueChanged(value: any): void {
    this.srcSelectedKeys = []
    this.selectionChangeEvent.emit(value);
  }

  /*
   * Heirarchy list drop down change event
   * value - Heirarchy Object
   */
  public heirarchyValueChanged(value: any): void {
    this.heirarchyChangeEvent.emit(value);
  }

  /*
   * Copy button action
   */
  copybtnClicked(event) {
    this.copyMenuSystem.emit(this.confirmationValue);
  }

  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === -1;
  }

}
