import { Component, OnInit, AfterViewInit, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast/toast.service';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { SideNavMenuItems } from 'src/app/global/app-enums.enum';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {
  faTrash = faTrash;
  faEdit = faEdit;
  subscription: Subscription;
  
  constructor(public router: Router,
    private spinner: NgxSpinnerService,
    public toastService: ToastService,
    private commonService: CommonService) {
    this.subscription = this.commonService.onMessage().subscribe((message) => {
      if (message.text === SideNavMenuItems.PhoneAdmin) {
        this.router.navigate(['widget/data-widget']);
      }
    });
  }

  ngOnInit() {
    if (this.router.url === '/widget') {
      this.router.navigate(['widget/data-widget']);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  
  public tabClick(event) {
    switch (event.nextId) {
      case 0:
        this.router.navigate(['/widget', 'data-widget']);
        break;
      case 1:
        this.router.navigate(['/widget', 'menu-widget']);
        break;
    }
  }
}


