import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HelpComponent } from './help/help.component';
import { SharedRoutingModule } from './shared-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from './filter.pipe';
import { GridModule } from '@progress/kendo-angular-grid';
import { SortPipe } from './sort.pipe';
import { ConfirmDailogComponent } from '../common/confirm-dailog/confirm-dailog.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CopyModalComponent } from '../common/copy-modal/copy-modal.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CopyMenuModalComponent } from '../common/copy-menu-modal/copy-menu-modal.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { FeatureListingComponent } from '../system/feature-listing/feature-listing.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CopyFeatureModalComponent } from '../common/copy-feature-modal/copy-feature-modal.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    HelpComponent,
    FilterPipe,
    SortPipe,
    ConfirmDailogComponent,
    CopyModalComponent,
    CopyMenuModalComponent,
    FeatureListingComponent,
    CopyFeatureModalComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FontAwesomeModule,
    NgbModule,
    ReactiveFormsModule,
    GridModule,
    PopupModule,
    InputsModule,
    DropDownsModule,
    DialogModule,
    ButtonsModule,
    FormsModule,
    TreeViewModule,
    NgxSpinnerModule,
  ],
  exports: [
    FooterComponent,
    FilterPipe,
    SortPipe,
    ConfirmDailogComponent,
    CopyModalComponent,
    CopyMenuModalComponent,
    FeatureListingComponent,
    CopyFeatureModalComponent
    ]
})
export class SharedModule { }
