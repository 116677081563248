import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { faCheck, faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject, Subscription } from 'rxjs';
import { CustomFormValidationsService } from 'src/app/shared/formValidations/custom-form-validations.service';

@Component({
  selector: 'app-enum-edit',
  templateUrl: './enum-edit.component.html',
  styleUrls: ['./enum-edit.component.scss']
})
export class EnumEditComponent implements OnInit, OnChanges {
  @Output() closeEvent = new EventEmitter<number>();
  @Output() argCloseEvent = new EventEmitter<EnumArgument>();
  @Output() saveEvent = new EventEmitter<EnumValues>();
  @Output() displayErrorMessage = new EventEmitter<string>();
  @Input() enumData = '';
  @Input() minimum = -1;
  @Input() maximum = -1;
  @Input() isbitMaskEnum = false;
  faCheck = faCheck;
  faCopy = faCopy;
  faTimes = faTimes;
  errorMessage = ''
  _val: Subject<string> = new Subject();
  @Input()
  set events(val: Subject<string>) {
    this._val = val;
  }
  _index: number = -1
  @Input()
  set index(index: number) {
    this._index = index;
  }

  _argumentIndex: number = -1
  @Input()
  set argumentIndex(argumentIndex: number) {
    this._argumentIndex = argumentIndex;
  }
  _rows: string;
  @Input()
  set rows(rows: string) {
    this._rows = rows;
  }

  modifiedEnumValues: EnumValues

  private eventsSubscription: Subscription;

  constructor(private customFormValidationsService: CustomFormValidationsService) { }


  ngOnInit() {
    this.eventsSubscription = this._val.subscribe((data) => {
      this.enumData = data;
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if(changes.minimum) {
      this.minimum = +changes.minimum.currentValue
    }
    if(changes.maximum) {
      this.maximum = +changes.maximum.currentValue
    }
  }

  /*
  * Check for duplicate validations, maximum and minimum range or number of rows (2 pow n)
  */
  async saveEnumData() {
    let success = await this.duplicateValidations()
    if (success) {
      this.saveEvent.emit(this.modifiedEnumValues)
    }
    this.displayErrorMessage.emit(this.errorMessage)
  }

  /*
  * Close enum edit view
  */
  closeEnumEdit() {
    this.errorMessage = ""
    this.displayErrorMessage.emit(this.errorMessage)
    if (this.argCloseEvent.observers.length > 0) {
      let indexes: EnumArgument = { index: this._index, argument_index: this._argumentIndex }
      this.argCloseEvent.emit(indexes)
    } else {
      this.closeEvent.emit(this._index)
    }
  }

  /*
  * Copy enum values to clipboard
  */
  copyText() {
    navigator.clipboard.writeText(this.enumData);
  }

  /*
    enum rows duplicate validations
  */
  async duplicateValidations() {
    this.errorMessage = ''
    this.displayErrorMessage.emit("")
    let arrayValues = this.enumData.split("\n").filter(x => x != '');
    if(arrayValues.length > 2000) {
      this.errorMessage = "Data entries should not exceed 2000 rows."
      this.displayErrorMessage.emit(this.errorMessage)
      return false
    }
    let indexesArray = Array<string>()
    let valuesArray = {}
    let existedElement = ""
    arrayValues.forEach((item: string) => {
      let splitted = item.split(':')
      const element = indexesArray.find((item) => {
        return item === splitted[0]
      })
      if (element) {
        existedElement = element;
      }
      indexesArray.push(splitted[0])
      valuesArray[splitted[0]] = splitted[1]
    })
    this.modifiedEnumValues = {
      indexes: indexesArray,
      values: valuesArray,
      index: this._index,
      argumentIndex: this._argumentIndex > -1 ? this._argumentIndex : undefined
    }
    if (existedElement.length > 0) {
      this.errorMessage = 'Duplicate enumeration index value ' + existedElement;
      return false;
    }
    if (this.isbitMaskEnum === true) {
      return this.rowsValidators(indexesArray)
    } else {
      return (this.maximum >= 0 || this.minimum >= 0) ? this.rangeValidations(indexesArray) : true
    }
  }

  /*
    enum min & max validations
  */
  rangeValidations(indexesArray) {
    let sorted = indexesArray.sort((first, second) => {
      if (parseInt(first) > parseInt(second)) {
        return 1;
      }
      return -1;
    })
    if (this.minimum !== null && this.maximum !== null) {
      if (parseInt(sorted[sorted.length - 1]) > this.maximum || parseInt(sorted[0]) < this.minimum) {
        this.errorMessage = 'Enumeration value should be between min and max range';
        return false
      }
    }
    return true
  }

  /*
    Bitmask enum no.of rows validations
  */
  rowsValidators(indexesArray) {
    this.errorMessage = ""
    let min;
    let max;
    if (this._rows.includes('-')) {
      let splitBitValue = this._rows.split('-');
      min = Number(splitBitValue[0]);
      max = Number(splitBitValue[1]);
    } else {
      min = max = Number(this._rows);
    }

    const exponent = Number(max - min + 1);
    const powResult = this.customFormValidationsService.pow(2, exponent);
    const enumCount = powResult;

    if (indexesArray.length > enumCount) {
      this.errorMessage = 'Enumeration rows should not be greater than ' + enumCount;
      return false
    } else {
      return true
    }
  }


  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}

export interface EnumValues {
  index: number
  indexes: string[],
  values: {},
  argumentIndex?: number
}

export interface EnumArgument {
  index: number,
  argument_index: number
}
