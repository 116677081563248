import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getServiceUrl, AppService } from 'src/app/global/global';
import { DataType } from 'src/app/models/component/srdo-data.model';

@Injectable({
  providedIn: 'root'
})
export class CommandResponseService {

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }
  /**
   * Get Command response data
   */
  getCommandListData(componentId): Observable<any> {
    const ComponentId = componentId.toString();
    return this.http.get<any>(getServiceUrl(AppService.GetCommandsByComponentId , ComponentId), this.httpOptions);
  }
  commandResponseFileUploadService(data, componentId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentid: componentId
      })
    };
    return this.http.post(getServiceUrl(AppService.CommandResponseDataImport), data, this.httpOptions);
  }
  getCommandResponseDataService(componentId, commandId): Observable<any> {
    return this.http.get<any>(getServiceUrl(AppService.GetCommandResponseData_2, componentId, commandId),this.httpOptions);
  }
  getCommandResponseGridDataService(componentId, commandId, headerData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        pageSize: headerData.pageSize,
        pageIndex: headerData.pageIndex,
        sortorder: headerData.sortorder,
        sortcolumname: headerData.sortcolumname
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.GetCommandResponseData , commandId),this.httpOptions);
  }

  textFileUploadService(data, componentId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentid: componentId
      })
    };
    return this.http.post(getServiceUrl(AppService.Importdatatext), data, this.httpOptions);
  }

  defaultTextFileUpload(data, componentId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
      }),
      params: { isDefault: 'true' }
    };
    return this.http.post(getServiceUrl(AppService.Importdatatext), data, httpOptions);
  }

  GetUploadedTextFileService(textFileComponentId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        componentId: textFileComponentId
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.GetComponentDataText), httpOptions);
  }

  getComponentDefaultTextFile(textFileComponentId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        componentId: textFileComponentId.toString(),
      }),
      params: { isDefault: 'true' }
    };
    return this.http.get<any>(getServiceUrl(AppService.GetComponentDataText), httpOptions);
  }

  addCommandData(data, componentId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentid: componentId.toString()
      })
    };
    return this.http.post(getServiceUrl(AppService.AddCommand), data, this.httpOptions);
  }
  removeCommandData(id): Observable<any> {
    return this.http.delete(getServiceUrl(AppService.DeleteCommand, id), this.httpOptions);
  }
  editCommandData(data): Observable<any> {
    return this.http.put(getServiceUrl(AppService.UpdateCommand), data, this.httpOptions);
  }
  addResponseArgument(data, commandId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        commandId: commandId.toString()
      })
    };
    return this.http.post(getServiceUrl(AppService.AddResponseArgument), data, this.httpOptions);
  }
  
  AddCommandArgument(data, commandid): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        commandId: commandid.toString()
      })
    };
    return this.http.post(getServiceUrl(AppService.AddCommandArgument), data, this.httpOptions);
  }

  RemoveResponseData(id): Observable<any> {
    return this.http.delete(getServiceUrl(AppService.DeleteResponse, id), this.httpOptions);
  }

  RemoveResponseArgumentData(id): Observable<any> {
    return this.http.delete(getServiceUrl(AppService.DeleteResponseArgument, id), this.httpOptions);
  }
  DeleteCommandArgument(id): Observable<any> {
    return this.http.delete(getServiceUrl(AppService.DeleteCommandArgument, id), this.httpOptions);
  }

  UpdateCommandArgument(data): Observable<any> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const ComponentData = data;
    ComponentData.ModifiedBy = 'test@gmail.com';
    const body = JSON.stringify(ComponentData);
    return this.http.put(getServiceUrl(AppService.UpdateCommandArgument), body, httpOptions1);
  }

  updateResponseArgument(data, responseId): Observable<any> {
    const ResponseData = data;
    ResponseData.modifiedBy = 'test@gmail.com';
    ResponseData.id = responseId;
    delete ResponseData.createdBy;
    const body = JSON.stringify(ResponseData);
    return this.http.put(getServiceUrl(AppService.UpdateCommandResponse), data, this.httpOptions);
  }
  getBitmaskDataType(): Observable<any> {
    return this.http.get<any>(getServiceUrl(AppService.GetBitmaskDataType), this.httpOptions);
  }
  getNextCommandSubType(componentId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.GetNextSubTypeOfComponent), this.httpOptions);
  }

  fetchToolTips() : Observable<DataType[]> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<DataType[]>(getServiceUrl(AppService.GetToolTips), httpOption);
  }

  /**
   * Copy SRDO to Component 
   * sourceSRDOID - Source SRDO
   * componentId - Destination component
   * pageNumber - Page number of Source SRDO
   * returns {Observable}
   */
  copyCommandFromOtherComp(sourceCommandDbId, componentId): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        sourceCommandDbId: sourceCommandDbId,
        targetComponentId: componentId,
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.CopyCommand), this.httpOptions);
  }

  copyResponseFromOtherComp(sourceComponentId: number, sourceCommandDbId: number, sourceResponseId: number, 
    targetcomponentId: number, targetCommandId: number ): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        sourceCommandId: sourceCommandDbId.toString(),
        sourceComponentId: sourceComponentId.toString(),
        sourceResponseId : sourceResponseId.toString(),
        targetComponentId : targetcomponentId.toString(),
        targetCommandId : targetCommandId.toString()
      })
    };
    return this.http.post<any>(getServiceUrl(AppService.copyResponseCode), null, httpOptions);
  }

}
