// toast.service.ts
import { Injectable, TemplateRef  } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private notificationService: NotificationService) {
  }

  public show(textOrTpl: string | TemplateRef<any>, options: any = {}): void {
    if(options !== undefined) {
      if(options.classname === "bg-success") {
        this.notificationService.show({
          content: textOrTpl,
          cssClass: 'button-notification',
          position: { horizontal: 'center', vertical: 'top' },
          animation: { type: "slide", duration: 400 },
          type: { style: "success", icon: true },
          appendTo: options.appendTo,
          hideAfter: 2000
        });
      }
      else if(options.classname === "bg-danger") {
        this.notificationService.show({
          content: textOrTpl,
          cssClass: 'button-notification',
          animation: { type: "slide", duration: 400 },
          position: { horizontal: 'center', vertical: 'top' },
          type: { style: 'error', icon: true },
          closable: true
      });
      }
    }
  }
}
