import { Pipe, PipeTransform } from '@angular/core';
import { FilterType } from '../component-data/command-response-data/command-response-data.component';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(values: any[], args: FilterType, args1: boolean): any[] {
    let sorted = []
    switch (args) {
      case FilterType.Name:
        sorted = values.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        break;
      case FilterType.Subtype:
        sorted = values.sort((item1, item2) => item1.commandSubType - item2.commandSubType)
        break;
      case FilterType.CreatedDate:
        sorted = values.sort((item1, item2) => {
          let date1 = new Date(item1.createdOn)
          let date2 = new Date(item2.createdOn)
          if (date1 < date2) {
            return -1;
          }
          if (date1 > date2) {
            return 1;
          }
          return 0;
        })
        break;
      case FilterType.ModifiedDate:
        sorted = values.sort((item1, item2) => {
          let date1 = item1.modifiedOn ? new Date(item1.modifiedOn) : new Date(item1.createdOn)
          let date2 = item2.modifiedOn ? new Date(item2.modifiedOn) : new Date(item2.createdOn)
          if (date1 < date2) {
            return -1;
          }
          if (date1 > date2) {
            return 1;
          }
          return 0;
        })
        break;
      case FilterType.Id:
          sorted = values.sort((item1, item2) => item1.srdoId - item2.srdoId)
          break;
    }
    return args1 === true ? sorted : sorted.reverse();
  }

}
