import { Component, OnInit } from '@angular/core';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { WidgetService } from '../../services/widget/widget.service';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ToastService } from '../../services/toast/toast.service';
import { ModelPopupComponent } from '../../common/model-popup/model-popup.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogboxService } from '../../services/confirmation-dialog/dialogbox.service';
import { UserAccess } from 'src/app/global/user-access';
import { MenuWidgetModel } from 'src/app/models/widget/menu.widget.model';

@Component({
  selector: 'app-menu-widget',
  templateUrl: './menu-widget.component.html',
  styleUrls: ['./menu-widget.component.scss']
})
export class MenuWidgetComponent implements OnInit {
  menuWidgetFrom: UntypedFormGroup;
  faSearch = faSearch;
  faTimes = faTimes;
  menuWidgetId: number = null;
  menuWidgetName: string = null;
  menuWidgetList: MenuWidgetModel[] = [];
  submitted = false;
  updateMenuWidget: any;
  isDefault: boolean;
  phoneAdminAccess = false;
  text: string;


  constructor(private widgetService: WidgetService,
              private formBuilder: UntypedFormBuilder , private modalService: NgbModal,
              public toastService: ToastService , private spinner: NgxSpinnerService,
              private confirmationDialogService: DialogboxService,
              private useraccess: UserAccess) { }
  formTemplate = [
    {
      type: 'text',
      label: 'Name',
      formName: 'name',
      value: '',
      min: 2,
      max: 100
    },
    {
      type: 'checkbox',
      label: 'Is Controller Data Requested? ',
      formName: 'isDataRequested',
      value: 'false'
    }
  ];
  
  formValidation = {
    name: ['', Validators.required],
    isDataRequested: false,
    value: ['']
  };
  ngOnInit() {
    this.spinner.show();
    this.checkAccess("");
    this.initForm();
    this.getMenuWidgetList();
  }

  initForm() {
    this.menuWidgetFrom = this.formBuilder.group({
      name: [null, Validators.required],
    });
  }
  // Menu modal popup
  open() {
    this.formTemplate[0].value = '';
    this.formTemplate[1].value = 'false';
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    const modalRef = this.modalService.open(ModelPopupComponent,ngbModalOptions);
    modalRef.componentInstance.form_template = this.formTemplate;
    modalRef.componentInstance.my_modal_title = 'Menu Widget';
    modalRef.componentInstance.my_modal_content = 'Add Menu Widget';
    modalRef.componentInstance.form_validation = this.formValidation;
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.widgetService.addMenuWidget(result).subscribe((res) => {
        this.spinner.show();
        if (res === true) {
          this.ToastMessage('Menu Widget Created Successfully.', 'bg-success', 'Success');
          this.menuWidgetId = null;
          this.menuWidgetName = null;
          this.isDefault = null;
          this.getMenuWidgetList();
        }
      }, () => {
        this.spinner.hide();
      });
    });
  }

  // Menu Edit modal popup
  editMenuWidget(menuWidgetName, id) {
    if (this.isDefault === true) {
      alert('You can not edit the default menu widget.Please select another.');
    } else {
        this.formTemplate[0].value = menuWidgetName;
        let menuItem = this.menuWidgetList.find((item) => item.id === +id);
        if(menuItem) {
          this.formTemplate[1].value = menuItem.isDataRequested.toString()
        }
      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false
      };
      const modalRef = this.modalService.open(ModelPopupComponent,ngbModalOptions);
      modalRef.componentInstance.form_template = this.formTemplate;
      modalRef.componentInstance.my_modal_title = 'Edit Menu Widget';
      modalRef.componentInstance.my_modal_content = 'Edit Menu Widget';
      modalRef.componentInstance.form_validation = this.formValidation;
      modalRef.componentInstance.isDataRequested = this.formTemplate[1].value === 'true'
      modalRef.result.then((result) => {
        this.spinner.show();
        this.widgetService.updateMenuWidget(result, id).subscribe((res) => {
          this.spinner.hide();
          if (res === true) {
            const bodyRes = JSON.parse(JSON.stringify(result));
            this.menuWidgetId = id;
            this.menuWidgetName = bodyRes.name;
            this.getMenuWidgetList();
            this.ToastMessage('Menu Widget Successfully Updated.', 'bg-success', 'Success');
          } else {
            this.ToastMessage('HTTP ERROR....', 'bg-danger','Error');
          }
        }, ((error) => {
          this.spinner.hide();
        }))
      })
    }
  }

  getMenuWidgetList() {
      this.widgetService.getMenuWidgetList().subscribe((data) => {
        if (data !== null) {
          this.menuWidgetList = data;
          if (this.menuWidgetId === null) {
            if (this.menuWidgetList.length > 0) {
              this.menuWidgetId = this.menuWidgetList[0].id;
              this.menuWidgetName = this.menuWidgetList[0].name;
              this.isDefault = this.menuWidgetList[0].isDefault;
            }
          }
          this.onMenuWidgetSelect(this.menuWidgetId, this.menuWidgetName,this.isDefault);
          this.spinner.hide();
      }
    }, ((error) => {
        this.spinner.hide();
    }));
  }

  // convenience getter for easy access to form fields
  get menuWidgetFromControl() { return this.menuWidgetFrom.controls; }

  ToastMessage(message, type, Header) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: Header
    });
  }

  clearMenuWidgetForm() {
    this.menuWidgetFrom.reset();
    this.submitted = false;
  }

  onMenuWidgetSelect(Id, MenuName,isDefault) {
    if (Id !== null) {
      const menuWidgetId = Id.toString();
      this.menuWidgetId = menuWidgetId;
      this.menuWidgetName = MenuName;
      this.isDefault = isDefault;
      const MenuWidgetValue = {
        name: this.menuWidgetName,
      };
      this.menuWidgetFrom.patchValue(MenuWidgetValue);
    }
  }
  deleteMenuWidget(Id, isDefault) {
    if (isDefault === true) {
      alert('You can not delete the default menu widget.Please select another.');
    } else {
      this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
      .then((confirmed) => {
        if (confirmed === true) {
          this.spinner.show();
          this.widgetService.deleteMenuWidget(Id).subscribe((res) => {
            if (res === true) {
              this.menuWidgetId = null;
              this.menuWidgetName = null;
              this.ToastMessage('Menu Widget deleted Successfully.', 'bg-success', 'Success');
              this.getMenuWidgetList();
            } else {
              this.ToastMessage('HTTP ERROR....', 'bg-danger', 'Error');
              this.spinner.hide();
            }
          }, () => {
            this.spinner.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
  }

  public checkAccess(action: string, dataItem: number = -1) {
    this.phoneAdminAccess = this.useraccess.getUserAccess("PhoneAdministrator", action, dataItem !== -1 ? +dataItem : -1);
  }
  searchClear() {
    this.text = null;
  }

}
