import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Subscription, fromEvent } from 'rxjs';
import { take, tap } from 'rxjs/Operators';
import { SrdoModel } from 'src/app/models/component/srdo-data.model';

@Component({
  selector: 'app-rearrange-modal',
  templateUrl: './rearrange-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./rearrange-modal.component.scss']
})
export class RearrangeModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() opened: boolean = false;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() saveEvent: EventEmitter<SrdoModel> = new EventEmitter();
  @Input() title: string = ''
  @Input() gridData: GridDataResult
  @Input() model: SrdoModel;
  data = [{}];
  public state: State = {
    skip: 0,
  };
  private currentSubscription: Subscription;

  constructor(private renderer: Renderer2, private zone: NgZone) {

  }

  ngOnInit() {
    this.data = this.model.dataTypeDetails;
    this.gridData = process(this.data, this.state);
    this.currentSubscription = this.handleDragAndDrop();
    // this.eventsSubscription = this._val.subscribe((data) => {
    //   // this.currentSubscription = this.handleDragAndDrop();
    //   // this.model = data;
    //   // this.data = this.model.dataTypeDetails;
    //   // this.gridData = process(this.data, this.state);
    // });
  }

  public ngAfterViewInit(): void {
    // this.model = data;
    this.data = this.model.dataTypeDetails;
    this.gridData = process(this.data, {});
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  public dataStateChange(state: State): void {
    this.state = state;
    this.gridData = process(this.data, this.state);
    // this.data = process(this.data.data, {});

    this.currentSubscription.unsubscribe();
    this.zone.onStable
      .pipe(take(1))
      .subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging,
    };
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;

    const tableRows = Array.from(document.querySelectorAll(".k-grid tr"));
    tableRows.forEach((item) => {
      this.renderer.setAttribute(item, "draggable", "true");
      const dragStart = fromEvent<DragEvent>(item, "dragstart");
      const dragOver = fromEvent(item, "dragover");
      const dragEnd = fromEvent(item, "dragend");

      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement("span");
                dragImgEl.setAttribute(
                  "style",
                  "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;"
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {
                // IE doesn't support setDragImage
              }
              try {
                // Firefox won't drag without setting data
                dataTransfer.setData("application/json", "");
              } catch (err) {
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.gridData.data[draggedItemIndex];
            dataItem.dragging = true;
          })
      );

      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.gridData.data[dropIndex];

          draggedItemIndex = dropIndex;
          this.zone.run(() =>
            this.gridData.data.splice(dropIndex, 0, dataItem)
          );
        })
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data[draggedItemIndex];
          dataItem.dragging = false;
        })
      );
    });

    return sub;
  }

  public close(status: boolean) {
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

  public savebtnClicked(event: any) {
    this.saveEvent.emit(this.model);
  }

}

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

const tableRow = node => node.tagName.toLowerCase() === 'tr';

