import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dailog',
  templateUrl: './confirm-dailog.component.html',
  styleUrls: ['./confirm-dailog.component.scss']
})
export class ConfirmDailogComponent implements OnInit {

  @Input() opened: boolean = false
  @Input() message: string = ''
  @Input() btnYesText: string = '';
  @Input() btnNoText: string = '';
  @Input() title: string = 'Please confirm';
  @Input() confimationMessage: string = '';
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  @Output() userconfirmAction: EventEmitter<UserConfirmActions> = new EventEmitter();
  confirmationValue = false
  constructor() { }

  ngOnInit() {
  }

  public close(status: string): void {
    let cofirmStatus = status === 'yes' ? true : false
    if(this.confimationMessage.length > 0) {
      let userConfirmAction : UserConfirmActions = {confirmAction: cofirmStatus, userConfirmAction: this.confirmationValue}
      this.userconfirmAction.emit(userConfirmAction)
      this.confirmationValue = false
    }else {
      this.confirmAction.emit(cofirmStatus)
    }
  }

}

export interface UserConfirmActions {
  confirmAction: boolean,
  userConfirmAction: boolean
}
