import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { faSearch, faTimes, faPlus, faTrash, faLink } from '@fortawesome/free-solid-svg-icons';
import { WidgetService } from '../../services/widget/widget.service';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ToastService } from '../../services/toast/toast.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { InputDataComponent } from '../../widget/input-data/input-data.component';
import { DataWidgetListHeader } from 'src/app/global/app-enums.enum';
import { ModelPopupComponent } from '../../common/model-popup/model-popup.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogboxService } from '../../services/confirmation-dialog/dialogbox.service';
import { UserAccess } from 'src/app/global/user-access';
import { LinkedFeatureSystem } from 'src/app/common/display-list-modal/display-list-modal.component';

@Component({
  selector: 'app-data-widget',
  templateUrl: './data-widget.component.html',
  styleUrls: ['./data-widget.component.scss']
})
export class DataWidgetComponent implements AfterViewInit, OnInit {
  dataWidgetFrom: UntypedFormGroup;
  faSearch = faSearch;
  faTimes = faTimes;
  faPlus = faPlus;
  faTrash = faTrash;
  faLink =faLink;
  // tslint:disable-next-line: variable-name
  dataWidgetId: number = null;
  showLinkModal = false;
  linkedFeatures: LinkedFeatureSystem[] = [];
  inputDataDeails: any = [];
  DataWidgetFrm: any = {
    name: '',
    size: '',
  };
  DataWidgetValue: any = {
    name: '',
    size: ''
  }
  dataWidgetList: any = [];
  InputItems: any = [];
  submitted = false;
  pageSize = '6';
  dataWidgetName: string = null;
  updateMenuWidget: any;
  isDefault: boolean;
  phoneAdminAccess = false;
  text: string;
  title: string;
  InpuDataWidgetListHeaders = [
    {
      key: 'key',
      value: DataWidgetListHeader.InputNumber
    },
    {
      key: 'value',
      value: DataWidgetListHeader.DataIdentifier
    }
  ];

  headerData = {
    pageSize: this.pageSize,
    pageIndex: '1',
    sortorder: 'Ascending',
    sortcolumname: 'Id'
  };
  formTemplate = [
    {
      type: 'text',
      label: 'Name',
      formName: 'name',
      value: '',
      min: 3,
      max: 100
    },
    {
      type: 'text',
      label: 'Size',
      formName: 'size',
      value: '',
      min: 1,
      max: 2
    },
  ];
  formValidation = {
    name: ['', Validators.required],
    size: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
  };
  InputData: any = []
  @ViewChild('InpuDataListTable') InpuDataListTable: InputDataComponent;
  constructor(private widgetService: WidgetService,
              private formBuilder: UntypedFormBuilder, public toastService: ToastService,
              private router: ActivatedRoute, private spinner: NgxSpinnerService,
              private modalService: NgbModal, private confirmationDialogService: DialogboxService,
              private useraccess: UserAccess) { }

  ngOnInit() {
    this.initForm();
    this.checkAccess("");
    this.spinner.show();
    this.getDataWidgetList();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
         // this.InpuDataListTable.headers = this.InpuDataWidgetListHeaders;
    });
  }

  initForm() {
    this.dataWidgetFrom = this.formBuilder.group({
      name: [null, Validators.required],
      size: [null, Validators.required]
    });
  }
  // Add Data Widget modal popup
  open() {
    this.formTemplate.forEach((formdata, index) => {
      this.formTemplate[index].value = '';
    });
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    const modalRef = this.modalService.open(ModelPopupComponent, ngbModalOptions);
    modalRef.componentInstance.form_template = this.formTemplate;
    modalRef.componentInstance.my_modal_title = 'Data Widget';
    modalRef.componentInstance.my_modal_content = 'Add Data Widget';
    modalRef.componentInstance.form_validation = this.formValidation;
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.widgetService.addDataWidget(result).subscribe((res) => {
        this.spinner.show();
        if (res !== null) {
          this.ToastMessage('Data Widget Created Successfully.', 'bg-success', 'Success');
          this.dataWidgetId = null;
          this.isDefault = null;
          this.getDataWidgetList();
          this.spinner.hide();
        }
      }, () => {
        this.spinner.hide();
      });
    });
  }

  // Data Widget Edit modal popup
  editDataWidget(id) {
    this.formTemplate[0].value = this.dataWidgetFrom.get('name').value;
    this.formTemplate[1].value = this.dataWidgetFrom.get('size').value;
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    const modalRef = this.modalService.open(ModelPopupComponent,ngbModalOptions);
    modalRef.componentInstance.form_template = this.formTemplate;
    modalRef.componentInstance.my_modal_title = 'Edit Data Widget';
    modalRef.componentInstance.my_modal_content = 'Edit Data Widget';
    modalRef.componentInstance.form_validation = this.formValidation;
    modalRef.result.then((result) => {
      this.spinner.show();
      this.widgetService.updateDataWidget(result, id).subscribe((res) => {
        if (res !== null) {
          const bodyRes = JSON.parse(JSON.stringify(result));
          this.dataWidgetId = id;
          this.isDefault = bodyRes.isDefault;
          this.getDataWidgetList();
          this.ToastMessage('Data Widget Successfully Updated.', 'bg-success', 'Success');
          this.spinner.hide();
        } else {
          this.ToastMessage('HTTP ERROR....', 'bg-danger', 'Error');
        }
      }, () => {
        this.spinner.hide();
      });
    }, () => {
      this.spinner.hide();
    });
  }
  getDataWidgetList() {
    this.widgetService.getDataWidgetList().subscribe((data) => {
      if (data !== null) {
        this.dataWidgetList = data;
        if (this.dataWidgetId === null) {
          if (this.dataWidgetList.length > 0) {
            this.dataWidgetId = this.dataWidgetList[0].id;
            this.isDefault = this.dataWidgetList[0].isDefault;
          }
        }
      }
      this.onDataWidgetSelect(this.dataWidgetId);
      this.spinner.hide();
    },
      (() => {
        this.spinner.hide();
      }));
  }

  // convenience getter for easy access to form fields
  get dataWidgetFromControl() { return this.dataWidgetFrom.controls; }

  ToastMessage(message, type, Header) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: Header
    });
  }

  clearSrdoForm() {
    this.dataWidgetFrom.reset();
    this.submitted = false;
  }

  closeEvent(event) {
    this.showLinkModal = false;
  }

  onDataWidgetSelect(Id) {
    this.submitted = false;
    if (Id !== null) {
      this.spinner.show();
      this.dataWidgetId = Id.toString();
      this.widgetService.WidgetDetailsById(this.dataWidgetId).subscribe((DataWidgetItem) => {
        if (DataWidgetItem.length > 0) {
          this.InputData = JSON.parse(JSON.stringify(DataWidgetItem[0].dataDetails));
          this.DataWidgetValue = {
            name: DataWidgetItem[0].name,
            size: DataWidgetItem[0].size
          };
          this.isDefault = DataWidgetItem[0].isDefault;
          this.dataWidgetFrom.patchValue(this.DataWidgetValue);
        }
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
    }
  }
  deleteDataWidget(Id, isDefault) {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
      .then((confirmed) => {
        if (confirmed === true) {
          this.spinner.show();
          this.widgetService.deleteDataWidget(Id).subscribe((res) => {
            if (res === true) {
              this.dataWidgetId = null;
              this.ToastMessage('Data Widget deleted Successfully.', 'bg-success', 'Success');
              this.getDataWidgetList();
            } else {
              this.ToastMessage('HTTP ERROR....', 'bg-danger', 'Error');
              this.spinner.hide();
            }
          }, () => {
            this.spinner.hide();
          });
        }
      })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  getDataWidgetDetails(id) {
    this.onDataWidgetSelect(id);
  }

  public checkAccess(action: string, dataItem: number = -1) {
        this.phoneAdminAccess = this.useraccess.getUserAccess("PhoneAdministrator", action, dataItem !== -1 ? +dataItem : -1);
    }
  searchClear() {
    this.text = null;
  }
  

  openLinkedFeatures() {
    this.spinner.show();
    this.title = `Features linked with ${this.DataWidgetValue.name}`;
    let isAdmin = this.useraccess.actions.roles.find((x) => x.id == 1);
    let isGlobalRead = this.useraccess.actions.roles.find((x) => x.id == 9);
    let systemOwner = this.useraccess.actions.roles.find((x) => x.id == 2);
    let systemUser = this.useraccess.actions.roles.find((x) => x.id == 3);
    let systemReadOnly = this.useraccess.actions.roles.find((x) => x.id == 8);
    let showLink = isAdmin
      ? true
      : !systemOwner || !systemUser || !systemReadOnly || !isGlobalRead;
    this.widgetService
      .getSystemInfoByDataWidgetId(this.dataWidgetId, true)
      .subscribe(
        (res) => {
          if (res !== null) {
            const result = JSON.parse(JSON.stringify(res));
            if (result.systemData) {
              Object(result.systemData).forEach((obj) => {
                this.renameKey(obj, "systemName", "System Name");
              });
              this.linkedFeatures = result.systemData;
              this.updateIsNavigale(isAdmin, isGlobalRead, showLink);
            } else {
              this.linkedFeatures = [];
            }
            this.showLinkModal = true;
            this.spinner.hide();
          } else {
            this.ToastMessage("HTTP ERROR....", "bg-danger", "Error");
          }
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  /**
   * update isNavigable flag
   * @param isAdmin 
   * @param isGlobalRead 
   * @param showLink 
   */
  updateIsNavigale(isAdmin: any, isGlobalRead: any, showLink: Boolean) {
    this.linkedFeatures.map((feature) => {
      let isNavigable = false
      if(!isAdmin && !isGlobalRead) {
        let system = this.useraccess.actions.systems.find((system) => {
          return system.systemId === feature.systemId
        })
        if(system) {
          isNavigable = true
        }else {
          isNavigable = false
        }
        feature.isNavigable = showLink && isNavigable
      } else {
        feature.isNavigable = true
      }})
  }

  renameKey(obj: any, oldKey: string, newKey: string) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

}

