import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getServiceUrl, AppService } from 'src/app/global/global';
import { SystemListModel, SystemModel  } from 'src/app/models/system/system.data.model';
import { Artifacts } from 'src/app/system/system-data/system-data.component';
import { UserAndRole } from 'src/app/models/component/component.data.model';
import { map } from 'rxjs/Operators';

@Injectable({
  providedIn: 'root'
})
export class SystemListService {

  private _systemList: Array<SystemModel> = []; 

  set systemList(list: SystemModel[]) {
    this._systemList = list;
  }

  get systemList() {
    return this._systemList;
  }


  selectedSystemId: number;
  constructor(private http: HttpClient) { }

  /**
   * Add System
   * returns true
   * memberof SystemListService
   */
  addSystem(data) {
    const httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(data);
    return this.http.post(getServiceUrl(AppService.AddSystem), body, httpOption);
  }
  /**
   * System List Data
   * param {} headerData
   * returns {Observable<SystemListModel>}
   * memberof SystemListService
   */
  getSystemDataList(headerData, name): Observable<SystemListModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        pageSize: headerData.pageSize,
        pageIndex: headerData.pageIndex,
        sortorder: headerData.sortorder,
        sortcolumname: headerData.sortcolumname,
        systemname: name
      })
    };
    return this.http.get<SystemListModel>(getServiceUrl(AppService.SystemList), httpOptions);
  }

  /**
   * Update of System
   * returns true
   * memberof updateSystem
   */

  updateSystem(updateData, id) {
    const httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const SystemData =  updateData;
    SystemData.modifiedBy = 'test@gmail.com';
    SystemData.id = id;
    const body = JSON.stringify(SystemData);
    return this.http.post(getServiceUrl(AppService.UpdateSystem), body, httpOption);
  }
  /**
   * List of System
   * returns true
   * memberof AllSystems
   */
  getAllSystemList(isAccessRequested: Boolean) : Observable<SystemModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'isAccessRequested': isAccessRequested.toString()
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.AllSystems), httpOptions).pipe(map( (data) => {
      return data.systems;
    }));;
  }
  /**
   * Delete System with id
   * param  Id
   * returns
   * memberof SystemListService
   */
  deleteSystemListData(Id) {
    // Http Options
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json'
      })
    };
    return this.http.delete(getServiceUrl(AppService.DeleteSystem, Id), httpOptions);
  }
  /**
   * deploy of System
   * returns true
   * memberof Deploy
   */
  deploySystem(systemId) {
    const httpOptions = {
      headers: new HttpHeaders({
      systemid : systemId
    })
   };
    return this.http.get(getServiceUrl(AppService.DeploySystem), httpOptions);
  }
  /**
   * Download Artifacts of System
   * returns true
   * memberof Download Artifacts
   */
   GetDownloadSystemArtifacts(systemId, isDeploy): Observable<Artifacts> {
    const httpOptions = {
      headers: new HttpHeaders({
      systemid : systemId
    })
   };
   if(isDeploy === true) {
    return this.http.get<Artifacts>(getServiceUrl(AppService.DownloadSystemArtifacts), httpOptions);
   }
   return this.http.get<Artifacts>(getServiceUrl(AppService.TestArtifacts), httpOptions);
    
  }

  testSystem(systemnumber): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        systemid: systemnumber.toString()
      })
    };
    return this.http.get<boolean>(getServiceUrl(AppService.SystemTest), httpOptions);
  }

  copySystem(sourceSystemId, destinationSystemId): Observable<any> {
   const httpOptions = {
      headers: new HttpHeaders({
        sourcesystemid : sourceSystemId.toString(),
        destinationsystemid: destinationSystemId.toString()
      })
     };
    return this.http.post<any>(getServiceUrl(AppService.CopySystem),{}, httpOptions);
  }

  fetchAssociatedUsers(systemId: number): Observable<UserAndRole[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'systemId': systemId.toString()
      })
    };
    return this.http.get<UserAndRole[]>(getServiceUrl(AppService.GetSystemAssociatedUsers), httpOptions);
  }
} 
