import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuditDataService } from '../services/audit/audit.service';
import { AuditRoutingModule } from './audit-routing.module';
import { AuditComponent } from './audit.component';


@NgModule({
  declarations: [
    AuditComponent
  ],
  imports: [
    CommonModule,
    AuditRoutingModule,
    GridModule,
    NgxSpinnerModule,
    DialogModule
  ],
  providers: [AuditDataService],
})
export class AuditModule { }
