import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { NgxSpinnerService } from "ngx-spinner";
import { MenuTitle } from "src/app/models/component/component.data.model";
import { CommonMenusFeaturesService } from "src/app/services/common-menus-features/common-menus-features.service";
import { MenuService } from "src/app/services/system/menu.service";
import { UserAccess } from "src/app/global/user-access";
import { FeatureService } from "src/app/services/system/feature.service";
import {  Subscription } from "rxjs";
import { ComponentDataActions } from "src/app/global/app-enums.enum";
import { exists } from "fs";

@Component({
  selector: "app-common-menu-features",
  templateUrl: "./common-menu-features.component.html",
  styleUrls: ["./common-menu-features.component.scss"],
})
export class CommonMenuFeaturesComponent implements OnInit, OnDestroy {
  selectedTab = "Features";
  faPlus = faPlus;
  faEdit = faEdit;
  opened = false;
  public commonMenuTitle = "";
  public commonMenuDesc = "";
  // componentId: string;
  selectedTitle: MenuTitle;
  menuTitles: MenuTitle[] = [];
  public defaultItem: MenuTitle = {
    name: "Select system template",
    description: '',
    id: null,
  };
  public titleForm: FormGroup = new FormGroup({
    title: new FormControl({}, [Validators.required]),
    desc: new FormControl({}),
  });
  newMenuTitleId: number = -1;
  menufeatureSelectionSub: Subscription
  fetchCommonFeaturesSub: Subscription;
  componentAccess = false
  titleEditState = false
  constructor(
    private route: ActivatedRoute,
    private menuService: MenuService,
    private spinner: NgxSpinnerService,
    private commonMenusFeaturesService: CommonMenusFeaturesService
  ) {}
  ngOnDestroy(): void {
    this.menufeatureSelectionSub.unsubscribe()
    if(this.fetchCommonFeaturesSub) {
        this.fetchCommonFeaturesSub.unsubscribe()
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      if (this.route.snapshot.paramMap.get("id") != null) {
        let componentId = this.route.snapshot.paramMap.get("id");
        this.commonMenusFeaturesService.componentId = componentId;
        this.fetchCommonMenuTitles();
      }
    });

    this.menufeatureSelectionSub = this.commonMenusFeaturesService.menufeatureSelection$.subscribe((selection: string) => {
      switch(selection) {
          case 'Menu':
              this.selectedTab = 'Menus'
            break
          case 'Feature':
            this.selectedTab = 'Features'
            break;
      }
    })
  }

  menuButtonClicked(value: string) {
    this.selectedTab = value;
  }

  /**
   * Helper function
   */
  addCommonMenu() {
    this.titleEditState = false
    this.opened = true;
  }

    /**
   * Edit function
   */
    editSystemTemplate() {
      if(this.selectedTitle.id) {
        this.titleEditState = true
        this.commonMenuTitle = this.selectedTitle.name
        this.commonMenuDesc = this.selectedTitle.description
        this.opened = true;
      }
    }

  /**
   * Common menu section
   *
   * @param itemArgs
   * @returns
   */
  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === -1;
  }

  /**
   * Helper function
   */
  public open(): void {
    this.opened = true;
  }

  /**
   * Menu Titles Section
   * @param status
   * @returns
   */
  public close(status: string): void {
    if (this.titleForm.invalid && status == "yes") {
      return;
    }
    if (status == "yes") {
      const data = {
        title: this.commonMenuTitle,
        description: this.commonMenuDesc,
      };
      this.spinner.show();
      let compId = this.commonMenusFeaturesService.componentId;
      if (compId) {
         this.titleEditState == true ? this.updateSystemTemplate(compId, data) :  this.createSystemTemplate(compId, data)
      } else {
        this.resetandClose();
      }
    }else {
      this.resetandClose();
    }
  }

  createSystemTemplate(compId: string, data: any) {
    this.menuService.createTitle(compId, data).subscribe(
      (res) => {
        if (res) {  
          // this.newMenuTitleId = res;
          this.resetandClose();
          this.commonMenusFeaturesService.ToastMessage(
            "System template added successfully",
            "bg-success"
          );
          this.fetchCommonMenuTitles();
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  updateSystemTemplate(compId: string, data: any) {
    data.id = this.selectedTitle.id
    this.menuService.updateTitle(compId, data).subscribe(
      (res) => {
        if (res) {
          this.newMenuTitleId = res;
          this.resetandClose();
          this.commonMenusFeaturesService.ToastMessage(
            "System template updated successfully",
            "bg-success"
          );
          this.fetchCommonMenuTitles();
        } else {
          this.commonMenusFeaturesService.ToastMessage("System template doesn't exists", 'bg-danger')
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  /**
   * Helper function
   */
  resetandClose() {
    this.titleEditState = false
    this.opened = false;
    this.commonMenuTitle = "";
    this.commonMenuDesc = "";
    this.titleForm.reset();
  }

  /**
   * Menu Titles Section
   * Selection change - Change in common menu title
   * @param value
   */
  public selectionChange(value: MenuTitle): void {
    this.selectedTitle = value;
    this.commonMenusFeaturesService.changeCommonTitle(value);
    if (value.id) {
      // this.spinner.show()
      this.fetchCommonFeatures()
    }
  }

  /**
   * Menu Titles Section
   * Fetch common titles
   */
  fetchCommonMenuTitles() {
    this.spinner.show();
    let compId = this.commonMenusFeaturesService.componentId;
    if (compId) {
      this.commonMenusFeaturesService.fetchCommonMenuTitles(compId).subscribe({
        next: (status) => {
          this.componentAccess = this.commonMenusFeaturesService.componentAccess
          if (status) {
            this.menuTitles = this.commonMenusFeaturesService.commonMenuTitles;
            if (this.menuTitles.length > 0) {
              this.selectedTitle =
                this.newMenuTitleId > -1
                  ? this.findMenuWithId(this.newMenuTitleId)
                  : this.menuTitles[0];
              this.newMenuTitleId = -1;
              this.commonMenusFeaturesService.changeCommonTitle(
                this.selectedTitle
              );
            } else {
              this.selectedTitle = this.defaultItem;
              this.commonMenusFeaturesService.commonFeatures = []
              this.commonMenusFeaturesService.changeCommonTitle(undefined);
            }
          }
          this.spinner.hide();
        },
        complete: () => {
          this.fetchCommonFeatures()
        },
        error: () => this.spinner.hide(),
      });
    }
  }

  fetchCommonFeatures() {
    if (this.selectedTitle.id) {
      this.spinner.show()
      let compId = this.commonMenusFeaturesService.componentId;
      this.fetchCommonFeaturesSub = this.commonMenusFeaturesService
        .fetchCommonFeatures(compId, this.selectedTitle.id.toString()).subscribe(features => {
          this.spinner.hide()
            this.commonMenusFeaturesService.commonFeatures = features
        }, error => this.spinner.hide())
    }
  }

  /**
   * Helper function
   * @param id
   * @returns  menutitle
   */
  findMenuWithId(id: number) {
    let menutitle = this.menuTitles.find((item) => item.id == id);
    if (menutitle) {
      return menutitle;
    } else {
      this.menuTitles[0];
    }
  }
}
