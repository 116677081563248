import { Component, OnInit } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { FileDetails } from 'src/app/models/component/component.data.model';
import { Artifacts } from '../system-data/system-data.component';

@Component({
  selector: 'app-artifacts',
  templateUrl: './artifacts.component.html',
  styleUrls: ['./artifacts.component.scss']
})
export class ArtifactsComponent extends DialogContentBase {
  headertitle = "Download Artifacts";
  private _deploy: Artifacts;
  public deployArtifacts: FileDetails[];
  public testArtifacts: FileDetails[];
  public testSas: string;
  public deploySas: string;
  public set deploy(d: Artifacts) {
    this._deploy = d;
    this.deploySas = d.sasToken;
    this.deployArtifacts = d.files;
  }
  private _test: Artifacts;
  public set test(t: Artifacts) {
    this._test = t;
    this.testSas = t.sasToken;
    this.testArtifacts = t.files;
  }
  constructor(public dialog: DialogRef) {
    super(dialog);
  }
  ngOnInit() {
  }

}
