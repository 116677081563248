import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LinkedFeatureSystem } from '../common/display-list-modal/display-list-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private notify = new Subject<any>();
  private reverseAction = new Subject<any>();
  private updateFeatureListSubject = new Subject<any>();
  private selectMenuSubject = new Subject<any>();
  private reopenedVersionSubject = new Subject<any>();

  public takenInput = false;
  public srdoChanges = false;

  private _selectedFeature: LinkedFeatureSystem;
  get selectedFeature(): LinkedFeatureSystem {
    return this._selectedFeature;
  }
  set selectedFeature(value: LinkedFeatureSystem) {
    this._selectedFeature = value;
  }

  private _selectedSRDOCommand: any;
  get selectedSRDOCommand(): any {
    return this._selectedSRDOCommand;
  }
  set selectedSRDOCommand(value: any) {
    this._selectedSRDOCommand = value;
  }

  private _isReopened = false;
  get isReopened(): boolean {
    return this._isReopened;
  }
  set isReopened(value: boolean) {
    this._isReopened = value;
  }

  private _isStaged = false;
  get isStaged(): boolean {
    return this._isStaged;
  }
  set isStaged(value: boolean) {
    this._isStaged = value;
  }


  /**
   * Observable string streams
   */
  notifyObservable$ = this.notify.asObservable();

  constructor() { }

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  sendMessage(message: string) {
    this.notify.next({ text: message });
  }

  onMessage(): Observable<any> {
    return this.notify.asObservable();
  }

  onRequest(): Observable<boolean> {
    return this.reverseAction.asObservable();
  }

  reverseRequest() {
    this.reverseAction.next(true);
  }

  selectMenuObservable(): Observable<any> {
    return this.selectMenuSubject.asObservable();
  }

  selectMenuAction() {
    this.selectMenuSubject.next();
  }

  reopenedVersionObservable(): Observable<any> {
    return this.reopenedVersionSubject.asObservable();
  }

  reopenedVersionAction() {
    this.reopenedVersionSubject.next(this._isReopened);
  }

  updateFeatureListObservable(): Observable<boolean> {
    return this.updateFeatureListSubject.asObservable();
  }

  updateFeatureListAction() {
    this.updateFeatureListSubject.next(true)
  }


  public deployInstance = true;
  public isDeployedFlag = null;
}
