import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SystemListComponent } from './system-list/system-list.component';
import { SystemDataComponent } from './system-data/system-data.component';
import { SystemsRoutingModule } from './systems-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FeatureComponent } from './feature/feature.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CreateFeatureListingComponent } from './create-feature-listing/create-feature-listing.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { ArtifactsComponent } from './artifacts/artifacts.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { PopupModule } from '@progress/kendo-angular-popup';
import { CopySystemComponent } from './copy-system/copy-system.component';
import { InputsModule } from '@progress/kendo-angular-inputs';

@NgModule({
  declarations: [
    SystemListComponent,
    SystemDataComponent,
    FeatureComponent,
    MenuComponent,
    CreateFeatureListingComponent,
    ArtifactsComponent,
    CopySystemComponent,
    ],
  imports: [
    CommonModule,
    SystemsRoutingModule,
    NgbModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TreeViewModule,
    DropDownsModule,
    GridModule,
    DialogModule,
    InputsModule,
    PopupModule,
    NgxSpinnerModule,
    NgxSmartModalModule.forRoot(),
  ]
})
export class SystemsModule { }
