import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class UserAccess {

    public actions: Access;

    constructor(private userService: UserService) {
    }

    getUserClaims(headerData): Observable<boolean> {
        return new Observable<boolean>(observer => {            
            this.userService.getUsersClaims(headerData).subscribe((data) => {
                this.actions = data;
                if(data) {
                    observer.next(true);
                } else {
                    observer.next(false);
                }
                observer.complete();
            }, ((error) => {
                observer.next(false);
                observer.complete();
            }));
        });
    }

    getUserAccess(module: string, action: string, id: number): boolean {
        let state = false;
        // Not specific component/system
        if(this.actions === undefined)
          return false;
        let roles = this.actions.roles;
        let isfind = this.actions.roles.find(x => x.id == 1)
        if (isfind !== undefined) {
            state = true;
        }
        else if (module === "GlobalReadOnly") {
            let isGlobalReadOnly = this.actions.roles.find(x => x.id == 9);
            if (isGlobalReadOnly !== undefined) {
                state = true;
            }else{
                state = false;
            }
        }
        else if (module === "PhoneAdministrator") {
            let isPhoneAdmin = this.actions.roles.find(x => x.id == 6);
            if (isPhoneAdmin !== undefined) {
                state = true;
            }else{
                state = false;
            }
        }
        else if (module === "SystemOwner") {
            let isSystemOwner = this.actions.roles.find(x => x.id == 2);
            if (isSystemOwner !== undefined) {
                state = true;
            }else{
                state = false;
            }
        }
        else if (id == -1) {
            state = false;
        }
        else {
            switch (module) {
                case "systems":
                    let systems = this.actions.systems;
                    if (systems.length > 0) {
                        let obj = systems.find((o, i) => {
                            if (o.systemId == id) {
                                o.actions.find((item) => {
                                    if (item.actionName == action) {
                                        state = true;
                                    }
                                });
                            }
                        });
                    }
                    break;
                case "components":
                    let components = this.actions.components;
                    if (components.length > 0) {
                        let obj = components.find((o, i) => {
                            if (o.componentId == id) {
                                o.actions.find((item) => {
                                    if (item.actionName == action) {
                                        state = true;
                                    }
                                });
                            }
                        });
                    }
                    break;
                default:
                    break;
            }
        }
        return state;
    }
}


export interface Access {
    userId: number;
    userName: string;
    emailId: string;
    roles: UserRole[];
    components: ComponentAccess[];
    systems: SystemAccess[];
}

export interface UserRole {
    id: number;
    name: string;
}

export interface ComponentAccess {
    componentName: string;
    componentId: number;
    actions: Actions[]
}

export interface SystemAccess {
    systemName: string;
    systemId: number;
    actions: Actions[]
}

export interface Actions {
    action: string;
    actionName: string;
}


