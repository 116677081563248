import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { CommonFeature } from 'src/app/models/system/feature/feature.module';
import { CommonMenusFeaturesService } from 'src/app/services/common-menus-features/common-menus-features.service';
import { FeatureService } from 'src/app/services/system/feature.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { faRetweet, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  DropTargetEvent,
  DragTargetContainerDirective,
  DropTargetContainerDirective,
} from "@progress/kendo-angular-utils";

import { calculateDestinationIndex } from '../utils';
@Component({
  selector: "app-rearrange",
  templateUrl: "./rearrange.component.html",
  styleUrls: ["./rearrange.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RearrangeComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  faRetweet = faRetweet;
  faTimes = faTimes;
  @ViewChild("wrapper", { read: DragTargetContainerDirective })
  public dragTargetContainer: DragTargetContainerDirective;
  @ViewChild("wrapper", { read: DropTargetContainerDirective })
  public dropTargetContainer: DropTargetContainerDirective;
  @Input() recordsCount = 0;
  @Input() opened: boolean = false;
  @Input() title = "Rearrange feature list";
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() showLoadingEvent: EventEmitter<boolean> = new EventEmitter();
  gridData: any = [];
  @Input() refreshEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() feature: CommonFeature;
  public state: State = {
    skip: 0,
    take: 50,
  };
  data = [{}];
  private _dynamicWidth = 600;
  get dynamicWidth() {
    return this._dynamicWidth;
  }

  set dynamicWidth(width: number) {
    this._dynamicWidth = width;
  }
  listingsSubscription: Subscription;
/**
 * costructor
 * @param zone 
 * @param renderer 
 * @param featureService 
 * @param commonMenusFeaturesService 
 */
  constructor(
    private zone: NgZone,
    private renderer: Renderer2,
    private featureService: FeatureService,
    private commonMenusFeaturesService: CommonMenusFeaturesService,
    private spinner: NgxSpinnerService,
  ) {}

  /**
   * On changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    const { feature, ...otherchanges } = changes;
    if (feature && feature.currentValue) {
      this.fetchListings()
    }
  }



  /**
   * On Init
   */
  ngOnInit(): void {
    this.dynamicWidth = window.innerWidth - 360;
  }

  /**
   * Fetch listings
   */
  fetchListings() {
    this.spinner.show()
      this.listingsSubscription = this.commonMenusFeaturesService.fetchCommonListings(this.feature,
        this.recordsCount,
        1).subscribe((featureListings) => {
          this.spinner.hide()
          this.gridData = featureListings;
        }, error => this.spinner.hide());
  }

  /**
   * On destroy
   */
  public ngOnDestroy(): void {
    if(this.listingsSubscription) this.listingsSubscription.unsubscribe()
  }

  public trackByIndex(index: number, item: any): any {
    return index;
}

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.dynamicWidth = window.innerWidth - 360;
  }

  /**
   * 
   * @param status Close
   */
  public close(status: boolean) {
    // this.responseDesc = ''
    this.opened = false;
    this.closedEvent.emit(this.commonMenusFeaturesService.isReOrdered); // False indicates user clicked on close button
  }

  public dragData = ({ dragTarget }) => {
    return Number(dragTarget.getAttribute("data-kendo-grid-item-index"));
  };

  public onDrop(e: DropTargetEvent): void {
    const fromIndex = e.dragData;
    const destinationIndex = calculateDestinationIndex(e, 0, 0, 0);
    this.updateGridsData(fromIndex, destinationIndex);
    this.dragTargetContainer.notify();
    this.dropTargetContainer.notify();
  }

  private updateGridsData(fromIndex: number, toIndex: number) {
    const dataItem = this.gridData[fromIndex];
    const dataItemDest = this.gridData[toIndex]
    this.gridData.splice(fromIndex, 1);
    this.gridData.splice(toIndex, 0, dataItem);
    this.postReorderData(dataItem.id, dataItemDest.id)
  }

  postReorderData(sourceId: number, destinationId: number) {
    this.spinner.show()
    this.featureService
            .reOrderCommonFeatureListingPosition(sourceId, destinationId)
            .subscribe(
              (resData) => {
                this.commonMenusFeaturesService.isReOrdered = true
                this.spinner.hide()
                this.fetchListings()
              },
              (error) => {
                this.fetchListings()
                this.spinner.hide()
              }
    );
  }

  reOrder(e: any) {
    const dataItem = this.gridData[e.draggedRows[0].rowIndex];
    const dataItemDest = this.gridData[e.dropTargetRow.rowIndex]
    this.postReorderData(dataItem.id, dataItemDest.id)
  }
}

