import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ComponentListComponent } from "./component-list/component-list.component";
import { ComponentDataTextFileComponent } from "./component-data-text-file/component-data-text-file.component";
import { ComponentDataComponent } from "./component-data/component-data.component";
import { SrdoDataComponent } from "./srdo-data/srdo-data.component";
import { CommandResponseDataComponent } from "./command-response-data/command-response-data.component";
import { PagedetailsComponent } from "./pagedetails/pagedetails.component";
import { ResourcesComponent } from "./resources/resources.component";
import { CanDeactivateGuard } from "../services/can-deactivate-guard.service";
import { MsalGuard } from '@azure/msal-angular';
import { RolesResolverService } from "../global/roles-resolver.service";
import { CommonMenuComponent } from "./common-menu-features/common-menu/common-menu.component";
import { CommonFeaturesComponent } from "./common-features/common-features.component";
import { CommonMenuFeaturesComponent } from "./common-menu-features/common-menu-features.component";

const routes: Routes = [
  {
    path: "component-list",
    canActivate: [MsalGuard],
    // resolve: { roles: RolesResolverService },
    component: ComponentListComponent,
  },
  {
    path: "modal",
    canActivate: [MsalGuard],
    component: ComponentListComponent,
  },
  {
    path: "component-data",
    canActivate: [MsalGuard],
    component: ComponentDataComponent,
    children: [
      {
        path: "srdo/:id/:componentVersionId",
        canDeactivate: [CanDeactivateGuard],
        component: SrdoDataComponent
      },
      {
        path: "responsedata/:id/:componentVersionId",
        component: CommandResponseDataComponent,
      },
      {
        path: "datatextfile/:id/:componentVersionId",
        component: ComponentDataTextFileComponent,
      },
      {
        path: "page-details/:id/:componentVersionId",
        component: PagedetailsComponent,
      },
      {
        path: "resources/:id/:componentVersionId",
        component: ResourcesComponent,
      },
      {
        path: "system-templates/:id/:componentVersionId",
        component: CommonMenuFeaturesComponent
      },
      // {
      //   path: "common-features/:id/:componentVersionId",
      //   component: CommonFeaturesComponent
      // }
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class ComponentDataRoutingModule { }
