import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeItemDropEvent } from '@progress/kendo-angular-treeview';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonMenu } from 'src/app/models/component/component.data.model';
import { CommonMenusFeaturesService } from 'src/app/services/common-menus-features/common-menus-features.service';
import { MenuService } from 'src/app/services/system/menu.service';

@Component({
  selector: "app-rearrange-common-menu",
  templateUrl: "./rearrange-common-menu.component.html",
  styleUrls: ["./rearrange-common-menu.component.scss"],
})
export class RearrangeCommonMenuComponent {
  @Input() opened: boolean = false;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshEvent = new EventEmitter();
  @Input() data: CommonMenu[];
  errorMessage: string = ''

  constructor(private menuService: MenuService,  
    private spinner: NgxSpinnerService, 
    private commonMenusFeaturesService: CommonMenusFeaturesService) {

  }

  /*
   * Close button action
   * status - false -> Hides the modal
   */
  public close(status: boolean) {
    this.opened = false;
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

  menuDragDrop(event: string, args?: any): void {
    let destinationPosition: number = 0
    let currentPosition = 0
    if (event === "addItem") {
      if(args.destinationItem.parent == undefined) {
        this.errorMessage ="Adding menu as 'above' or 'below' to a root menu is not allowed";
        return;
      }
      if (args.dropPosition === undefined || args.dropPosition >= 0) {
        destinationPosition =
          args.destinationItem.item.dataItem.Dbid.toString();
        currentPosition = 0;
        if (args.dropPosition === 1 || args.dropPosition === 2) {
          currentPosition =
            args.destinationItem.item.dataItem.Dbid.toString();
          destinationPosition =
            args.destinationItem.parent.item.dataItem.Dbid.toString();
        }
      } else {
        currentPosition =
          args.destinationItem.item.dataItem.Dbid.toString();
        destinationPosition =
          args.destinationItem.parent.item.dataItem.Dbid.toString();
      }
      if(destinationPosition !== args.sourceItem.item.dataItem.Dbid.toString()) 
      {
        this.spinner.show()
        this.menuService
          .getCommonMenuReOrdering(
            args.sourceItem.item.dataItem.Dbid,
            destinationPosition,
            currentPosition).subscribe(
            (res) => {
              this.spinner.hide()
              if (res) {
                this.commonMenusFeaturesService.ToastMessage("Menu rearranged Successfully.", "bg-success");
                this.refreshEvent.emit()
              }
            },
            () => {
              this.spinner.hide();
            }
          );
      }
    }
  }
  public handleMenuDrop(event: TreeItemDropEvent): void {
    this.menuDragDrop("nodeDrop", event);
  }
}
