import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast/toast.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  showViewAccess = false;
  constructor(public router: Router,
     public toastService: ToastService,) { }

  ngOnInit() {
    
  }

  login() {
    this.router.navigate(['/login']);
  }

  showViewAccessAction() {
    this.showViewAccess = true
  }

  closeEventAction(status: boolean) {
    this.showViewAccess = false
    if(status === true) {
      this.ToastMessage("Request Sent Successfully. Please wait for 2 to 3 days to get the approval.", 'bg-success')
    }
  }

  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: 'Success'
    });
  }

}
