import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService, getServiceUrl } from 'src/app/global/global';
import { Region } from 'src/app/models/component/component.data.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private http: HttpClient) {
  }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  /*
   * Default Menu Master Data
   * param { optionanl } headerData
   * memberof DefaultMenu
   */
  getDefaultMenu() {
    return this.http.get(getServiceUrl(AppService.DefaultMenu), this.httpOptions);
  }
  /*
   * Menu security Master Data
   * param { optionanl } headerData
   * memberof GetSecurityLevel
   */
  getSecurityLevel(): Observable<any> {
    return this.http.get(getServiceUrl(AppService.GetSecurityLevel), this.httpOptions);
  }
  /*
   * All Default Menu Master Data
   * param { optionanl } headerData
   * memberof DefaultAll
   */
  getAllDefaultWidget() {
    return this.http.get(getServiceUrl(AppService.DefaultAll), this.httpOptions);
  }

  getAllRegions(): Observable<Region[]> {
    return this.http.get<Region[]>(getServiceUrl(AppService.GetRegions), this.httpOptions);
  }
}
