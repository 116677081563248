import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ComponentListModel, ComponentArtifacts, ComponentInfo, UserAndRole } from 'src/app/models/component/component.data.model';
import { getServiceUrl, AppService } from 'src/app/global/global';
import { ResourceFiles } from 'src/app/component-data/resources/resources.component';
import { LinkedFeatureSystem } from 'src/app/common/display-list-modal/display-list-modal.component';
@Injectable({
  providedIn: 'root'
})
export class ComponentListService {

  public componentData: ComponentInfo[] = [];
  public versionSelected: string = ''
  constructor(private http: HttpClient) {
  }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**
   * Component List Data
   * param {} headerData
   * returns {Observable<ComponentListModel>}
   * memberof ComponentListService
   */
  getComponentListData(headerData, name): Observable<ComponentListModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        pageSize: headerData.pageSize,
        pageIndex: headerData.pageIndex,
        sortorder: headerData.sortorder,
        sortcolumname: headerData.sortcolumname,
        componentname: name
      })
    };
    return this.http.get<ComponentListModel>(getServiceUrl(AppService.ComponentList), httpOptions);
  }

  fetchAssociatedUsers(componentId: number): Observable<UserAndRole[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'componentId': componentId.toString()
      })
    };
    return this.http.get<UserAndRole[]>(getServiceUrl(AppService.GetComponentAssociatedUsers), httpOptions);
  }


  /**
   * Delete Compoenet with id
   * param  Id
   * returns
   * memberof ComponentListService
   */
  deleteComponentListData(Id) {
    return this.http.delete(getServiceUrl(AppService.DeleteComponent, Id), this.httpOptions);
  }

  addComponent(data) {
    const body = JSON.stringify(data);
    return this.http.post(getServiceUrl(AppService.AddComponent), body, this.httpOptions);
  }

  editComponent(updateData, id) {
    const ComponentData =  updateData;
    ComponentData.ModifiedBy = 'test@gmail.com';
    ComponentData.id = id;
    const body = JSON.stringify(ComponentData);
    return this.http.put(getServiceUrl(AppService.UpdateComponent), body, this.httpOptions);
  }
  getComponentList(isAccessRequested: Boolean, isComponentLayer = false
    ): Observable<ComponentInfo[]> {
    let httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      isAccessRequested : isAccessRequested.toString(),
      isComponentLayer: isComponentLayer.toString()
      })
     };
    return this.http.get<ComponentInfo[]>(getServiceUrl(AppService.GetBaseVersionComponents), httpOptions);
  }

  testComponent(componentnumber): Observable<boolean> {
    const componentId = componentnumber.toString();
    this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      componentid : componentId
      })
     };
    return this.http.get<boolean>(getServiceUrl(AppService.Test), this.httpOptions);
  }

  stageComponent(componentnumber): Observable<boolean> {
    const componentId = componentnumber.toString();
    this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      componentId : componentId
      })
     };
    return this.http.post<boolean>(getServiceUrl(AppService.Stage), null, this.httpOptions);
  }


  deployComponent(componentnumber): Observable<boolean> {
    const componentId = componentnumber.toString();
    this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      componentid : componentId
      })
     };
    return this.http.get<boolean>(getServiceUrl(AppService.Deploy), this.httpOptions);
  }
  
  getComponentArtifacts(componentnumber): Observable<ComponentArtifacts> {
    const url = getServiceUrl(AppService.DownloadTestArtifacts, '');
    const componentid = componentnumber.toString();
    this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      componentid 
      })
     };
    return this.http.get<ComponentArtifacts>(getServiceUrl(AppService.DownloadTestArtifacts), this.httpOptions);
  }

  AddVersion(data) {
    const ComponentData =  data;
    ComponentData.createdBy = 'test@gmail.com';
    const body = JSON.stringify(ComponentData);
    return this.http.post(getServiceUrl(AppService.AddVersion), body, this.httpOptions);
  }

  RenameVersion(data) {
    const ComponentData =  data;
    ComponentData.modifiedBy = 'test@gmail.com';
    const body = JSON.stringify(ComponentData);
    return this.http.put(getServiceUrl(AppService.RenameVersion), body, this.httpOptions);
  }

  reOpenComponentVersion(data : any) : Observable<boolean>{
    const body = JSON.stringify(data);
    return this.http.post<boolean>(getServiceUrl(AppService.EnableRemodificationOfComponentData), body, this.httpOptions);
  }

  getversionlistbycomponent(componentnumber) : Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      componentId : componentnumber
      })
     };
    return this.http.get<any>(getServiceUrl(AppService.GetVersionListByComponent), this.httpOptions);
  }

  DeleteVersion(Id): Observable<any>  {
    return this.http.delete(getServiceUrl(AppService.DeleteVersion, Id), this.httpOptions);
  }

  getResources(componentnumber: number): Observable<ResourceFiles> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'componentId': componentnumber.toString()
      })
    };
    return this.http.get<ResourceFiles>(getServiceUrl(AppService.GetComponentResourceList), this.httpOptions);
  }

  saveResource(data: any, componentId: string, title: string): Observable<boolean> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'componentId': componentId.toString(),
        'Title': title
      })
    };
    return this.http.post<boolean>(getServiceUrl(AppService.SaveResource), data, this.httpOptions);
  }

  deleteResource(resourceId: number): Observable<boolean> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.delete<boolean>(getServiceUrl(AppService.DeleteResource, resourceId.toString()), this.httpOptions)
  }

  updateResource(data: any, resourceId: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'resourceId': resourceId.toString(),
      })
    };
    return this.http.put<boolean>(getServiceUrl(AppService.UpdateResource), data, this.httpOptions);
  }

  getLinkedFeatures(componentId: Number, dataId: Number, controlType: Number): Observable<LinkedFeatureSystem[]> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'componentId': componentId.toString(),
        'dataId': dataId.toString(),
        'controlType': controlType.toString()
      })
    };
    return this.http.get<LinkedFeatureSystem[]>(getServiceUrl(AppService.GetLinkedFeaturesList), this.httpOptions);
  }

}













