import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DataWidgetComponent } from './data-widget/data-widget.component';
import { MenuWidgetComponent } from './menu-widget/menu-widget.component';
import { WidgetComponent } from './widget/widget.component';
import { RolesResolverService } from '../global/roles-resolver.service';

const routes: Routes = [
  {path: 'widget', component: WidgetComponent, resolve: { roles: RolesResolverService },
    children: [
      {path: 'data-widget', component: DataWidgetComponent, pathMatch: 'full'},
      {path: 'menu-widget', component: MenuWidgetComponent, pathMatch: 'full'}
    ]
  }
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class WidgetRoutingModule { }
