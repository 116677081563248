import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SystemListComponent } from './system-list/system-list.component';
import { SystemDataComponent } from './system-data/system-data.component';
import { FeatureComponent } from './feature/feature.component';
import { MenuComponent } from './menu/menu.component';
import { ArtifactsComponent } from './artifacts/artifacts.component';
import { CopySystemComponent } from './copy-system/copy-system.component';

const routes: Routes = [
  {path: 'system-list', component: SystemListComponent},
  {path: 'system-data', component: SystemDataComponent,
    children: [
      {path: 'menu/:id', component: MenuComponent, pathMatch: 'full'},
      {path: 'feature/:id', component: FeatureComponent, pathMatch: 'full'},
    ]
  },
  {path: 'show-artifacts', component: ArtifactsComponent},
  {path: 'copy-system', component: CopySystemComponent}
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class SystemsRoutingModule { }
