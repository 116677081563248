
export const environment = {
  production: true,
  apiUrl: 'https://omusotieddpapi01.azurewebsites.net/api',
  redirectUrl: 'https://edd.otiselevator.com/login',
  version: 'NAA31903AAK-V01',
  msalConfig: {
    auth: {
      clientId: '77ca33d7-01a0-49b8-b133-5f5a7e862aca',
      authority: 'https://login.microsoftonline.com/',
      tenantId: '9c7d751b-b1a4-4c4a-9343-12b2814ae031'
    }
  },
};
