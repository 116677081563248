import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faTrash, faEdit, faPlus, faFileImport, faSitemap, faClone } from '@fortawesome/free-solid-svg-icons';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin, of } from 'rxjs';
import { CommonMenu, MenuDetailsById, MenuTitle } from 'src/app/models/component/component.data.model';
import { CommonFeature, CommonMenuModel } from 'src/app/models/system/feature/feature.module';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { MenuService } from 'src/app/services/system/menu.service';
import { WidgetService } from 'src/app/services/widget/widget.service';
import { AddEditCommonMenuComponent } from '../add-edit-common-menu/add-edit-common-menu.component';
import { catchError, map, switchMap, tap } from 'rxjs/Operators';
import { ComponentListService } from 'src/app/services/component/component-data.service';
import { SrdoDataService } from 'src/app/services/component/srdo-data.service';
import { SystemListService } from 'src/app/services/system/system-list.service';
import { CommonMenusFeaturesService } from 'src/app/services/common-menus-features/common-menus-features.service';

@Component({
  selector: "app-common-menu",
  templateUrl: "./common-menu.component.html",
  styleUrls: ["./common-menu.component.scss"],
})
export class CommonMenuComponent implements OnInit, AfterViewInit {
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faSitemap = faSitemap;
  faFileImport = faFileImport;
  faClone = faClone;
  menuImport: FormGroup;
  public opened = false;
  public commonMenuTitle = "";
  public commonMenuDesc = "";
  public menuTitles: MenuTitle[] = [];
  public defaultItem: MenuTitle = {
    name: "Select system template",
    description: '',
    id: null,
  };
  public commonMenuId: string = "1";
  public data: CommonMenu[];
  public selectedKeys: any[] = [0];
  public addtreeselectedKeys: any[] = [0];
  public expandedKeys = [0];
  public hasChildren = (item: any) => item.children && item.children.length > 0;
  public fetchChildren = (item: any) => of(item.children);
  componentVersionId: string;
  componentId: string;
  newMenuTitleId: number = -1;
  selectedCommonMenu: CommonMenuModel;
  addOrEditCommonMenuTitle = "";
  widgetList: any[] = [];
  securityList: any[] = [];
  selectedTitle: MenuTitle;
  public titleForm: FormGroup = new FormGroup({
    title: new FormControl({}, [Validators.required]),
    desc: new FormControl({}),
  });
  @ViewChild("child") addEditchild: AddEditCommonMenuComponent;
  refreshMenuBySelectedId = 0;
  showRootMenuOption = false;
  //Copy system menu modal
  public copyModalSelectedKeys: any[] = [0];
  public copyModalExpandedKeys: any[] = [];
  showCopySystemMenuModal = false;
  copyMenuAction = false;
  systemList = [];
  systemMenuData = [];
  parentRelation = [
    {
      id: 1,
      value: "As a Child",
    },
  ];
  sourceMenuSelectedId: number = -1;
  destMenuSelectedId: number = -1;
  heirarchySelected: number = 1;
  copybtnState = true;
  copyMenuSystemId : number = 0
  componentAccess = false
  destinationTitle = ""
  commonFeatures: CommonFeature[] = []
  featureListForAdd: CommonFeature[] = []
  commonTitleSub: Subscription;
  constructor(
    private spinner: NgxSpinnerService,
    private menuService: MenuService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private widgetService: WidgetService,
    private masterDataService: MasterDataService,
    private componentService: ComponentListService,
    private srdoDataService: SrdoDataService,
    private systemService: SystemListService,
    private commonMenusFeaturesService:CommonMenusFeaturesService
  ) {
    this.menuImport = this.formBuilder.group({
      menuFile: ["", Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.commonMenuId = "1";
    this.prefetchTheComponentData();
  }

  ngOnInit(): void {
    this.commonTitleSub = this.commonMenusFeaturesService.currentCommonTitle.subscribe((title: MenuTitle) => {
        this.componentAccess = this.commonMenusFeaturesService.componentAccess
        if(title) {
          this.componentId = this.route.snapshot.paramMap.get("id");
          this.componentVersionId = this.route.snapshot.paramMap.get("componentVersionId");
          this.selectedTitle = title
          this.fetchData()
        } else {
          this.selectedTitle = undefined;
          this.showRootMenuOption = false;
          this.selectedCommonMenu = undefined;
          this.data = undefined;
        }
    })
  }

  fetchData() {
    this.fetchCommonMenuData();
  }

 /**
  * Call back listener from add or edit common menu component 
  * @param $event 
  */
  refreshCommonMenuHierarchy($event) {
    this.refreshMenuBySelectedId = $event;
    this.fetchCommonMenuData($event);
  }

  /**
   * Common menu section
   * Fetch common menu data
   */
  fetchCommonMenuData(id?: number) {
    if (this.selectedTitle.id) {
      this.spinner.show();
      this.menuService
        .getCommonMenuHeirarchy(this.componentId, this.selectedTitle.id)
        .subscribe(
          (res) => {
            if (res != null && res.length > 0) {
              this.showRootMenuOption = false;
              this.data = res;
              this.expandedKeys = []
              this.expandNodes(res)
              let idToselect: number
              if(this.commonMenusFeaturesService.menuIdSelected) {
                idToselect = this.commonMenusFeaturesService.menuIdSelected
                this.commonMenusFeaturesService.menuIdSelected = undefined
              }
              else {
                idToselect =
                (id && id > 0)
                  ? this.refreshMenuBySelectedId
                  : this.data[0].id;
              }
              // this.menuValues[0].value = this.data[0].name
              this.selectedKeys = [idToselect];
              this.expandedKeys.push(idToselect)
              this.addtreeselectedKeys = [idToselect];
              this.fetchCommonMenuById(idToselect);
            } else {
              this.showRootMenuOption = true;
              this.featureListForAdd = this.commonMenusFeaturesService.commonFeatures.filter((item) => item.isMapped == false)
              this.data = undefined;
              this.selectedCommonMenu = undefined;
              this.spinner.hide();
            }
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }else {
      this.selectedCommonMenu = undefined
    }
  }

  /**
   * Fetch the data to show in the drop downs
   * @returns
   */
  prefetchTheComponentData() {
    this.spinner.show()
    let firstAPI = this.widgetService.getMenuWidgetList();
    let secondAPI = this.masterDataService.getSecurityLevel();
    forkJoin([firstAPI, secondAPI]).subscribe(
      (result) => {
        Object(result[1]).forEach((obj) => {
          this.renameKey(obj, "value", "name");
        });
        this.securityList = result[1];
        this.widgetList = result[0];
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  /**
   * Expand nodes
   * @param nodes 
   */
  expandNodes(nodes: CommonMenu[]) {
      nodes.forEach((item) => {
        this.expandedKeys.push(item.id)
        if(item.children && item.children.length > 0) {
          this.expandNodes(item.children)
        }
      })
  }

  /**
   * Common menu section
   *
   * @param itemArgs
   * @returns
   */
  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === -1;
  }

  /**
   * Fetch all data
   */
  fetchAllData() {
    // Start with the initial version list request
    let componentList = this.componentService.componentData;
    // this.menuValues[4].list = componentList;
    this.addEditchild.updateFromParents(componentList, 4, componentList.find((item) => item.id == this.selectedCommonMenu.baseComponentId));
    this.spinner.show();
    this.componentService
      .getversionlistbycomponent(
        this.selectedCommonMenu.baseComponentId.toString()
      )
      .pipe(
        // Find the desired version (or handle not found)
        map((versions) => {
          Object(versions).forEach((obj) => {
            this.renameKey(obj, "versionNumber", "name");
            this.renameKey(obj, "versionNumberId", "id");
          });
          const versionFound = versions.find(
            (item) => +item.id == this.selectedCommonMenu.versionComponentId
          );
          this.addEditchild.updateFromParents(versions, 5, versionFound);
          if (!versionFound) {
            // Handle not found case (throw error, log warning, etc.)
            throw new Error("Version not found");
          }
          return versionFound;
        }),
        // Fetch page data based on selected version:
        switchMap((versionFound) =>
          this.srdoDataService.getPageByComponentIdVersionNumber(
            this.selectedCommonMenu.baseComponentId.toString(),
            ""
          )
        ),
        // Find the desired page (or handle not found):
        map((pages) => {
          Object(pages).forEach((obj) => {
            this.renameKey(obj, "pageText", "name");
            this.renameKey(obj, "pageNumber", "id");
          });
          const pageFound = pages.find(
            (item) => +item.id == this.selectedCommonMenu.pageId
          );
          this.addEditchild.updateFromParents(pages, 6, pageFound);
          if (!pageFound) {
            // Handle not found case (throw error, log warning, etc.)
            throw new Error("Page not found");
          }
          return pageFound;
        }),
        // Fetch SRDO data based on selected version and page:
        switchMap((pageFound) =>
          this.srdoDataService.getSrdoByComponentIdVersionNumber(
            this.selectedCommonMenu.versionComponentId.toString(),
            "",
            this.selectedCommonMenu.pageId.toString()
          )
        ),
        // Find the desired SRDO (optional):
        map((srdoListData) => {
          const srdoFound = srdoListData.find(
            (item) => +item.id == this.selectedCommonMenu.srdoId
          );
          Object(srdoListData).forEach((obj) => {
            this.renameKey(obj, "srdoName", "name");
            // this.renameKey(obj, "srdoId", "id");
          });
          this.addEditchild.updateFromParents(srdoListData, 7, srdoFound);
          if (!srdoFound) {
            // Handle not found case (throw error, log warning, etc.)
            throw new Error("Srdo not found");
          }
          return srdoFound;
        }),
        // Handle errors at any point in the chain:
        catchError((error) => {
          this.spinner.hide();
          // Handle errors appropriately (log, display message, etc.)
          console.error("Error occurred:", error);
          // Optionally throw or return a default/error value
          throw error; // Example error propagation
        })
      )
      .subscribe(
        // Handle final data (SRDO or error)
        (data) => {
          // Use the loaded SRDO data or handle errors
          this.spinner.hide();
        },
        (error) => {
          // Handle errors propagated from catchError or subscription
          this.spinner.hide();
        }
      );
  }

  /**
   * Helper function
   * @param id
   * @returns  menutitle
   */
  findMenuWithId(id: number) {
    let menutitle = this.menuTitles.find((item) => item.id == id);
    if (menutitle) {
      return menutitle;
    } else {
      this.menuTitles[0];
    }
  }

  /**
   * Kendo tree view menu selection
   * @param event
   */
  public handleSelection(event: TreeItem): void {
    this.selectedKeys = [event.dataItem.id];
    this.addtreeselectedKeys = [event.dataItem.id];
    this.fetchCommonMenuById(event.dataItem.id);
  }

  /**
   * Get the menu selected data
   * @param menuId
   */
  fetchCommonMenuById(menuId: number) {
    this.spinner.show();
    let commonMenuDetails: MenuDetailsById = {
      componentId: this.componentId,
      titleId: this.selectedTitle.id.toString(),
      commonMenuId: menuId.toString(),
    };
    this.menuService.getCommonMenuById(commonMenuDetails).subscribe(
      (res) => {
        this.spinner.hide();
        if (res != null) {
          this.commonFeatures = this.commonMenusFeaturesService.commonFeatures.filter((feature) => {
            if(feature.isMapped && feature.menuId == res.menuId) {
              return true
            } else {
              return !feature.isMapped
            }
          });
          this.featureListForAdd = this.commonMenusFeaturesService.commonFeatures.filter((item) => item.isMapped == false)
          this.selectedCommonMenu = res;
          if (this.selectedCommonMenu.baseComponentId > 0) {
            setTimeout(() => {
              this.fetchAllData();
            }, 500);
          }
        } else {
          this.selectedCommonMenu = undefined;
        }
      },
      (error) => {
        this.selectedCommonMenu = undefined;
        this.spinner.hide();
      }
    );
  }

  /**
   * Helper function
   */
  resetandClose() {
    this.opened = false;
    this.commonMenuTitle = "";
    this.commonMenuDesc = "";
    this.titleForm.reset();
  }

  /**
   * Helper function
   */
  public open(): void {
    this.opened = true;
  }

  /**
   * Helper function
   */
  addCommonMenu() {
    this.opened = true;
  }

  /**
   * Show or hide spinner
   * @param isLoading 
   */
  handleLoadingChange(isLoading: boolean) {
    isLoading ? this.spinner.show() : this.spinner.hide();
  }

  /**
   * Show alert message
   * @param message 
   */
  showSuccessMessage(message: string) {
    this.commonMenusFeaturesService.ToastMessage(message, "bg-success");
  }

  addChildMenu($event) {
    // setTimeout(() => {
    //   let rootMenuDetails = {
    //     componentId: this.componentId,
    //     titleId: this.selectedTitle.id,
    //   };
    //   this.addEditchild.listenFromParent("Add", rootMenuDetails);
    // });
  }

  /**
   * Helper function
   * @param obj
   * @param oldKey
   * @param newKey
   */
  renameKey(obj: any, oldKey: string, newKey: string) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  /************  Copy system menu to common menu module *************/
  /**
   * Copy system menu event
   * @param $event
   */
  copySystemMenu($event) {
    this.copyMenuAction = true;
    this.destMenuSelectedId = this.selectedKeys[0]
    this.copyModalExpandedKeys = [...this.expandedKeys];
    this.copyModalSelectedKeys = [...this.selectedKeys];
    this.destinationTitle = "Destination System Template"
    if (
      this.systemService.systemList &&
      this.systemService.systemList.length > 0
    ) {
      this.systemList = this.systemService.systemList;
      this.showCopySystemMenuModal = true;
    } else {
      this.getSystemsList();
    }
  }

  /**
   * Close copy modal action listener
   * @param $event
   */
  closeCopyMenuModal($event) {
    this.sourceMenuSelectedId = -1;
    this.destMenuSelectedId = -1;
    this.showCopySystemMenuModal = false;
    this.copybtnState = true;
    this.copyModalSelectedKeys = [];
    this.systemMenuData = [];
  }

  /**
   * Get the system list to show in copy menu modal
   */
  getSystemsList() {
    this.spinner.show();
    this.systemService.getAllSystemList(true).subscribe(
      (data) => {
        this.spinner.hide();
        if (data) {
          this.systemList = data;
          this.systemService.systemList = data;
          this.showCopySystemMenuModal = true;;
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  refreshMenuHeirarchy(systemId: number) {
    this.spinner.show();
    this.systemMenuData = [];
    this.sourceMenuSelectedId = -1
    this.copyMenuSystemId = systemId
    this.checkCopyMenuDataValid()
    this.menuService.getMenuHeirarchy(systemId.toString()).subscribe(
      (res) => {
        if (res != null && res[0] != null) {
          this.systemMenuData = res;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  /**
   * Copy system menu to common menu
   */
  copySystemMenuToCommonMenu(copy: boolean) {
    this.spinner.show();
    let componentId, titleId, targetMenuId, systemId, sourceMenuId = 0
    if(this.selectedCommonMenu) {
       componentId = this.selectedCommonMenu.componentId;
       titleId = this.selectedCommonMenu.titleId;
       systemId = this.copyMenuSystemId;
       sourceMenuId = this.sourceMenuSelectedId;
       targetMenuId = this.destMenuSelectedId;
    } else {
      componentId = this.componentId;
      titleId = this.selectedTitle.id,
      systemId = this.copyMenuSystemId;
      sourceMenuId = this.sourceMenuSelectedId;
      targetMenuId = 0
    }

    this.menuService
      .copySystemMenuToCommonMenu(
        componentId,
        titleId,
        targetMenuId,
        systemId,
        sourceMenuId,
        copy
      )
      .subscribe(
        (res) => {
          if (res) {
            this.showCopySystemMenuModal = false
            this.copyMenuAction = false;
            this.copyModalSelectedKeys = [];
            this.systemMenuData = [];
            this.copybtnState = false;
            this.commonMenusFeaturesService.fetchCommonFeatures(this.commonMenusFeaturesService.componentId ,this.commonMenusFeaturesService.commonTitle.id.toString()).subscribe((features) => {
              if (features && features.length > 0) {
                this.commonMenusFeaturesService.commonFeatures = features 
                this.featureListForAdd = this.commonMenusFeaturesService.commonFeatures.filter((item) => item.isMapped == false)
              } else {
                this.commonMenusFeaturesService.commonFeatures = []
              }
              this.fetchCommonMenuData()
            }, error => {
              this.spinner.hide();
            })
            this.commonMenusFeaturesService.ToastMessage("Common menu copied successfully", "bg-success");
          }
          this.spinner.hide();
        },
        (error) => this.spinner.hide()
      );
  }

  /*
  Source menu selection callback
  */
  sourceMenuSelectionEvent(node: TreeItem) {
    this.sourceMenuSelectedId = node.dataItem.id;
    this.checkCopyMenuDataValid();
  }

  /*
  Destination menu selection callback
  */
  destMenuSelectionEvent(node: TreeItem) {
    this.destMenuSelectedId = node.dataItem.id;
    this.checkCopyMenuDataValid();
  }

  /*
  Validations to enable Copy button
  */
  checkCopyMenuDataValid() {
    if (this.sourceMenuSelectedId > -1 && this.destMenuSelectedId > -1) {
      this.copybtnState = false;
    } else {
      this.copybtnState = true;
    }
  }

  ngOnDestroy() {
      this.commonTitleSub.unsubscribe()
  }
}

