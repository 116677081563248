import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  faArrowDown,
  faArrowUp,
  faClone,
  faCopy,
  faEdit,
  faFileImport,
  faLink,
  faPlus,
  faSearch,
  faSort,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommandResponse } from 'src/app/models/component/command-response.model';
import { ToastService } from 'src/app/services/toast/toast.service';
import { CommandArgumentComponent } from '../../component-data/command-argument/command-argument.component';
import { CreateResponseArgumentComponent } from '../../component-data/create-response-argument/create-response-argument.component';
import { CommonService } from '../../services/common.service';
import { CommandResponseService } from '../../services/component/command-response.service';
import { ComponentListService } from '../../services/component/component-data.service';

import {
  DataStateChangeEvent,
  GridComponent,
  GridDataResult
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DisplayItem, DisplayModel } from 'src/app/common/copy-modal/copy-modal.component';
import { LinkedFeatureSystem } from 'src/app/common/display-list-modal/display-list-modal.component';
import { SrdoDataService } from 'src/app/services/component/srdo-data.service';
import { DialogboxService } from 'src/app/services/confirmation-dialog/dialogbox.service';
import { UserAccess } from '../../global/user-access';
import { join } from 'path';
import { DataType } from 'src/app/models/component/srdo-data.model';
import { error } from 'console';


@Component({
  selector: 'app-command-response-data',
  templateUrl: './command-response-data.component.html',
  styleUrls: ['./command-response-data.component.scss']
})
export class CommandResponseDataComponent implements OnInit {

  commandForm: UntypedFormGroup;
  addCommandForm: UntypedFormGroup;
  editCommandForm: UntypedFormGroup;
  commandResponseImportFile: UntypedFormGroup;
  faClone = faClone;
  faSort = faSort;
  selectedComponentCommandData = [];
  commandsList = []
  list = [];
  faSearch = faSearch;
  faFileImport = faFileImport;
  faTimes = faTimes;
  faPlus = faPlus;
  faTrash = faTrash;
  faEdit = faEdit;
  faLink = faLink;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faCopy = faCopy;
  commandId: number = null;
  commandName: string = ''
  commandData: CommandResponse[] = [];
  submitted = false;
  loading: boolean;
  public gridView: GridDataResult;
  @Input() public category: any;
  componentVersionId : any;
  public view: GridDataResult;
  public skip = 0;
  public sort: Array<SortDescriptor> = [];
  public pageSize = 5;
  public CAskip = 0;
  public CAsort: Array<SortDescriptor> = [];
  public CApageSize = 5;
  componentId: string;
  getCommandId: number;
  text: string;
  closeResult: string;
  // tslint:disable-next-line: ban-types
  private data: Object[];
  private masterData: any;
  // For Angular 8
  // @ViewChild(GridComponent, { static: true })
  // public grid: GridComponent;
  DeleteCommand = false;
  EditCommand = false;
  AddCommand = false;
  ImportCommandAccess = false;
  AddCommandResponseAccess = false;
  AddCommandArgumentAccess = false;
  linkedFeatures: LinkedFeatureSystem[];
  showLinkModal = false;
  showMenu = false;
  selectedFilter = FilterType.Name;
  sortyByItems = [FilterType.Name, FilterType.Subtype, FilterType.CreatedDate, FilterType.ModifiedDate]
  ascending = true
  copyTitle = ''
  isCommand = true //Placeholder to identify Command Copy or Response Code Copy
  @ViewChild(GridComponent, { static: true}) grid: GridComponent;

  formTemplate = [
    {
      type: 'text',
      label: 'Display Text',
      formName: 'name',
      min: 1
    },
    {
      type: 'text',
      label: 'Command',
      formName: 'command',
      min: 1,
      max: 5
    },
    {
      type: 'text',
      label: 'Sub Type',
      formName: 'subType',
      min: 1,
      max: 5
    },
    {
      type: 'textarea',
      label: 'Description',
      formName: 'description',
      value: ''
    }
  ];

  formValidation = {
    name: ['', Validators.required],
    command : ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    subType : ['',  [Validators.required, Validators.pattern('^[0-9]*$')]],
    description : ['', Validators.required]
    };

  headerData = { pageSize: 6, pageIndex: '1', sortorder: 'Ascending', sortcolumname: 'Id' };

  copyCommandFromOtherComp = false;
  copyCommandError: string = ''
  componentDetails = {};
  copyButtonState: boolean = true;
  copyCommandDetails: DisplayItem[] = [];
  toolTips: DataType[] = []

  constructor(private commandResponseService: CommandResponseService,
              private router: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              public toastService: ToastService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              public commonService: CommonService,
              private confirmationDialogService: DialogboxService,
    private useraccess: UserAccess,
    private componentService: ComponentListService,
    private srdoDataService: SrdoDataService
  ) { }

  ngOnInit() {
     // Bind directly to the service as it is a Subject
    this.loading = false;
     // Fetch the data with the initial state
    this.commandResponseImportFile = this.formBuilder.group({
      formFile: ['', Validators.required]
    });

    // command form validation
    this.commandForm = this.formBuilder.group({

      displayText: [null, Validators.required],
      command: [{value: null, disabled: true }, Validators.required],
      subtype: [null, Validators.required],
      description: [null, Validators.required],
      actionName: [null, Validators.maxLength(50)],
    });

    this.addCommandForm = this.formBuilder.group({

      addDisplayText: [null, Validators.required],
      addCommand: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      addSubtype: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      addDescription: [null, Validators.required],
      addActionName: [null, Validators.maxLength(50)],
    });

    this.editCommandForm = this.formBuilder.group({
      editDisplayText: [null, Validators.required],
      editCommand: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      editSubtype: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      editDescription: [null, Validators.required],
      editActionName: [null, Validators.maxLength(50)],
    });
    this.router.paramMap.subscribe(params => {
      this.loading = true;
      if (this.router.snapshot.paramMap.get('id') != null) {
        const componentVersionId = this.router.snapshot.paramMap.get('componentVersionId');
        this.componentVersionId = componentVersionId;
        this.getCommandListData(componentVersionId)
      }
    });
    this.fetchToolTips()
  }

  getCommandListData(componentVersionId: string) {
    this.spinner.show()
    this.commandResponseService.getCommandListData(componentVersionId).subscribe((listRes) => {
      this.spinner.hide()
      this.commandForm.reset();
      this.selectedFilter = FilterType.Name
      this.ascending = true
      this.selectedComponentCommandData = listRes;
      this.commandsList = listRes
      if (this.selectedComponentCommandData.length > 0) {
        this.commandId = this.commonService.selectedSRDOCommand ? this.fetchCommandIndex() : this.selectedComponentCommandData[0].id;
        if (this.commandId != null) {
          this.submitted = false;
          this.commandForm.reset();
          this.getComponentList(this.commandId);
          if(this.text && this.text.length > 0) {
            let searchText = this.text
            this.selectedComponentCommandData = listRes.filter((item) => JSON.stringify(item.name).toLowerCase().includes(this.text.toLowerCase()));
            if (this.selectedComponentCommandData && this.selectedComponentCommandData.length > 0) {
              this.onCommandSelect(this.selectedComponentCommandData[0].id);
            } else {
              this.commandId = null;
              return
            }
          } else {
            this.loading = false
            this.onCommandSelect(this.commandId);
          }
        }
      } else {
        this.loading = false
        this.removeAllData();
      }
    },(error) => {
      this.loading = false
      this.spinner.hide()
    });
  }

  fetchToolTips() {
    this.spinner.show()
    this.commandResponseService.fetchToolTips().subscribe((data)=> {
        if(data.length > 0) {
          this.toolTips = data
        }
    }, ((error) => {
      this.spinner.hide();
    }));
  }

  /* 
  Find index of the selected CR
  */
  fetchCommandIndex() {
    let index = this.selectedComponentCommandData.findIndex(item => item.name === this.commonService.selectedSRDOCommand.linkName)
    if(index !== -1) {
      this.text = this.selectedComponentCommandData[index].name
    }
    return index === -1 ? this.selectedComponentCommandData[0].id : this.selectedComponentCommandData[index].id
  }

  getComponentList(commandId) {
    this.commandResponseService.getCommandResponseGridDataService(
      this.router.snapshot.paramMap.get('componentVersionId'), commandId,
      this.headerData).subscribe((data) => {
        this.componentId = this.router.snapshot.paramMap.get('componentVersionId');
        this.getCommandId = commandId;
        this.masterData = data;
        this.setFormData(data)
        this.loadDataTable();
        this.loadCATableData();
        this.loading = false
      },
        ((error) => {
          this.spinner.hide();
          this.loading = false
        }));
  }
  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.commandResponseImportFile.get('formFile').setValue(file);
      this.onSubmit();
    }
  }

  setFormData(data) {
    const commandFormData = {
      command: data.command,
      displayText: data.displayText,
      subtype: data.commandSubType,
      description: data.description,
      actionName: data.actionName
    };
    const editCommandFormData = {
      editCommand: data.command,
      editDisplayText: data.displayText,
      editSubtype: data.commandSubType,
      editDescription: data.description,
      editActionName: data.actionName,
    };
    this.commandForm.patchValue(commandFormData);
    this.editCommandForm.patchValue(editCommandFormData);
  }

  onCommandFormSubmit() {
    this.submitted = true;
    if (this.editCommandForm.invalid) {
      return;
    }
    this.spinner.show();
    this.modalService.dismissAll();
    // display form values on success
    const editCommandData = {
      id: this.commandId,
      componentId: this.router.snapshot.paramMap.get('componentVersionId'),
      command: this.commandForm.get('command').getRawValue(),
      name: this.commandForm.get('displayText').value,
      subtype: this.commandForm.get('subtype').value,
      description: this.commandForm.get('description').value,
      actionName: this.commandForm.get('actionName').value,
      modifiedBy : 'test@gmail.com'
    };
    this.commandResponseService.editCommandData(editCommandData).subscribe((res) => {
      this.spinner.show();
      if (res === true) {
        this.commandResponseService.getCommandListData(this.router.snapshot.paramMap.get('componentVersionId')).subscribe((listRes) => {
          this.editCommandForm.reset();
          this.selectedComponentCommandData = listRes;
          this.getComponentList(this.commandId);
          this.onCommandSelect(this.commandId);
          this.ToastMessage('Command Updated Successfully.', 'bg-success');
          this.spinner.hide();
        }, ((error) => {
          this.spinner.hide();
        }));
      } else {
        this.spinner.hide();
      }
    }, (() => {
      this.spinner.hide();
    }));
  }
  onSubmit() {
    const formData = new FormData();
    this.spinner.show();
    formData.append('formFile', this.commandResponseImportFile.get('formFile').value);
    this.commandResponseService.commandResponseFileUploadService(formData, this.router.snapshot.paramMap.get('componentVersionId'))
    .subscribe(
      (res) => {
        if (res === true) {
          this.commandResponseService.getCommandListData(this.router.snapshot.paramMap.get('componentVersionId')).subscribe((listRes) => {
            this.spinner.hide();
            this.ToastMessage('Command imported successfully', 'bg-success');
            this.selectedComponentCommandData = listRes;
            if (this.selectedComponentCommandData.length > 0) {
              this.commandId = this.selectedComponentCommandData[0].id;
              if (this.commandId != null) {
                this.onCommandSelect(this.commandId);
              }
            }
          }, () => {
            this.spinner.hide();
          });
        } else {
          this.spinner.hide();
        }
      }, (() => {
        this.spinner.hide();
      }));
  }
  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: 'Success'
    });
  }
  onCommandSelect(commandId) {
    if (commandId !== null) {
      this.spinner.show();
      this.editCommandForm.reset();
      this.CAskip = 0
      this.skip = 0
      this.commandResponseService.getCommandResponseDataService(
        this.router.snapshot.paramMap.get('componentVersionId'), commandId).subscribe((listRes) => {
          this.checkAccess(CommandDataActions.AddCommand, Number(this.router.snapshot.paramMap.get('id')));
          this.checkAccess(CommandDataActions.UpdateCommand, Number(this.router.snapshot.paramMap.get('id')));
          this.checkAccess(CommandDataActions.DeleteCommand, Number(this.router.snapshot.paramMap.get('id')));
          this.checkAccess(CommandDataActions.ImportCommandResponseData, Number(this.router.snapshot.paramMap.get('id')));
          this.checkAccess(CommandDataActions.AddCommandArgument, Number(this.router.snapshot.paramMap.get('id')));
          this.checkAccess(CommandDataActions.AddCommandResponse, Number(this.router.snapshot.paramMap.get('id')));
          const commandFormData = {
            command: listRes.command,
            displayText: listRes.displayText,
            subtype: listRes.commandSubType,
            description: listRes.description,
            actionName: listRes.actionName,
          };
          const editCommandFormData = {
            editCommand: listRes.command,
            editDisplayText: listRes.displayText,
            editSubtype: listRes.commandSubType,
            editDescription: listRes.description,
            editActionName: listRes.actionName,
          };
          this.commandId = commandId;
          this.commandName = listRes.displayText;
          this.commandForm.patchValue(commandFormData);
          this.editCommandForm.patchValue(editCommandFormData);
          this.getComponentList(commandId);
          setTimeout(() => {
            this.spinner.hide();
          }, 1000)
        }, () => {
          this.spinner.hide();
        });
    }
  }
  onPageChanged(pageData: { pageIndex: number }) {
    this.headerData.pageIndex = pageData.pageIndex.toString();
    this.getComponentList(this.commandId);
  }
  clearSrdoForm() {
    this.commandForm.reset();
    this.submitted = false;
  }
  openResponseArgument() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'xl'
    };
    const modalRef = this.modalService.open(CreateResponseArgumentComponent, ngbModalOptions);
    modalRef.componentInstance.title = 'Add ';
    modalRef.componentInstance.commandId = this.commandId;
    modalRef.componentInstance.responseCodes = this.masterData.response
    modalRef.componentInstance.toolTips = this.toolTips
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.commandResponseService.addResponseArgument(result, this.commandId).subscribe((res) => {
        if (res !== null) {
          this.onCommandSelect(this.commandId);
          modalRef.close();
          this.ToastMessage('Response Argument Created Successfully.', 'bg-success');
          this.spinner.hide();
        }
      }, (error) => {
        this.spinner.hide();
      });
    });
  }
  OpenEditResponseArgumnet(data) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg'
    };
    const modalRef = this.modalService.open(CreateResponseArgumentComponent, ngbModalOptions);
    modalRef.componentInstance.title = 'Edit ';
    modalRef.componentInstance.editId = data.id;
    modalRef.componentInstance.responseCodes = this.masterData.response
    modalRef.componentInstance.editData = data;
    modalRef.componentInstance.toolTips = this.toolTips
    modalRef.componentInstance.isReopened = this.commonService.isReopened
    modalRef.componentInstance.commandId = this.commandId;
    modalRef.componentInstance.submitFormData.subscribe((result) => {
      this.spinner.show();
      this.commandResponseService.updateResponseArgument(result,data.id).subscribe((res) => {
        if (res !== null) {
          this.onCommandSelect(this.commandId);
          modalRef.close();
          this.ToastMessage('Response Argument Updated Successfully.', 'bg-success');
          this.spinner.hide();
        }
      }, (error) => {
        this.spinner.hide();
      });
    });
  }
  searchClear() {
    if(this.text.length > 0) {
      this.text = null;
      this.selectedComponentCommandData = this.commandsList
      if(this.selectedComponentCommandData && this.selectedComponentCommandData.length > 0) {
        this.commandId = this.selectedComponentCommandData[0].id
        this.onCommandSelect(this.selectedComponentCommandData[0].id)
      }
    }
    this.text = null
  }
  get f() { return this.addCommandForm.controls; }
  get edit() { return this.editCommandForm.controls; }
  onAddCommandFormSubmit() {
    this.submitted = true;
    if (this.addCommandForm.invalid) {
      return false;
    }
    this.spinner.show();
    this.modalService.dismissAll();
    const addMenuData = {
      name: this.addCommandForm.get('addDisplayText').value,
      command: this.addCommandForm.get('addCommand').value,
      subType: this.addCommandForm.get('addSubtype').value,
      description: this.addCommandForm.get('addDescription').value,
      actionName: this.addCommandForm.get('addActionName').value,
      createdBy: 'test@gmail.com'
    };
    this.commandResponseService.addCommandData(addMenuData, this.router.snapshot.paramMap.get('componentVersionId')).subscribe((res) => {
        this.ToastMessage('Command Created Successfully.', 'bg-success');
        this.commandResponseService.getCommandListData(this.router.snapshot.paramMap.get('componentVersionId')).subscribe((listRes) => {
          this.commandForm.reset();
          this.selectedComponentCommandData = listRes;
          this.getComponentList(res);
          this.onCommandSelect(res);
          this.spinner.hide();
        });
    }, ((error) => {
      this.spinner.hide();
    }));
  }
  removeCommand() {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
      .then((confirmed) => {
        if (confirmed === true) {
          this.commandResponseService.removeCommandData(this.commandId).subscribe((res) => {
            this.ToastMessage('Command Deleted Successfully.', 'bg-success');
            this.commandResponseService.getCommandListData(this.router.snapshot.paramMap.get('componentVersionId')).subscribe((listRes) => {
              this.commandForm.reset();
              this.selectedComponentCommandData = listRes;
              if (listRes.length > 0) {
                this.commandId = this.selectedComponentCommandData[0].id;
                this.getComponentList(this.commandId);
                this.onCommandSelect(this.commandId);
              }else{
                this.removeAllData();
              }
              this.spinner.hide();
            }, ((error) => {
              this.spinner.hide();
            }));
          });
        } else {
          this.spinner.hide();
        }
      });
  }

  removeAllData(){
    this.commandId = null;
    this.DeleteCommand  = false;
    this.EditCommand = false;
    this.AddCommand= false;
    this.ImportCommandAccess = false;
    this.AddCommandArgumentAccess = false;
    this.AddCommandResponseAccess = false;
    this.view = {
      data: [],
      total: 0
    };
    this.gridView = {
      data: [],
      total: 0
    };
    this.checkAccess(CommandDataActions.AddCommand, Number(this.router.snapshot.paramMap.get('id')));
    this.checkAccess(CommandDataActions.ImportCommandResponseData, Number(this.router.snapshot.paramMap.get('id')));    
  }

  openMenuModel(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg',
      ariaLabelledBy: 'modal-basic-title'
    };
    this.modalService.open(content, ngbModalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      const editCommandFormData = {
        editCommand: this.commandForm.value.command,
        editDisplayText: this.commandForm.value.displayText,
        editSubtype: this.commandForm.value.subtype,
        editDescription: this.commandForm.value.description,
        editActionName: this.commandForm.value.actionName,
      };
      this.editCommandForm.patchValue(editCommandFormData);
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openCommandModel(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg',
      ariaLabelledBy: 'modal-basic-title'
    };
    this.addCommandForm.reset();
    this.getNextSubType(this.router.snapshot.paramMap.get('componentVersionId'));
    this.addCommandForm.patchValue({ addCommand: 2 }); 
    this.modalService.open(content, ngbModalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getNextSubType(componentId) {
    let nextSubTypeId = null;
    this.spinner.show();
    this.commandResponseService.getNextCommandSubType(componentId).subscribe((data) => {
      if (data !== null || data !== '') {
        nextSubTypeId = data;
      }
      this.addCommandForm.patchValue({ addSubtype: nextSubTypeId });
      this.spinner.hide();
    }, (() => {
      this.spinner.hide();
    }));
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  clearModelAddForm() {
    this.addCommandForm.reset();
    this.addCommandForm.patchValue({ addCommand: 2 });
    this.submitted = false;
  }
  clearModeleditForm() {
    this.editCommandForm.reset();
    this.editCommandForm.patchValue({ editCommand: 2 });
    this.submitted = false;
  }
  openCommandArgument() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg'
    };
    const modalRef = this.modalService.open(CommandArgumentComponent, ngbModalOptions);
    modalRef.componentInstance.title = 'Add ';
    modalRef.componentInstance.commandId = this.commandId;
    modalRef.componentInstance.commandArgumentFormData.subscribe((result) => {
      this.spinner.show();
      this.commandResponseService.AddCommandArgument(result, this.commandId).subscribe((data) => {
          this.ToastMessage('Command Argument Successfully Created.', 'bg-success');
          this.getComponentList(this.commandId);
          this.spinner.hide();
        }, ((error) => {
          this.spinner.hide();
        }));
    });
  }
  public removeResponseHandler({ sender, dataItem }) {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
    .then((confirmed) => {
      if (confirmed === true) {
        this.spinner.show();
        this.commandResponseService.RemoveResponseData(dataItem.id).subscribe((res) => {
        if (res === true) {
          this.onCommandSelect(this.commandId);
          this.ToastMessage('Response deleted Successfully.', 'bg-success');
          this.spinner.hide();
        } else {
           this.ToastMessage('HTTP ERROR....', 'bg-danger');
           this.spinner.hide();
        }
        }, (error) => {
          this.spinner.hide();
        });
        sender.cancelCell();
      }
    });
  }

  public removeCommandArgumentHandler({ sender, dataItem}) {
    this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
    .then((confirmed) => {
      if (confirmed === true) {
        this.spinner.show();
        this.commandResponseService.DeleteCommandArgument(dataItem.id).subscribe((res) => {
        if (res === true) {
          this.onCommandSelect(this.commandId);
          this.ToastMessage('Command deleted Successfully.', 'bg-success');
          this.spinner.hide();
        } else {
           this.ToastMessage('HTTP ERROR....', 'bg-danger');
           this.spinner.hide();
        }
        }, (error) => {
          this.spinner.hide();
        });
        sender.cancelCell();
      }
    });
  }
  public editCommandArgument(data) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size : 'lg'
    };
    const modalRef = this.modalService.open(CommandArgumentComponent, ngbModalOptions);
    modalRef.componentInstance.title = 'Edit ';
    modalRef.componentInstance.commandId = this.commandId;
    modalRef.componentInstance.editCommandArgumentData = data;
    modalRef.componentInstance.isReopened = this.commonService.isReopened;
    modalRef.componentInstance.commandArgumentFormData.subscribe((result) => {
      this.spinner.show();
      this.commandResponseService.UpdateCommandArgument(result[0]).subscribe((data) => {
          this.ToastMessage('Command Argument Successfully Updated.', 'bg-success');
          this.getComponentList(this.commandId);
          this.spinner.hide();
        }, ((error) => {
          this.spinner.hide();
        }));
    });
  }

  showResArgDetails(dataItem: any, index: number): boolean {
    if (dataItem.responseArgumentRecordCount > 0) {
      return true;
    } else {
      return false;
    }
 }
showResArgEnumDetails(dataItem: any, index: number): boolean {
  if (dataItem.dataType.name.includes('Enum') || dataItem.dataType.name.includes('Bitm')) {
    return true;
  } else {
    return false;
  }
 }
showResArgBitEnumDetails(dataItem: any, index: number): boolean {
  if (dataItem.dataTypeId === 1) {
    return true;
  } else {
    return false;
  }
 }
showEnumGrid(dataType) {
  if (dataType.name.includes('Enum') ) {
    return true;
  } else {
    return false;
  }
}

showBitmaskGrid(dataType) {
  if (dataType.name.includes('Bitm') ) {
    return true;
  } else {
    return false;
  }
}
public removeResponseArgHandler({ sender, dataItem }) {
  this.confirmationDialogService.confirm('Please confirm..', 'Are you sure you want to delete this item?')
  .then((confirmed) => {
    if (confirmed === true) {
      this.spinner.show();
      this.commandResponseService.RemoveResponseArgumentData(dataItem.id).subscribe((res) => {
      if (res === true) {
        this.onCommandSelect(this.commandId);
        this.ToastMessage('Response argument deleted Successfully.', 'bg-success');
        this.spinner.hide();
      } else {
         this.ToastMessage('HTTP ERROR....', 'bg-danger');
      }
      }, (error) => {
        this.spinner.hide();
      });
      sender.cancelCell();
    }
  });
}
public pageChange({ skip, take, sort }: DataStateChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.sort = sort;
    this.loadDataTable();
}

public pageCAChange({ skip, take, sort }: DataStateChangeEvent): void {
  this.CAskip = skip;
  this.CApageSize = take;
  this.CAsort = sort;
  this.loadCATableData();
}
private loadCATableData(): void {
  this.gridView = {
      data: this.masterData.commandArguments.slice(this.CAskip, this.CAskip + this.CApageSize),
      total: this.masterData.commandArgumentRecordCount
  };
}

private loadDataTable(): void {
  this.view = {
      data: this.masterData.response,
      total: this.masterData.responseRecordCount
  };
}

copyToClipBoard() {
  let data = this.masterData.response.map((response) => 
     `${response.responseCode}     ${response.displayText}    ${response.description}     ${response.responseCategory.name}`
  )
  this.ToastMessage('Data copied to clipboard successfully.', 'bg-success');
  navigator.clipboard.writeText(data.join('\n'))
}

  public showNonPrimitiveDetails(dataItem: any, index: number): boolean {
    if (dataItem.dataTypeName.toLocaleLowerCase().includes('enum') === true) {
      return true;
    }
    if (dataItem.dataTypeName.toLocaleLowerCase().includes('bitmask') === true) {
      return true;
    }
    if (dataItem.dataTypeName.toLocaleLowerCase().includes('command') === true) {
      return true;
    }
    return false;
  }
  public showDataDetails(dataItem: any): boolean {
    if (dataItem.dataTypeName.includes('Bitmask') === true) {
      return true;
    }
    return false;
  }
  public showEnumDataDetails(dataItem: any): boolean {
      if (dataItem.dataTypeName.includes('Enum') === true || dataItem.dataTypeName.includes('Command') === true) {
        return true;
      }
      return false;
  }
  public checkAccess(action: string, dataItem: number = -1) {
    switch(action) {
      case CommandDataActions.DeleteCommand:
        this.DeleteCommand = this.useraccess.getUserAccess('components', action, dataItem);
        break;
      case CommandDataActions.UpdateCommand:
        this.EditCommand = this.useraccess.getUserAccess('components', action, dataItem);
        break;
      case CommandDataActions.AddCommand:
        this.AddCommand = this.useraccess.getUserAccess('components', action, dataItem );
        break;
      case CommandDataActions.ImportCommandResponseData:
        this.ImportCommandAccess = this.useraccess.getUserAccess('components', action, dataItem );
        break;
      case CommandDataActions.AddCommandArgument:
        this.AddCommandArgumentAccess = this.useraccess.getUserAccess('components', action, dataItem );
        break;
      case CommandDataActions.AddCommandResponse:
        this.AddCommandResponseAccess = this.useraccess.getUserAccess('components', action, dataItem );
        break;
      default:
        break;
    }
  }
  public checkAccessForItem(action: string, dataItem: any = undefined): Boolean {
    var state = this.useraccess.getUserAccess('components',
                action, dataItem !== undefined ? dataItem.id : -1);
    return state;
  }

  async getComponentDetail(component) {
    const compFound = this.componentService.componentData.find((data) => {
      return data.id === Number(component);
    });
    return compFound;
  }


  public async openCopyCommandModal(isCommand: boolean) {
    //Model preparation
    // this.selectedSRDODetails.componentName = "Global"
    this.isCommand = isCommand
    this.copyCommandError = ''
    this.copyCommandDetails = []
    this.copyTitle = isCommand ? 'Copy Command' : 'Copy Response Code'
    let compDetails = await this.getComponentDetail(Number(this.router.snapshot.paramMap.get('id')));
    let versionName = this.componentService.versionSelected
    // let item = this.pageData.find((item) => item.pageNumber == this.selectedPage)
    let commandName = this.selectedComponentCommandData.find(item => item.id == this.commandId)?.displayText
    this.componentDetails = { "Name": compDetails.name, "Version": versionName, "Command": commandName }
    this.copyCommandDetails.push({
      title: "Component", values: this.componentService.componentData,
      disabled: false, selectedValue: { id: '', name: 'Please select source component' }
    });
    this.copyCommandDetails.push({ title: "Version", values: [], disabled: true, selectedValue: { id: '', name: '' } })
    this.copyCommandDetails.push({ title: "Command", values: [], disabled: true, selectedValue: { id: '', name: '' } })
    if(!isCommand) {
    this.copyCommandDetails.push({ title: "Code", values: [], disabled: true, selectedValue: { id: '', name: '' } })
    }
    this.copyCommandFromOtherComp = true
  }

  public closeCommandSRDO(state: boolean) {
    if (this.copyButtonState === false && state === true) {
      this.spinner.show();
      if(this.isCommand) {
        this.commandResponseService.copyCommandFromOtherComp(this.copyCommandDetails[2].selectedValue.id.toString(), this.componentVersionId).subscribe(res => {
          this.spinner.hide();
          if (res) {
            this.copyCommandFromOtherComp = false;
            this.ToastMessage('Command copied successfully.', 'bg-success');
            this.commandResponseService.getCommandListData(this.router.snapshot.paramMap.get('componentVersionId')).subscribe((listRes) => {
              this.commandForm.reset();
              this.selectedComponentCommandData = listRes;
              this.commandId = res
              if (listRes.length > 0) {
                this.onCommandSelect(this.commandId);
              }
            });
          } else {
            this.copyCommandError = 'Command copy failed.';
          }
        }, error => {
          this.copyCommandError = error;
          this.spinner.hide();
        })
      } else {
        const copyResponseCode = {
          sourceCommandDbId: this.copyCommandDetails[2].selectedValue.id,
          sourceComponentId: this.copyCommandDetails[1].selectedValue.id,
          sourceResponseId : this.copyCommandDetails[3].selectedValue.id,
          targetComponentId: this.componentId,
          targetCommandId : this.commandId
        };
        this.commandResponseService.copyResponseFromOtherComp(+copyResponseCode.sourceComponentId, 
          +copyResponseCode.sourceCommandDbId, +copyResponseCode.sourceResponseId,
          +copyResponseCode.targetComponentId, +copyResponseCode.targetCommandId ).subscribe(res => {
          this.spinner.hide();
          if (res) {
            this.copyCommandFromOtherComp = false;
            this.ToastMessage('Response copied successfully.', 'bg-success');
            this.commandResponseService.getCommandListData(this.router.snapshot.paramMap.get('componentVersionId')).subscribe((listRes) => {
              this.commandForm.reset();
              this.selectedComponentCommandData = listRes;
              if (listRes.length > 0) {
                this.onCommandSelect(this.commandId);
              }
            });
          } else {
            this.copyCommandError = 'Command copy failed.';
          }
        }, error => {
          this.copyCommandError = error;
          this.spinner.hide();
        })
      }
    } else {
      this.copyCommandFromOtherComp = false;
    }
    this.copyButtonState = true;
  }

  public selectionChange(item: DisplayModel) {
    //Changed Item - Component/Version/SRDO/Page No selection change
    this.copyCommandError = ''
    let selectedItem: DisplayModel;
    let selctedType = ''
    this.copyCommandDetails.forEach(element => {
      if (element.values.length > 0) {
        let found = element.values.find(v => v.name === item.name && v.id === item.id);
        if (found) {
          selectedItem = found
          selctedType = element.title
        }
      }
    });

    switch (selctedType) {
      case 'Component':
        this.componentService.getversionlistbycomponent(selectedItem.id.toString()).subscribe((res) => {
          this.copyCommandDetails[1].disabled = false;
          this.copyCommandDetails[2].disabled = true;
          if(!this.isCommand) {
            this.copyCommandDetails[3].disabled = true;
          }
          this.copyCommandDetails.map((res, index) => {
            res.disabled = false;
            if (index > 1) {
              res.disabled = true;
            }
            if (index > 0) {
              res.selectedValue = { id: '', name: '' }
            }
          })
          Object(res).forEach(obj => {
            this.renameKey(obj, 'versionNumberId', 'id')
            this.renameKey(obj, 'versionNumber', 'name')
          });
          this.copyCommandDetails[1].values = JSON.parse(JSON.stringify(res));
          this.changeCopyButtonState()
        })
        break;
      case 'Version':
        this.spinner.show();
        this.commandResponseService.getCommandListData(selectedItem.id.toString()).subscribe((listRes) => {
          Object(listRes).forEach(obj => {
            this.renameKey(obj, 'displayText', 'name')
          });
          this.copyCommandDetails[2].values = JSON.parse(JSON.stringify(listRes));
          this.copyCommandDetails[2].disabled = false;
          this.copyCommandDetails[2].selectedValue = { id: '', name: '' }
          if(!this.isCommand) {
            this.copyCommandDetails[3].disabled = true;
            this.copyCommandDetails[3].selectedValue = { id: '', name: '' }
          }
          this.changeCopyButtonState()
          this.spinner.hide();
        });
        break;
      case 'Command':
        if(this.isCommand) {
          this.changeCopyButtonState()
        }else {
          //Fetch response codes
          this.spinner.show();
          const headerData = { pageSize: 6, pageIndex: '1', sortorder: 'Ascending', sortcolumname: 'Id' };
          this.commandResponseService.getCommandResponseGridDataService(
            this.router.snapshot.paramMap.get('componentVersionId'), item.id, headerData).subscribe((listRes) => {
            Object(listRes.response).forEach(obj => {
              this.renameKey(obj, 'responseCode', 'name')
            });
            this.copyCommandDetails[3].values = JSON.parse(JSON.stringify(listRes.response));
            this.copyCommandDetails[3].disabled = false;
            this.copyCommandDetails[3].selectedValue = { id: '', name: '' }
            this.changeCopyButtonState()
            this.spinner.hide();
          });
        }
        break;
      case 'Code':
        this.changeCopyButtonState()
        break;
      default:
        break;
    }
  }

  renameKey(obj: any, oldKey: string, newKey: string) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  public changeCopyButtonState() {
    let item = this.copyCommandDetails.find((item) => item.selectedValue.id.length === 0)
    this.copyButtonState = false;
    if (item) {
      this.copyButtonState = true;
    }
  }

  closeEvent(event) {
    this.showLinkModal = false;
  }

  filterBy(type: FilterType) {
    if(this.selectedFilter === type) {
      this.ascending = !this.ascending
    } else {
      if(type === FilterType.CreatedDate || type === FilterType.ModifiedDate) {
        this.ascending = false
      } else {
        this.ascending = true
      }
    }
    this.selectedFilter = type
  }

  public findCommandLinkedFeatures() {
    this.spinner.show();
    this.componentService.getLinkedFeatures(Number(this.componentVersionId), this.commandId, 2).subscribe((data) => {
       this.spinner.hide()
       this.linkedFeatures = data
       let isAdmin = this.useraccess.actions.roles.find(x => x.id == 1);
       let isGlobalRead = this.useraccess.actions.roles.find(x => x.id == 9);
       if(isAdmin !== undefined || isGlobalRead !== undefined){
        this.linkedFeatures.map((linkedfeature) => {
          linkedfeature.isNavigable = true
        })
      } else{
         //Component User or Component Owner
         this.checkforIsNavigable()
      }
      this.showLinkModal = true;
     }, ((error) => {
       this.spinner.hide()
     }));
  }

  /* Enable Linking on Menu and Feature */
  checkforIsNavigable() {
    this.linkedFeatures = this.linkedFeatures.filter(item => item.systemId !== -1);
    this.linkedFeatures.map((linkedfeature) => {
        let system = this.useraccess.actions.systems.find((system) => {
          return system.systemId === linkedfeature.systemId
        })
        if(system) {
          linkedfeature.isNavigable = true
        }else {
          linkedfeature.isNavigable = false
        }
    })
  }

  public get filterType() {
    return FilterType; 
  }

  copyResponseCode() {

  }
}
export enum CommandDataActions {
  DeleteCommand = 'DeleteCommand',
  AddCommand = 'AddCommand',
  UpdateCommand = 'UpdateCommand',
  ImportCommandResponseData = 'ImportCommandResponseData',
  AddCommandArgument = 'AddCommandArgument',
  AddCommandResponse = 'AddCommandResponse'
}

export enum FilterType {
  Name = 'Name',
  Subtype = "Subtype",
  CreatedDate = "Created Date",
  ModifiedDate = "Modified Date",
  Id = "Srdo Id"
};

