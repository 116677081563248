import { Injectable } from '@angular/core';
import { getServiceUrl, AppService } from 'src/app/global/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { featureListModel, featureListing, CommonFeature, CommFeatureList } from 'src/app/models/system/feature/feature.module';
import { map } from 'rxjs/Operators';
import { MenuWidgetModel } from 'src/app/models/widget/menu.widget.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private attachedFeatureMessage = new BehaviorSubject('');
  private attachedFeatureMessageId = new BehaviorSubject('');
  featureId = this.attachedFeatureMessageId.asObservable();
  currentFeatureName = this.attachedFeatureMessage.asObservable();
  getFeatureServiceId: number;
  getFeatureDBServiceId: number;
  featureMenuId: number;
  constructor(private http: HttpClient) {
  }
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**
   * Feature List Data
   * param {system Id} headerData
   * returns {Observable<featureListModel>}
   * memberof GetFeatureList
   */
  getFeaturelist(systemId): Observable<featureListModel> {
    this.httpOptions = {
      headers: new HttpHeaders({
        systemid: systemId
      })
    };
    return this.http.get<featureListModel>(getServiceUrl(AppService.UnMappedFeatureListBySystem), this.httpOptions);
  }
  /**
   * Feature Data Bases on Feature ID Data
   * param {System Id, Feature ID} headerData
   * returns {Observable<featureDetailsModel>}
   * memberof FeatureById
   */
  getFeatureDataByFeatureIdService(headerData: any, systemId: string, FeatureId:number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        pageSize: headerData.pageSize,
        pageIndex: headerData.pageIndex,
        sortorder: headerData.sortorder,
        sortcolumname: headerData.sortcolumname,
        systemid: systemId,
        featureid: FeatureId.toString()
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.FeatureListingByFeatureId), httpOptions);
  }
  /**
   * Feature Listing Excel import file
   * param { system Id} headerData
   * memberof ImportFeatureFile
   */
  importFeatureListingExcelFile(data, systemId) {
    this.httpOptions = {
      headers: new HttpHeaders({
        systemid: systemId
      })
    };
    return this.http.post(getServiceUrl(AppService.ImportListing), data, this.httpOptions);
  }
  /**
   * Feature Excel import file
   * param { system Id} headerData
   * memberof ImportFeatureFile
   */
  importFeatureExcelFile(data, systemId) {
    this.httpOptions = {
      headers: new HttpHeaders({
        systemid: systemId
      })
    };
    return this.http.post(getServiceUrl(AppService.ImportFeatureFile), data, this.httpOptions);
  }
  /**
   * Create a Feature
   * param headerData
   * memberof FeatureAdd
   */
  CreateFeature(data, systemId) {
    this.httpOptions = {
      headers: new HttpHeaders({
      systemid : systemId,
    })
   };
    data.createdBy = 'test@gmail.com';
    return this.http.post(getServiceUrl(AppService.FeatureAdd), data, this.httpOptions);
  }
  /**
   * Update a Feature
   * param headerData
   * memberof FeatureUpdate
   */
  editFeature(featureId, data, systemId) {
    this.httpOptions = {
      headers: new HttpHeaders({
        systemid: systemId
      })
    };
    const featureData = data;
    featureData.ModifiedBy = 'test@gmail.com';
    featureData.id = featureId;
    delete featureData.createdBy;
    return this.http.put(getServiceUrl(AppService.FeatureUpdate), featureData, this.httpOptions);
  }
  /**
   * Delete a Feature
   * param headerData
   * memberof deleteUpdate
   */
  deleteFeature(id) {
    return this.http.delete(getServiceUrl(AppService.FeatureDelete, id), this.httpOptions);
  }
  /**
   * Feature All List Data
   * param {system Id} headerData
   * returns {Observable<featureListModel>}
   * memberof FeatureListBySystem
   */
  getFeatureListBySystem(systemId): Observable<featureListModel> {
    this.httpOptions = {
      headers: new HttpHeaders({
        systemid: systemId
      })
    };
    return this.http.get<featureListModel>(getServiceUrl(AppService.FeatureListBySystem), this.httpOptions);
  }
  /**
   * Feature All Feature List Master  Data
   * headerData
   * returns {Observable}
   * memberof GetFeatureListingMasterData
   */
  GetFeatureListingMasterData(): Observable<any> {
    return this.http.get<any>(getServiceUrl(AppService.FeatureListingMasterData), this.httpOptions);
  }
  /**
   * Feature All Data Widget List 
   * headerData
   * returns {Observable}
   * memberof GetFeatureDataWidgetList
   * Alphabetically sorted
   */
  GetFeatureDataWidgetList(): Observable<any> {
    return this.http.get<any>(getServiceUrl(AppService.GetFeatureDataWidgetList), this.httpOptions).pipe(
      map(messages => messages.sort((a1: MenuWidgetModel, a2: MenuWidgetModel) => {
          if(a1.name === "DefaultWidget" || a2.name === "DefaultWidget") return 0;
          if(a1.name.toLocaleLowerCase() < a2.name.toLocaleLowerCase()) return -1;
          if(a1.name.toLocaleLowerCase() > a2.name.toLocaleLowerCase()) return 1;
          return 0;
      }))
    );
  }
  /**
   * Feature Component and version List 
   * headerData
   * returns {Observable}
   * memberof GetComponentVersionList
   */
  GetComponentVersionList(): Observable<any> {
    return this.http.get<any>(getServiceUrl(AppService.GetComponentVersionList), this.httpOptions);
  }
  /**
   * Get Pages based on comp id and version 
   * headerData
   * returns {Observable}
   * memberof getPageByComponentIdVersionNumber
   */
  getPageByComponentIdVersionNumber(componentid, versionnumber): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentId: componentid.toString(),
        versionNumber: versionnumber
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.getPageByComponentIdVersionNumber), this.httpOptions);
  }
  /**
   * Get Pages based on comp id and version 
   * headerData
   * returns {Observable}
   * memberof getSrdoByComponentIdVersionNumber
   */
  getSrdoByComponentIdVersionNumber(componentId, versionNumber, pageNumber): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId.toString(),
        versionNumber,
        pageNumber
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.getSrdoByComponentIdVersionNumber), this.httpOptions);
  }
  /**
   * Get Command Response based on comp id and version 
   * headerData
   * returns {Observable}
   * memberof getCommandByComponentIdVersionNumber
   */
  getCommandByComponentIdVersionNumber(componentId, versionNumber): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        componentId,
        versionNumber,
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.getCommandByComponentIdVersionNumber), this.httpOptions);
  }
   /* Feature List Data
   * param {system Id} headerData
   * returns {Observable<featureListModel>}
   * memberof GetFeatureList
   */
  getAllFeaturelist(systemId): Observable<featureListModel> {
    this.httpOptions = {
      headers: new HttpHeaders({
      systemid : systemId
      })
     };
    return this.http.get<featureListModel>(getServiceUrl(AppService.GetFeatureList), this.httpOptions);
  }
  /**
   * Feature Listing Data
   * param {system Id, Feature Id} headerData
   * returns {Observable<featureListModel>}
   * memberof ListingByFeatureId
   */

  getFeatureListing(data): Observable<featureListing> {
    const httpOption = {
      headers: new HttpHeaders({
        systemid : String(data.systemId),
        featureid : data.featureId,
        pageSize : data.pageSize,
        pageIndex : data.pageIndex
      })
     };
    return this.http.get<featureListing>(getServiceUrl(AppService.GetFeatureListing), httpOption);
  }

  /**
   * Create a Feature List
   * param headerData
   * memberof Feature Lst Add
   */
  CreateFeatureList(data, featureId) {
    this.httpOptions = {
      headers: new HttpHeaders({
        featureDBId : String(featureId),
    })
   };
   return this.http.post(getServiceUrl(AppService.AddFeatureList), data, this.httpOptions);
  }
  /**
   * SwapFeatureListingPosition Listing Data
   * param {system Id, Feature Id} headerData
   * returns {Observable<>}
   * memberof SwapFeatureListingPosition
   */
  SwapFeatureListingPosition(data) {
    this.httpOptions = {
      headers: new HttpHeaders({
        sourcefeatureListingid : data.sourceId,
        destinationfeatureListingid : data.destinationId
      })
     };
    return this.http.get(getServiceUrl(AppService.SwapFeatureListingPosition), this.httpOptions);
  }
  /**
   * deleteFeatureListing Listing Data
   * param { Feature Id} headerData
   * returns {Observable<>}
   * memberof featureListingDelete
   */
  deleteFeatureListing(id) {
    return this.http.delete(getServiceUrl(AppService.deleteFeatureListing, id), this.httpOptions);
  }
  /**
   * Update a Feature Listing
   * param headerData
   * memberof FeatureListingUpdate
   */
  UpdateFeatureList(data, featureListingId) {
    const featureListingData = data[0];
    featureListingData.ModifiedBy = 'test@gmail.com';
    featureListingData.featureListingId = featureListingId;
    delete featureListingData.widgetId;
    delete featureListingData.createdBy;
    return this.http.put(getServiceUrl(AppService.featureListingUpdate), featureListingData, this.httpOptions);
  }

  /*
  * Get Component Id and Version Id of a feature listing item
  * GetComponentDataForNavigation
  */
  componentDataForNavigation(featureListId: Number) {
    this.httpOptions = {
      headers: new HttpHeaders({
        featureListingId : featureListId.toString()
      })
     };
    return this.http.get<Number>(getServiceUrl(AppService.GetComponentDataForNavigation), this.httpOptions);
  }

  /**
   * 
   * @param sourceSystemId 
   * @param targetSystemId 
   * @param featureId 
   * @returns @Observable - true/false
   */
  copyFeatureDataByFeatureId(featureId: number, sourceSystemId: number, targetSystemId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        featureId : featureId.toString(),
        sourceSystemId: sourceSystemId.toString(),
        targetSystemId: targetSystemId.toString()
      })
     };
    return this.http.post<boolean>(getServiceUrl(AppService.CopyFeatureDataByFeatureId), null, httpOptions);
  }

  attachedFetureName(name, id) {
    this.attachedFeatureMessage.next(name);
    this.attachedFeatureMessageId.next(id);
  }
  goToFeatureMenuId(id) {
   this.featureMenuId =  id;
  }

  /***
   * Add common feature
   */
  addCommonFeature(componentId: string, titleId: number, data:any) : Observable<number> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId : componentId,
        commonTitleId : titleId.toString()
    })
   };
   data['createdBy'] = 'user@gmail.com'
   return this.http.post<number>(getServiceUrl(AppService.AddCommonFeature), data, httpOptions);
  }

    /**
   * Update a Feature
   * param headerData
   * memberof FeatureUpdate
   */
  updateCommonFeature(data: any, featureId: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      const featureData = data;
      featureData.ModifiedBy = 'test@gmail.com';
      featureData.id = featureId;
      return this.http.put(getServiceUrl(AppService.UpdateCommonFeature), featureData, httpOptions);
    }

   /**
   * Feature List Data
   * param {system Id} headerData
   * returns {Observable<featureListModel>}
   * memberof GetFeatureList
   */
   getCommonFeaturelist(componentId: string, commonTitleId: string): Observable<CommonFeature[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId,
        commonTitleId: commonTitleId
      })
    };
    return this.http.get<any>(getServiceUrl(AppService.GetCommonFeatures), httpOptions);
  }

  getCommonFeatureData(featureId: string, componentId: string, commonTitleId : number): Observable<CommonFeature> {
    let httpOptions = {
      headers: new HttpHeaders({
        componentId: componentId,
        featureId: featureId,
        commonTitleId : commonTitleId.toString()
      })
    };
    return this.http.get<CommonFeature>(getServiceUrl(AppService.GetCommonFeatureData), httpOptions)
  }

    /**
   * Delete a Feature
   * param headerData
   * memberof deleteUpdate
   */
    deleteCommonFeature(featureId: string) {
      let httpOptions = {
        headers: new HttpHeaders({
          featureId: featureId,
        })
      };
      return this.http.delete(getServiceUrl(AppService.DeleteCommonFeature), httpOptions);
    }

    copyCommonFeature(sourceCommonTitleId : number, targetCommonTitleId : number, componentId : number, commonFeatureId: number) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          sourceCommonTitleId : sourceCommonTitleId.toString(),
          targetCommonTitleId: targetCommonTitleId.toString(),
          componentId: componentId.toString(),
          commonFeatureId : commonFeatureId .toString()
        })
       };
      return this.http.post<boolean>(getServiceUrl(AppService.CopyCommonFeatureData), null, httpOptions);
    }

    //Feature Listing - Common
    getCommonFeatureListing(featureId: string, componetId: string, commonTitleId: number, pageSize: number, pageIndex:number): Observable<featureListing> {
      const httpOption = {
        headers: new HttpHeaders({
          componentId : componetId,
          commonTitleId: commonTitleId,
          featureId : featureId,
          pageSize : pageSize,
          pageIndex : pageIndex
        })
       };
      return this.http.get<featureListing>(getServiceUrl(AppService.GetListingDataByCommonFeatureId), httpOption);
    }

    /**
   * Create a Feature List
   * param headerData
   * memberof Feature Lst Add
   */
  createCommonFeatureList(data, featureId) {
    const httpOptions = {
      headers: new HttpHeaders({
        featureId : String(featureId),
    })
   };
   return this.http.post(getServiceUrl(AppService.AddCommonFeatureListing), data, httpOptions);
  }

    /**
   * Update a Common Feature Listing
   * param headerData
   * memberof FeatureListingUpdate
   */
    updateCommonFeatureList(data, featureListingId) : Observable<boolean> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
      const featureListingData = data[0];
      featureListingData.ModifiedBy = 'test@gmail.com';
      featureListingData.featureListingId = featureListingId;
      delete featureListingData.widgetId;
      delete featureListingData.createdBy;
      return this.http.put<boolean>(getServiceUrl(AppService.UpdateCommonFeatureListing), featureListingData, httpOptions);
    }

    /**
   * deleteFeatureListing common listing Data
   * param { Feature Id} headerData
   * returns {Observable<>}
   * memberof featureListingDelete
   */
  deleteCommonFeatureListing(featureListingId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'featureListingId': featureListingId
      })
    };
    return this.http.delete(getServiceUrl(AppService.DeleteCommonFeatureListing, null), httpOptions);
  }

    /**
   * ReOrder common feature listing position Data
   * param {system Id, Feature Id} headerData
   * returns {Observable<>}
   * memberof SwapFeatureListingPosition
   */
    reOrderCommonFeatureListingPosition(sourceListingid: number, targetListingId: number) {
      let httpOptions = {
        headers: new HttpHeaders({
          sourceListingid  : sourceListingid,
          targetListingId  : targetListingId
        })
       };
      return this.http.post(getServiceUrl(AppService.ReorderCommonFeatureListing), null, httpOptions);
    }
}
