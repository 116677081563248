import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import {
  faEdit, faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { DataBindingDirective, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { FileInfo, FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { process } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResourceActions } from 'src/app/global/app-enums.enum';
import { UserAccess } from 'src/app/global/user-access';
import { ComponentListService } from 'src/app/services/component/component-data.service';
import { ToastService } from 'src/app/services/toast/toast.service';
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  public gridView: GridDataResult;
  list: FileDetails[] = []
  data: ResourceFiles = { files: [], recordCount: 0, sasToken: "" }
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;
  faCopy = faCopy;
  pageSize = 10;
  fileUrl = ""
  public userImages: Array<FileInfo>;
  private isNew = false;
  isEditing = false;
  public formGroup: UntypedFormGroup;
  uploadError = false
  // For Angular 8
  @ViewChild(GridComponent, { static: true })
  public grid: GridComponent;
  private editedRowIndex: number;
  public myRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".jpeg", ".png", ".pdf", ".svg", ".tif", ".gif", ".ico", ".cur", ".xbm", ".bmp"]
  };
  showConfirmation = false
  selectedDataItem: FileDetails = undefined
  componentId: string = ''
  editAccess = false
  deleteAccess = false
  headerData = { pageSize: this.pageSize.toString(), pageIndex: (1).toString() };
  searchText = ''
  fileSizeMessage = 'Resource is required of size < 150 kb.'
  disclaimer = `
  Disclaimer: This website, its content, text, products and all graphic/drawing/artwork images are all the work of Otis Elevator.`
  @ViewChild(DataBindingDirective, { static: false }) dataBinding: DataBindingDirective;

  constructor(private router: ActivatedRoute, private componentService: ComponentListService,
    public toastService: ToastService, private useraccess: UserAccess, private route: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.router.paramMap.subscribe(params => {
      if (this.router.snapshot.paramMap.get('id') != null) {
        this.componentId = this.router.snapshot.paramMap.get('id');
        const componentVersionId = this.router.snapshot.paramMap.get('componentVersionId');
        let resourceAccess = this.useraccess.getUserAccess('components', 'GetComponentResourceList', Number(this.componentId));
        let isGlobalRead = this.useraccess.actions.roles.find(x => x.id == 9);
        let isComponentRead = this.useraccess.actions.roles.find(x => x.id == 7);
        this.editAccess = this.useraccess.getUserAccess('components', ResourceActions.DeleteResources, Number(this.componentId));
        if (resourceAccess || isGlobalRead || isComponentRead) {
          this.getResources(Number(this.componentId));
        } else {
          this.route.navigate(['component-data/srdo', this.componentId, componentVersionId])
        }
      }
    });
    this.gridView = this.list as any;
  }

  /**
   * Get the resources list with component id
   * @param componentId
   */
  getResources(componentId: number) {
    this.spinner.show()
    this.componentService.getResources(componentId).subscribe((res) => {
      this.searchText = ""
      this.list = res.files ? res.files : [];
      this.data = res ? res : { files: [], recordCount: 0, sasToken: "" }
      this.gridView = this.list as any
      this.spinner.hide()
    }, (err) => {
      this.spinner.hide()
    });
  }

  /**
   * Grid actions - Add Row
   */
  public addHandler() {
    this.isEditing = true;
    this.grid.addRow(this.createFormGroup({ fileName: '', resourceFile: '' }));
  }

  /**
   * Create a new row with required columns
   * @param dataItem 
   * @returns 
   */
  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formGroup = new UntypedFormGroup({
      fileName: new UntypedFormControl(dataItem.fileName, [Validators.required, Validators.maxLength(200)]),
      resourceFile: new UntypedFormControl(dataItem.resourceFile, [Validators.required])
    });
  }

  /**
   * Create a editable row with existing data
   * @param param0
   */
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.isEditing = true;
    dataItem.isEditable = true;
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  /**
   * Close the editable row
   * @param grid 
   * @param rowIndex 
   */
  private closeEditor(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {
    this.isNew = false;
    this.isEditing = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  /**
   * Cancel or discard changes
   * @param param0 
   */
  public cancelHandler({ sender, rowIndex, dataItem }) {
    //add the remtype identifier Prefix 0x to the field
    this.uploadError = false
    // dataItem?.isEditable = false;
    this.isEditing = false;
    this.fileUrl = ''
    sender?.closeRow(rowIndex);
  }

  /**
   * Save the new row or update the existing row
   * @param param0 
   */
  public saveHandler({ sender, formGroup, rowIndex, isNew, dataItem }) {
    if (formGroup.valid && !this.uploadError) {
      let value = formGroup.value;
      if (isNew) {
        const formData = new FormData();
        this.formGroup.get('resourceFile').setValue(this.userImages[0].rawFile);
        formData.append('resourceFile', this.formGroup.get('resourceFile').value);
        this.spinner.show()
        this.componentService.saveResource(formData, this.componentId, value['fileName']).subscribe((res) => {
          this.spinner.hide()
          if (res) {
            this.ToastMessage("Resource created successfully", 'bg-success', 'Success')
            this.userImages = undefined
            sender.closeRow(rowIndex)
            this.getResources(Number(this.componentId))
            this.isEditing = false;
          }
        }, () => {
          this.spinner.hide()
        })
      } else {
        const formData = new FormData();
        this.formGroup.get('resourceFile').setValue(this.userImages[0].rawFile);
        formData.append('resourceFile', this.formGroup.get('resourceFile').value);
        this.spinner.show()
        this.componentService.updateResource(formData, dataItem.fileId).subscribe((res) => {
          this.spinner.hide()
          if (res) {
            this.ToastMessage("Resource updated successfully", 'bg-success', 'Success')
            this.userImages = undefined
            sender.closeRow(rowIndex)
            this.getResources(Number(this.componentId))
            this.isEditing = false;
          }
        }, () => {
          this.spinner.hide()
        })
      }
    }
  }

  /**
   * Copy the Link
   * @param item 
   */
  copiedAction(item: FileDetails) {
    this.ToastMessage('Link copied successfully.', 'bg-success', 'Success');
    navigator.clipboard.writeText(item.linkText);
  }

  /**
   * File selection
   * @param e 
   */
  public select(e: SelectEvent): void {
    this.uploadError = false
    if(e.files[0] && e.files[0].extension === ".pdf") {
      if(e.files[0].size > 10485760) {
        this.uploadError = true
        this.fileSizeMessage = 'PDF is required of size < 10 MB.'
      }
    }else {
      if(e.files[0].size > 153600) {
        this.uploadError = true
        this.fileSizeMessage = 'Resource is required of size < 150 kb.'
      }
    }
    this.userImages = e.files
  }

  /**
   * Filter the resources grid
   * @param inputValue 
   */
  public onFilter(inputValue: string): void {
    this.gridView = process(this.list, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'fileName',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'linkText',
            operator: 'contains',
            value: inputValue
          },
        ],
      }
    }).data as any
    this.dataBinding.skip = 0;
  }

  /**
   * Toast message
   * @param message 
   * @param type 
   * @param headertext 
   */
  ToastMessage(message, type, headertext) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext
    });
  }

  pageChange(event) {

  }

  /**
   * Show delete resource confirmation dailog
   * @param dataItem 
   */
  deleteResource(dataItem: FileDetails) {
    this.showConfirmation = true
    this.selectedDataItem = dataItem;
  }

  /**
   * Delete a resource
   * @param status 
   */
  deleteConfirmation(status: boolean) {
    this.showConfirmation = false
    if (status) {
      this.spinner.show()
      this.componentService.deleteResource(this.selectedDataItem.fileId).subscribe((res) => {
        this.spinner.hide()
        if (res) {
          this.ToastMessage("Resource deleted successfully", 'bg-success', 'Success')
          this.getResources(Number(this.componentId))
        }
      }, () => {
        this.spinner.hide()
      });
    }
  }

  mousehover(item: FileDetails) {
    item.hover = true
  }

  mouseout(item: FileDetails) {
    item.hover = false
  }

}
export interface ResourceFiles {
  files: FileDetails[],
  recordCount: number,
  sasToken: string
}
export interface FileDetails {
  fileId: number,
  fileName: string,
  linkText: string,
  fileUrl: string,
  createdDate: string,
  modificationDate: string,
  hover: boolean
}
