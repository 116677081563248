import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { DataWidgetComponent } from './data-widget/data-widget.component';
import { MenuWidgetComponent } from './menu-widget/menu-widget.component';
import { WidgetRoutingModule } from './widget-routing.module';
import { WidgetComponent } from './widget/widget.component';
import { InputDataComponent } from './input-data/input-data.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GroupGridComponent } from '../common/group-grid/group-grid.component';

@NgModule({
  declarations: [
    MenuWidgetComponent,
    DataWidgetComponent,
    WidgetComponent,
    InputDataComponent,
    GroupGridComponent
  ],
  imports: [
    CommonModule,
    WidgetRoutingModule,
    NgbModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    GridModule,
    ReactiveFormsModule,
    PopupModule,
    DialogModule
  ]
})
export class WidgetsModule { }
