import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faCopy, faEdit, faFileImport, faPlus, faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { EnumValues } from 'src/app/common/enum-edit/enum-edit.component';
import { DataType } from 'src/app/models/component/srdo-data.model';
import { CommandResponseService } from 'src/app/services/component/command-response.service';
import { SrdoDataService } from 'src/app/services/component/srdo-data.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { FeatureService } from '../../services/system/feature.service';
import { CustomFormValidationsService } from '../../shared/formValidations/custom-form-validations.service';
import { RangeValidator } from '../create-response-argument/create-response-argument.component';
@Component({
  selector: 'app-command-argument',
  templateUrl: './command-argument.component.html',
  styleUrls: ['./command-argument.component.scss']
})
export class CommandArgumentComponent implements OnInit {

  public createCommandArgumentForm: UntypedFormGroup;
  public bitmasks: UntypedFormArray;
  public customs: UntypedFormArray;
  submitted = null;
  faTimes = faTimes;
  widgetTypeIndex: any = [];
  componentVersionListData: any = [];
  selectedComponent = '';
  componentVersion: any = [];
  dataTypes: DataType[] = [];
  @Input() title: any;
  @Input() commandId: any;
  @Output() commandArgumentFormData = new EventEmitter();
  @Input() editCommandArgumentData: any;
  @Input() isReopened : boolean;
  loading = false
  faSearch = faSearch;
  faFileImport = faFileImport;
  faPlus = faPlus;
  faTrash = faTrash;
  faEdit = faEdit;
  faCopy = faCopy
  enableFieldFLag = false;
  displayEnumRows = true;
  extraValueSplit: any = '';
  public gridView: any = [{}];
  items: any = []
  private editedRowIndex: number;
  private isNew = false;
  public formGroup: UntypedFormGroup;
  public editId: 0;
  dataTypeName = 'Oint';
  dataTypeDetails = '';
  enumDataDetails = false;
  enumDataDetailsValidation = false;
  bitmaskDataTypeArr = [];
  enumErrorMessage = null;
  bitmaskEnumErrorMessage = null;
  bitmaskEnumErrorMessages = {}
  @ViewChild(GridComponent)
  public grid: GridComponent;
  errorMsg = '';
  kendoEnumEditData = ''
  enummaximum = -1
  enumminimum = -1
  eventsSubject: Subject<string> = new Subject();
  enumEditValues = {};
  isBitMaskEnumEdit = false;
  validationErrorMessage = ''
  maxminError = 'Maximum value is less than the minimum value. Please check your data and try again.'

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private featureService: FeatureService,
    private spinner: NgxSpinnerService,
    private srdoDataService: SrdoDataService,
    private modalService: NgbModal,
    private commandResponseService: CommandResponseService,
    private router: ActivatedRoute,
    public toastService: ToastService,
    private formBuilder: UntypedFormBuilder,
    private customFormValidationsService: CustomFormValidationsService,
  ) {
    //this.GetComponentVersionList();
  }

  ngOnInit() {
    this.gridView.splice(0, 1);
    this.initform();
    if (this.editCommandArgumentData != null) {
      this.editId = this.editCommandArgumentData.id;
      this.createCommandArgumentForm.patchValue({
        text: this.editCommandArgumentData.displayText,
        description: this.editCommandArgumentData.description,
        dataTypeId: this.editCommandArgumentData.dataTypeId,
        dataPosition: this.editCommandArgumentData.dataPosition,
        count: this.editCommandArgumentData.count,
        maxValue: this.editCommandArgumentData.maxValue,
        minValue: this.editCommandArgumentData.minValue,
        dataType: this.editCommandArgumentData.dataType,
        Id : this.editCommandArgumentData.id
      });
      this.createCommandArgumentForm.setControl('bitmasks', this.setBitmasks(this.editCommandArgumentData));
      this.createCommandArgumentForm.get('dataTypeId')?.disable()
      if(this.isReopened) {
        this.createCommandArgumentForm.get('dataPosition')?.disable()
        this.createCommandArgumentForm.get('count')?.disable()
      }

      this.dataTypeName = this.editCommandArgumentData.dataTypeName;
      this.gridView = this.editCommandArgumentData.dataTypeDetails;
      setTimeout( () => {
        this.hideEditPageBtn();
       }, 1000);
       // tslint:disable-next-line: max-line-length
      if (Number(this.editCommandArgumentData.dataTypeId) === 11 || Number(this.editCommandArgumentData.dataTypeId) === 12 
       || Number(this.editCommandArgumentData.dataTypeId) === 13 || Number(this.editCommandArgumentData.dataTypeId) === 14) {
        this.setBitmaskValidator();
      }
    }
    this.getDataTypeListData();
    this.getBitmaskDataType();
  }

  initform() {
    this.createCommandArgumentForm = this.fb.group({
      text:  [null, [Validators.required]],
      description: [null, Validators.required],
      dataTypeId: [1, Validators.required],
      dataPosition: [null,  [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(65535)]],
      count: [null,  [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(255)]],
      maxValue: [null,  [Validators.required, Validators.pattern('^[0-9]*$')]],
      minValue: [null,  [Validators.required, Validators.pattern('^[0-9]*$')]],
      bitmasks: this.fb.array([this.bitmask()]),
      extraValues: [null]
    }, {
      validator: [this.customFormValidationsService.MatchDataTypeValidations('minValue', 'maxValue', 'dataTypeId', 'extraValues', null),
                  this.customFormValidationsService.setBitmaskValidation('dataTypeId','bitmasks')]
    });
  }
  get commandArg() {
    return this.createCommandArgumentForm.controls;
  }

  hasDuplicates(arr) {
    return arr.some(x => arr.indexOf(x) !== arr.lastIndexOf(x));
  }

  hasNegitiveIndexes(arr: number[]) {
    let items = arr.find(x => x < 0);
    if(items) {
      return true
    }
    return false
  }


  hasDuplicateIndexes(arr) {
    let arrayindexes = []
    arr.forEach((x) => {
      arrayindexes.push(x.bits)
    })
    return arrayindexes.some(x => arrayindexes.indexOf(x) !== arrayindexes.lastIndexOf(x));
  }

  submitForm() {
    this.errorMsg = '';
    this.submitted = true;
    if (this.createCommandArgumentForm.invalid) {
      return false;
    }
     if( Number(this.createCommandArgumentForm.get('maxValue').getRawValue()) < Number(this.createCommandArgumentForm.get('minValue').getRawValue())) {
      this.errorMsg = this.maxminError;
      return;
    }
    if (this.dataTypeName.includes('Enum') || this.dataTypeName.includes('Command')) {
      if (this.createCommandArgumentForm !== undefined ) {
        // this.gridView = this.editCommandArgumentData.dataTypeDetails;
        const enumArray: any[] = [];
        this.gridView.forEach((element, index) => {
          enumArray.push(Number(this.gridView[index].index));
        });
        if (this.hasDuplicates(enumArray) || this.hasNegitiveIndexes(enumArray)) {
          this.errorMsg = 'Please provide valid enumeration data';
          return;
        }
        this.gridView.forEach((element, index) => {
          if ((Number(this.createCommandArgumentForm.get('minValue').getRawValue()) > Number(this.gridView[index].index) ||
          (Number(this.createCommandArgumentForm.get('maxValue').getRawValue()) < Number(this.gridView[index].index) )
          )) {
            this.errorMsg = 'Enumeration value should be between min and max range';
          }
          if (index === 0) {
            this.gridView[index].inputMax = this.createCommandArgumentForm.get('maxValue').getRawValue();
            this.gridView[index].inputMin = this.createCommandArgumentForm.get('minValue').getRawValue();
          }
        });
        if (this.errorMsg.length > 0) {
          return false;
        }
        if ( Object.keys(this.gridView).length === 0) {
          this.enumDataDetails = true;
          this.spinner.hide();
          return false;
        } else {
          this.enumDataDetails = false;
        }
      } else if (this.gridView.length === 0) {
        this.enumDataDetails = true;
        this.spinner.hide();
        this.errorMsg = 'Please provide enumeration data.';
        return false;
      }
    } else  if (this.dataTypeName.includes('Bitmask')) {
      const bitmasksArr = this.createCommandArgumentForm.get('bitmasks').getRawValue();
      let bitmaskarr = []
      bitmasksArr.forEach((row, index) => {
        row.bitmasks_enums?.forEach((item, index) => {
          bitmaskarr.push(Number(item.bit_index));
        })
      })
      if (this.hasDuplicateIndexes(bitmasksArr) || this.hasNegitiveIndexes(bitmaskarr) ) {
        this.errorMsg = 'Please provide valid bitmask data';
        return;
      }
      if (bitmasksArr.length > 0) {
          let bitmaskData;
          this.gridView = [];
          for (const bitmask of bitmasksArr) {
            const bitmasksEnumsArr = bitmask.bitmasks_enums;
            const bitEnumObjArr = [];
            let dataType ;
            if ( bitmask.data_type_disabled !== undefined ) {
              dataType = bitmask.data_type_disabled;
            } else {
              dataType = bitmask.data_type;
            }
            dataType = ((dataType === '') ? Number(bitmask.data_type) : Number(bitmask.data_type_disabled));
            if (Number(dataType) === 1) {
              for (const bitEnum of bitmasksEnumsArr) {
                const enumObj = {
                  index: bitEnum.bit_index,
                  value: bitEnum.bit_value
                };
                bitEnumObjArr.push(enumObj);
              }

              bitmaskData = {
                index: bitmask.bits,
                dataTypeId: Number(dataType),
                value: bitEnumObjArr,
                text: bitEnumObjArr[0].value,
                inputMax: this.createCommandArgumentForm.get('maxValue').getRawValue(),
                inputMin: this.createCommandArgumentForm.get('minValue').getRawValue(),
              };
            } else {
              bitmaskData = {
                index: bitmask.bits,
                dataTypeId: ((dataType === '') ? Number(bitmask.data_type) : Number(dataType)),
                value: bitmask.value,
                text: bitmask.value,
                inputMax: this.createCommandArgumentForm.get('maxValue').getRawValue(),
                inputMin: this.createCommandArgumentForm.get('minValue').getRawValue(),
              };
            }
            this.gridView.push(bitmaskData);
          }
      } else {
        this.gridView = [{
          inputMax: this.createCommandArgumentForm.get('maxValue').getRawValue(),
          inputMin: this.createCommandArgumentForm.get('minValue').getRawValue(),
        }];
      }
    } else {
      this.gridView = [{
        inputMax: this.createCommandArgumentForm.get('maxValue').getRawValue(),
        inputMin: this.createCommandArgumentForm.get('minValue').getRawValue(),
      }];
    }
    this.modalService.dismissAll();
    const addCommandArgData = [{
      displayText: this.createCommandArgumentForm.get('text').getRawValue(),
      description: this.createCommandArgumentForm.get('description').getRawValue(),
      dataTypeId: this.createCommandArgumentForm.get('dataTypeId').getRawValue(),
      dataPosition: this.createCommandArgumentForm.get('dataPosition').getRawValue(),
      count: this.createCommandArgumentForm.get('count').getRawValue(),
      minValue: this.createCommandArgumentForm.get('minValue').getRawValue(),
      maxValue: this.createCommandArgumentForm.get('maxValue').getRawValue(),
      createdBy: 'test@gmail.com',
      dataTypeDetails: this.gridView ,
      dataType: this.createCommandArgumentForm.get('dataTypeId').getRawValue(),
      commandId: this.commandId,
      id: this.editId
    }];
    this.commandArgumentFormData.emit(addCommandArgData);
  }
  clearModelForm() {
    this.createCommandArgumentForm.reset();
    this.dataTypeName = 'Oint8';
    this.setRangeValidators(1)
    this.gridView = [{}];
    this.gridView.splice(0, 1);
    this.createCommandArgumentForm.patchValue({
      dataTypeId: 1,
    });
    this.removeBitmaskValidators();
    this.createCommandArgumentForm.setControl('bitmasks', new UntypedFormArray([]));
   // this.selecteFormDefaultValue();
    this.submitted = false;
  }
  getDataTypeListData() {
    this.srdoDataService.getSrdoMasterListData().subscribe((data) => {
      if (data !== null) {
        const dataType = data.srdoDataTypes.filter((x) => x.name !== 'Custom' && x.name !== 'Char' && x.name !== 'Text');
        const argumentDataType = dataType.filter((dataTypeVal) => {
          return dataTypeVal.name !== 'Custom';
        });
        this.dataTypes = argumentDataType;
        if(this.editCommandArgumentData) {
          this.setRangeValidators(this.editCommandArgumentData.dataType.id)
        } else {
          this.setRangeValidators(1)
        }
      }
    },
      (() => {
        this.spinner.hide();
      }));
  }
  ToastMessage(message, type) {
    this.toastService.show(message, {
      classname: type,
      delay: 3000,
      autohide: true,
      headertext: 'Success'
    });
  }
  // tslint:disable-next-line:adjacent-overload-signatures
  onOptionsSelected(event) {
    this.dataTypeName = event.target.options[event.target.options.selectedIndex].text;
    const dataTypeVal: Number = Number(event.target.value);
    this.removeFieldValidator(dataTypeVal);
  }

  public removeHandler({ sender, dataItem, rowIndex }) {
    this.gridView.splice(rowIndex, 1);
    sender.cancelCell();
  }
  public cancelHandler({ sender, rowIndex }) {
    this.enumErrorMessage = '';
    sender.closeRow(rowIndex);
  }
  public addHandler() {
    this.enumErrorMessage = '';
    const addEnum = { index: '', value: '' };
    this.grid.addRow(this.createFormGroup({ index: '', value: '' }));
  }
  public createFormGroup(dataItem: any): UntypedFormGroup {
    return this.formGroup = new UntypedFormGroup({
            index: new UntypedFormControl(dataItem.index, [Validators.required]),
            value: new UntypedFormControl(dataItem.value, [Validators.required]),
            defaultValue: new UntypedFormControl(),
            extraValues: new UntypedFormControl(),
            inputMax: new UntypedFormControl(this.createCommandArgumentForm.get('maxValue').getRawValue()),
            inputMin: new UntypedFormControl(this.createCommandArgumentForm.get('minValue').getRawValue())
     });
   }
  public editHandler({ sender, rowIndex, dataItem }) {
    if(sender !== null)
    this.closeEditor(sender);

    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    if(sender !== null)
    sender.editRow(rowIndex, this.formGroup);
  }
  private closeEditor(grid: GridComponent, rowIndex: number = this.editedRowIndex): void {
    this.isNew = false;
    if(grid !== null)
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formBuilder = undefined;
 }
 public saveHandler({ sender, formGroup, rowIndex, isNew, e }) {
  if (formGroup.valid) {
    // tslint:disable-next-line:forin
    // tslint:disable-next-line:no-shadowed-variable
    if (isNew) {
      for (const prop of this.gridView) {
        if (Number(formGroup.value.index) === Number(prop.index) &&
        Number(formGroup.value.index) > Number( this.createCommandArgumentForm.get('maxValue').getRawValue())
        && Number(formGroup.value.index) <   Number( this.createCommandArgumentForm.get('minValue').getRawValue())) {
          return false;
        } else {
            if (Number(formGroup.value.index) > Number( this.createCommandArgumentForm.get('maxValue').getRawValue())
            || Number(formGroup.value.index) <   Number( this.createCommandArgumentForm.get('minValue').getRawValue())) {
              this.enumErrorMessage = 'Enumeration value should be between min and max range';
              return false;
          }
        }
      }
      this.enumErrorMessage = null;
      this.gridView.push(formGroup.value);
      if(sender !== null)
      sender.closeRow(rowIndex);
    } else {
      for (const prop of this.gridView) {
        if (Number(formGroup.value.index) !== Number(prop.index) &&
        Number(formGroup.value.index) > Number( this.createCommandArgumentForm.get('maxValue').getRawValue())
        && Number(formGroup.value.index) <   Number( this.createCommandArgumentForm.get('minValue').getRawValue())) {
          this.enumErrorMessage = 'Enumeration value should be between min and max range';
          return false;
        } else {
          if (Number(formGroup.value.index) > Number(this.createCommandArgumentForm.get('maxValue').getRawValue())
          || Number(formGroup.value.index) < Number(this.createCommandArgumentForm.get('minValue').getRawValue())) {
            this.enumErrorMessage = 'Enumeration value should be between min and max range';
            return false;
          }
        }
      }
      this.enumErrorMessage = null;
      this.gridView.splice(rowIndex, 1, formGroup.value);
      if(sender !== null)
      sender.closeRow(rowIndex);
    }
  }
}
  bitmask() {
    return this.fb.group({
      bits: '',
      data_type: '',
      data_type_disabled: '',
      value: '',
      enumShow: false,
      bitmasks_enums: this.fb.array([this.bitmaskEnums])
    });
  }
  deleteBitmasks(index) {
   // this.bitmasks = this.editCommandArgumentData.get('bitmasks') as FormArray;
    this.bitmasks = this.createCommandArgumentForm.get('bitmasks') as UntypedFormArray;
    this.bitmasks.removeAt(index);
  }
  addBitmask() {
    this.bitmasks = this.createCommandArgumentForm.get('bitmasks') as UntypedFormArray;
    this.bitmasks.push(this.bitmask());
    this.setBitmaskValidator();
  }
  get bitmaskEnums() {
    return this.fb.group({
      bit_index: '',
      bit_value: ''
    });
  }
  getBitmaskEnumDisplay(bitmaskDataType) {
    if (Number(bitmaskDataType) === 1) {
      return 'block';
    } else {
      return 'none';
    }
  }
  getBitmaskDataType() {
    this.commandResponseService.getBitmaskDataType().subscribe((data) => {
      if (data !== null) {
        this.bitmaskDataTypeArr = data;
      }
      this.spinner.hide();
    },
      (() => {
        this.spinner.hide();
      }));
  }
  addBitmaskEnum(bitmasks, index) {
    const dataType = Number(this.createCommandArgumentForm.get('dataTypeId').getRawValue());
    const messageFlag = this.customFormValidationsService.setBitmaskEnumValidation(bitmasks, dataType);
    if (messageFlag === null) {
      this.bitmaskEnumErrorMessage = 'Enter Value for bit field';
      this.bitmaskEnumErrorMessages[index] = 'Enter Value for bit field'
    } else {
      if (messageFlag === false) {
        bitmasks.get('bitmasks_enums').push(this.bitmaskEnums);
        this.bitmaskEnumErrorMessage = null;
        this.bitmaskEnumErrorMessages[index] = ''
      } else {
        this.bitmaskEnumErrorMessage = 'Enumeration rows should not be greater than '+ messageFlag;
        this.bitmaskEnumErrorMessages[index] = 'Enumeration rows should not be greater than '+ messageFlag;
      }
    }
  }
  deleteBitmaskEnums(bitmasks, index) {
    bitmasks.get('bitmasks_enums').removeAt(index);
  }
  setBitmasks(bitmasksArr) {
    if (bitmasksArr.dataTypeDetails && bitmasksArr.dataTypeDetails.length > 0) {
      const formBitMaskArray = new UntypedFormArray([]);
      bitmasksArr.dataTypeDetails.forEach((element, i) => {
        formBitMaskArray.push(this.fb.group({
          bits: element.index,
          data_type: [{value: element.dataTypeId, disabled: true}],
          data_type_disabled: [element.dataTypeId],
          value: element.text,
          bitmasks_enums: this.setBitmasksEnums(element),
          enumShow: false 
        }));
      });
      return formBitMaskArray;
    }
  }
  setBitmasksEnums(argumentsArr) {
    if (argumentsArr.dataTypeId === 1 && argumentsArr.value.length > 0) {
      const formBitMaskArray = new UntypedFormArray([]);
      argumentsArr.value.forEach((element, i) => {
        formBitMaskArray.push(this.fb.group({
          bit_index: element.index,
          bit_value: element.value,
        }));
      });
      return formBitMaskArray;
    }
  }
  hideEditPageBtn() {
    if(this.createCommandArgumentForm.get('bitmasks')) {
      const bitmasksControl = this.createCommandArgumentForm.get('bitmasks')['controls']; //bitmask row     
      //Hiding bitmask enum input field during edit
      bitmasksControl.forEach((bitmask, j) => {
        if (bitmask.controls.bitmasks_enums.controls !== undefined) {
          const bitmasksEnumControl = bitmask.controls.bitmasks_enums.controls;
          bitmasksEnumControl.forEach((bitmaskEnum, k) => {
            this.hideAdd('bitmask_enum', k, j);
          });
        }
      });
    }
  }
  hideAdd(control, EnumIndex, bitmaskIndex) {
    if (control === 'enum') {
      document.getElementById('index_' + EnumIndex).style.display = 'none';
      document.getElementById('value_' +  EnumIndex).style.display = 'none';
      document.getElementById('add_enumbtn_' + EnumIndex).style.display = 'none';
      document.getElementById('index_span_' +  EnumIndex).style.display = 'block';
      document.getElementById('value_span_' + EnumIndex).style.display = 'block';
      document.getElementById('edit_enumbtn_' + EnumIndex).style.display = 'inline-block';
    } else {
      document.getElementById('bit_index_'  + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('bit_value_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('add_bitmask_enumbtn_' +   bitmaskIndex + '_' + EnumIndex).style.display = 'none';

      document.getElementById('bit_index_span_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('bit_value_span_'  + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('edit_bitmask_enumbtn_'  + bitmaskIndex + '_' + EnumIndex).style.display = 'inline-block';

    }
  }
  hideEdit(control, EnumIndex, bitmaskIndex) {
    if (control === 'enum') {
      document.getElementById('index_' + EnumIndex).style.display = 'block';
      document.getElementById('value_' + EnumIndex).style.display = 'block';
      document.getElementById('add_enumbtn_' + EnumIndex).style.display = 'inline-block';
      document.getElementById('index_span_'  + EnumIndex).style.display = 'none';
      document.getElementById('value_span_'  + EnumIndex).style.display = 'none';
      document.getElementById('edit_enumbtn_'  + EnumIndex).style.display = 'none';
    } else {
      document.getElementById('bit_index_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('bit_value_' + bitmaskIndex + '_' + EnumIndex).style.display = 'block';
      document.getElementById('add_bitmask_enumbtn_' + bitmaskIndex + '_' + EnumIndex).style.display = 'inline-block';
      document.getElementById('bit_index_span_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('bit_value_span_' + bitmaskIndex + '_' + EnumIndex).style.display = 'none';
      document.getElementById('edit_bitmask_enumbtn_' +  bitmaskIndex + '_' + EnumIndex).style.display = 'none';
    }
  }
  removeFieldValidator(dataType) {
    if ( this.editCommandArgumentData?.dataTypeName.toLocaleLowerCase().includes('bit')
    && this.dataTypeName.toLocaleLowerCase().includes('enum')) {
      this.gridView = [{}];
      this.gridView.splice(0, 1);
      this.setBitmaskValidator();
    }
    if ( this.editCommandArgumentData?.dataTypeName.toLocaleLowerCase().includes('enum')
    && this.dataTypeName.toLocaleLowerCase().includes('bit')) {
      this.removeBitmaskValidators();
      this.createCommandArgumentForm.setControl('bitmasks', new UntypedFormArray([]));
    }
    /* Add Range Validations - min and max */
    this.setRangeValidators(dataType)
  }


  setRangeValidators(dataType: number) {
    this.clearMinMaxValidators()
    let min : BigInt= BigInt(0);
    let max: BigInt = BigInt(0);
    let pattern = '^-?[0-9]*$'
    let floatPattern = /^-?\d*[.,]?\d*$/;
    let unsignedpattern = '^[0-9]*$'
    switch(dataType) {
      case 1:
          min = BigInt(-128)
          max = BigInt(127)
          this.setMinMaxValidators(min, max, pattern)
      break;
      case 2:
          min = BigInt(-32768)
          max = BigInt(32767)
          this.setMinMaxValidators(min, max, pattern)
      break;
      case 3:
          min = BigInt(-2147483648)
          max = BigInt(2147483647)
          this.setMinMaxValidators(min, max, pattern)
      break;
      case 4:
          min = BigInt("-9223372036854775808")
          max = BigInt("9223372036854775807")
          this.setMinMaxValidators(min, max, pattern)
      break;
      case 5://Oint8
      case 21://Hex8
      case 11://Bitmask 8
      case 15://Enumeration 8
      case 19://Command
        min = BigInt(0)
        max = BigInt(255)
        this.setMinMaxValidators(min, max, unsignedpattern)
      break;
      case 6://Oint16
      case 22://Hex16
      case 12://Bitmask 16
      case 16://Enumeration 16
        min = BigInt(0)
        max = BigInt(65535)
        this.setMinMaxValidators(min, max, unsignedpattern)
      break; 
      case 7://Oint32
      case 23://Hex32
      case 17://Enumeration 32
      case 14://Bitmask 32
        min = BigInt(0)
        max = BigInt(4294967295)
        this.setMinMaxValidators(min, max, unsignedpattern)
      break; 
      case 13://Bitmask 24)
          min = BigInt(0)
          max = BigInt(16777215)
          this.setMinMaxValidators(min, max, unsignedpattern)
      break;
      case 8://Oint64
      case 24://Hex64
        min = BigInt(0)
        max = BigInt("18446744073709551615")
        this.setMinMaxValidators(min, max, unsignedpattern)
      break;
      case 10://Boolean
        min = BigInt( 0)
        max = BigInt(255)
        this.createCommandArgumentForm.get('minValue').setValidators([Validators.min(0), Validators.max(0), Validators.required, Validators.pattern(unsignedpattern)])
        this.createCommandArgumentForm.get('maxValue').setValidators([RangeValidator(min, max), Validators.required, Validators.pattern(unsignedpattern)])
        this.createCommandArgumentForm.get('minValue').updateValueAndValidity();
        this.createCommandArgumentForm.get('maxValue').updateValueAndValidity();
        this.validationErrorMessage = `Min value must be 0 & Max value must be in range of 0 to 255`
      break;
      case 9://Float
        this.createCommandArgumentForm.get('minValue').setValidators([Validators.required, Validators.pattern(floatPattern)])
        this.createCommandArgumentForm.get('maxValue').setValidators([Validators.required, Validators.pattern(floatPattern)])
        this.createCommandArgumentForm.get('minValue').updateValueAndValidity();
        this.createCommandArgumentForm.get('maxValue').updateValueAndValidity();
        this.validationErrorMessage = `Value must be a valid decimal number`
        break;

    }
  }
  

  setMinMaxValidators(min: BigInt, max: BigInt, pattern:  string) {
    this.createCommandArgumentForm.get('minValue').setValidators([RangeValidator(min, max), Validators.required, Validators.pattern(pattern)])
    this.createCommandArgumentForm.get('maxValue').setValidators([RangeValidator(min, max), Validators.required, Validators.pattern(pattern)])
    this.createCommandArgumentForm.get('minValue').updateValueAndValidity();
    this.createCommandArgumentForm.get('maxValue').updateValueAndValidity();
    this.validationErrorMessage = `Value must be in the range of ${min} to ${max}`
  }

  clearMinMaxValidators() {
    if(this.editCommandArgumentData) {
        this.createCommandArgumentForm.get('minValue').setValue(this.editCommandArgumentData.minValue)
        this.createCommandArgumentForm.get('maxValue').setValue(this.editCommandArgumentData.maxValue)
    }else {
      this.createCommandArgumentForm.get('minValue').reset()
      this.createCommandArgumentForm.get('maxValue').reset()
    }
    this.createCommandArgumentForm.get('minValue').clearValidators()
    this.createCommandArgumentForm.get('maxValue').clearValidators()
    this.createCommandArgumentForm.get('minValue').updateValueAndValidity();
    this.createCommandArgumentForm.get('maxValue').updateValueAndValidity();
  }

  get bitmaskControls() {
    return this.createCommandArgumentForm.get('bitmasks') as UntypedFormArray;
  }
  
  removeBitmaskValidators() {
    const bitmaskControl = this.bitmaskControls.controls;
    if (bitmaskControl.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < bitmaskControl.length; i++) {
        bitmaskControl[i].get('bits').clearValidators();
        bitmaskControl[i].get('data_type').clearValidators();
        bitmaskControl[i].get('value').clearValidators();
        bitmaskControl[i].get('bits').updateValueAndValidity();
        bitmaskControl[i].get('data_type').updateValueAndValidity();
        bitmaskControl[i].get('value').updateValueAndValidity();
      }
    }
  }
  setBitmaskValidator() {
    const bitmaskControl = this.bitmaskControls.controls;
    if (bitmaskControl !== undefined && bitmaskControl.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < bitmaskControl.length; i++) {
        bitmaskControl[i].get('bits').setValidators([Validators.required]);
        bitmaskControl[i].get('data_type').setValidators([Validators.required]);
        bitmaskControl[i].get('value').setValidators([Validators.required]);
        bitmaskControl[i].get('bits').updateValueAndValidity();
        bitmaskControl[i].get('data_type').updateValueAndValidity();
        bitmaskControl[i].get('value').updateValueAndValidity();
      }
    }
  }

  /*
   Construct the enum values to a string (Ex 0: Low)
  */
  constructEnumData() {
    let textValue = "";
    if (this.gridView.length != 0) {
      for (const prop of this.gridView) {
        let value = prop.index + ":" + prop.value + "\n"
        textValue = textValue + value
      }
    }
    this.kendoEnumEditData = textValue;
    this.enumminimum = Number(this.createCommandArgumentForm.get('minValue').getRawValue())
    this.enummaximum = Number(this.createCommandArgumentForm.get('maxValue').getRawValue())
  }

  /*
    Save the bulk bitmask enum data to the table
  */
  saveEnumData($event: EnumValues) {
    this.loading = true
    if (this.isBitMaskEnumEdit) { //bitmask enum values
      this.bitmasks = this.createCommandArgumentForm.get('bitmasks') as UntypedFormArray;
      let clearall = this.bitmasks.controls[$event.index].get('bitmasks_enums') as UntypedFormArray;
      clearall.clear()
      $event.indexes.forEach(item => {
        clearall.push(
          this.fb.group({
            bit_index: item,
            bit_value: $event.values[item]
          })
        );
      })
    } else { //enum values
      this.displayEnumRows = true
      this.gridView = [];
      this.items = []
      let max = this.createCommandArgumentForm.get('maxValue').getRawValue()
      let min = this.createCommandArgumentForm.get('minValue').getRawValue()
      for (var i of $event.indexes) {
      var enumsvalue = $event.values[i];
      const enumObj = {
        index: i,
        value: enumsvalue,
        inputMax: max,
        inputMin: min
      };
      this.items.push(enumObj)
      }
      setTimeout(async () => {
        this.gridView = this.items
        this.loading = false    
       }, 100)
    }
  }

  /*
    Show the bulk enum edit view & hide the table
  */
  showEnumEdit() {
    this.isBitMaskEnumEdit = false
    this.displayEnumRows = true
    this.constructEnumData()
    this.eventsSubject.next(this.kendoEnumEditData);
    this.displayEnumRows = !this.displayEnumRows;
  }

  /*
    Show the bulk bitmask enum edit view & hide the table
  */
  showBitMaskEnumEdit(index: number) {
    this.isBitMaskEnumEdit = true
    this.bitmasks = this.createCommandArgumentForm.get('bitmasks') as UntypedFormArray;
    let textValue = "";
    let values = this.bitmasks.value[index].bitmasks_enums
    if (values) {
      let value = ""
      values.forEach((item) => {
        if (item.bit_value && item.bit_value.length > 0) {
          value += `${item.bit_index}:${item.bit_value}\n`
        }
      });
      textValue = value;
    }
    this.enumEditValues[index.toString()] = { "bits": this.bitmasks.value[index].bits, "value": textValue }
    this.toogleBitMaskEnumShow(index, true)
  }

  /*
    Show the bulk bitmask enum edit view & hide the table
  */
  toogleBitMaskEnumShow(index: number, value: boolean) {
    this.bitmasks = this.createCommandArgumentForm.get('bitmasks') as UntypedFormArray;
    this.bitmasks.controls[index].value.enumShow = value
  }

  /*
    Close the bulk bitmask enum edit view & show the table
  */
  closeEnumEdit($event: number) {
    if (this.displayEnumRows === false) {
      this.displayEnumRows = true
    } else {
      this.toogleBitMaskEnumShow($event, false)
    }
  }

  /*
    Display error message
  */
  displayErrorMessage(message: string) {
    //enum edit
    if (this.displayEnumRows === false) {
      this.enumErrorMessage = message;
    } else { // bitmask enum edit
      this.bitmaskEnumErrorMessage = message;
    }
  }
}
