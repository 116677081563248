import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinkedFeatureSystem } from '../display-list-modal/display-list-modal.component';
import { GroupDescriptor, GroupResult } from '@progress/kendo-data-query';
import { groupBy } from "@progress/kendo-data-query";
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-group-grid',
  templateUrl: './group-grid.component.html',
  styleUrls: ['./group-grid.component.scss']
})
export class GroupGridComponent implements OnInit {
  @Input() title = ''
  @Input() data: LinkedFeatureSystem[] = []
  @Input() opened: boolean = false;
  @Input() menuEnabled: boolean = false;
  @Output() closedEvent: EventEmitter<boolean> = new EventEmitter();
  public gridView: GroupResult[] | LinkedFeatureSystem[]; 
  public groups: GroupDescriptor[] = [{ field: "System Name" }];
  
  constructor(private commonService: CommonService) { }

  
  ngOnInit() {
    this.gridView = groupBy(this.data, this.groups);
  }

  public close(status: boolean) {
    this.opened = false;
    this.closedEvent.emit(false); // False indicates user clicked on close button
  }

  navigateToSystemData(dataItem: LinkedFeatureSystem, isMenuClicked: boolean) {
    dataItem.isMenuClicked = false
    this.commonService.selectedFeature = dataItem
  }

}
