import { Component, OnInit } from '@angular/core';
import { faCoffee, faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  faCoffee = faCoffee;
  faUser = faUser;
  constructor() { }

  ngOnInit() {
  }

}
